import { Button } from "@mui/material"
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import { forwardRef, useState } from "react"

const CommentDatePicker = ({
	commentDate = null,
	setCommentDate = () => {}
}) => {
	const [openDatePicker, setOpenDatePicker] = useState(false)

	return (
		<div className="calendar-wrapper">
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<MobileDateTimePicker
					id="calendar-date-picker"
					value={commentDate}
					onChange={(newValue) => {
						setCommentDate(newValue)
					}}
					open={openDatePicker}
					onClose={() => setOpenDatePicker(false)}
					renderInput={(params) => (
						<CustomButton
							{...params.inputProps}
							ref={params.inputRef}
							setOpenDatePicker={setOpenDatePicker}
							commentDate={commentDate}
						/>
					)}
					toolbarTitle=""
					showToolbar={false}
				/>
			</LocalizationProvider>
		</div>
	)
}

const CustomButton = forwardRef((props, ref) => (
	<Button
		className="calendar-btn"
		variant={props?.commentDate ? "outlined" : "text"}
		color="inherit"
		id="calendar-btn"
		onClick={() => props.setOpenDatePicker(true)}
		ref={ref}
	>
		{props?.commentDate ? (
			<span className="calendar-btn-date">
				{moment(props?.commentDate).format("DD.MM.YYYY HH:mm")}
			</span>
		) : (
			<i className="bi bi-calendar-plus" />
		)}
	</Button>
))
export default CommentDatePicker
