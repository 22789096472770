import {
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	LinearProgress,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueries } from "react-query"

const CRMOperatorsModal = (props) => {
	const { open, setOpen } = props
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [operatorsList, setOperatorsList] = useState([])
	const sendNotification = useNotification()

	const [crmOperatorsQuery, staffsQuery] = useQueries([
		{
			queryKey: "crmOperatorsList",
			queryFn: async function () {
				const response = await axiosPrivate.get("/crm/operator")
				return response.data.data
			},
			enabled: !hasError && open,
			onSuccess: (data) => {
				setOperatorsList([...data])
			},
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
		{
			queryKey: "staffsList",
			queryFn: async function () {
				const response = await axiosPrivate.get("/dictionary/staffes")
				return response.data.data
			},
			enabled: !hasError && open,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
	])

	const handleSave = async () => {
		setIsSubmitting(true)
		try {
			let response = await axiosPrivate.post(
				"/crm/operator/update",
				JSON.stringify({
					data: operatorsList.map((item) => ({
						id: item?.id,
						user_id: item?.user_id
					})),
					_method: "put"
				}),
				{ headers: { "Content-Type": "application/json" } }
			)
			if (response && response.data && response.data.status) {
				setIsSubmitting(false)
				handleClose()
			}
		} catch (error) {
			sendNotification({
				msg: error?.response?.data?.message || error?.message,
				variant: "error"
			})
			setIsSubmitting(false)
		}
	}

	const changeOperator = (userId, operatorId) => {
		let newOperatorsList = operatorsList.map((item) => {
			if (item.id === operatorId) {
				item.user_id = userId
			}
			return item
		})
		setOperatorsList([...newOperatorsList])
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span className="pr-5">OnlinePbx foydalanuvchilari</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<div className="mt-2">
					<TableContainer className="flex-auto h-full">
						<Table
							stickyHeader
							sx={{ minWidth: 750, height: "max-content" }}
							aria-labelledby="tableTitle"
						>
							<TableHead>
								<TableRow>
									<TableCell>№</TableCell>
									<TableCell>Operator</TableCell>
									<TableCell>Xodim</TableCell>
								</TableRow>
							</TableHead>
							{crmOperatorsQuery.isLoading || crmOperatorsQuery.isFetching ? (
								<TableBody className="overflow-hidden">
									<TableRow>
										<TableCell colSpan={3}>
											<LinearProgress />
										</TableCell>
									</TableRow>
								</TableBody>
							) : crmOperatorsQuery.isError ? (
								<TableBody className="overflow-hidden">
									<TableRow>
										<TableCell colSpan={3}>
											<div className="flex flex-col items-center">
												{crmOperatorsQuery.error?.response?.data?.message && (
													<span className="text-red-600 font-medium">
														{crmOperatorsQuery.error?.response?.data?.message}
													</span>
												)}
												{crmOperatorsQuery.error?.response?.data?.details &&
													crmOperatorsQuery.error?.response?.data?.details[0]
														?.message && (
														<div>
															<span className="text-red-600 font-medium">
																{t("common.errors.message")}
															</span>
															<span>
																{
																	crmOperatorsQuery.error?.response?.data
																		?.details[0]?.message
																}
															</span>
														</div>
													)}
											</div>
										</TableCell>
									</TableRow>
								</TableBody>
							) : crmOperatorsQuery.data &&
							  crmOperatorsQuery.data.length > 0 ? (
								<TableBody className="overflow-hidden">
									{operatorsList.map((row, rowIndex) => {
										return (
											<TableRow hover tabIndex={-1} key={"row-" + rowIndex}>
												<TableCell>{rowIndex + 1}</TableCell>
												<TableCell>
													{row?.name ? (
														<div>
															{row?.name} ({row?.number})
														</div>
													) : (
														row?.number
													)}
												</TableCell>
												<TableCell>
													<div className="max-w-[300px]">
														<FormControl fullWidth color="formColor">
															<Select
																id={`user-${rowIndex}-select`}
																label=""
																onChange={(event) =>
																	changeOperator(event.target.value, row?.id)
																}
																value={row?.user_id || ""}
																color="formColor"
																variant="outlined"
																role="presentation"
																MenuProps={{
																	id: `user-${rowIndex}-select-menu`
																}}
															>
																{staffsQuery.isLoading ||
																staffsQuery.isFetching ? (
																	<div className="circular-progress-box">
																		<CircularProgress size={25} />
																	</div>
																) : staffsQuery.data &&
																  staffsQuery.data.length > 0 ? (
																	staffsQuery.data.map((item, index) => (
																		<MenuItem
																			value={item.id}
																			key={index + 1}
																			selected={index === 0}
																		>
																			{item.name}
																		</MenuItem>
																	))
																) : (
																	<div>
																		<span className="no-data-found-wrapper select-box">
																			<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
																			{t("common.global.noDataFound")}
																		</span>
																	</div>
																)}
															</Select>
														</FormControl>
													</div>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							) : (
								<TableBody>
									<TableRow>
										<TableCell colSpan={3}>
											<span className="no-data-found-wrapper">
												<i className="bi bi-exclamation-octagon text-xl leading-4 mr-1" />{" "}
												{t("common.global.noDataFound")}
											</span>
										</TableCell>
									</TableRow>
								</TableBody>
							)}
						</Table>
					</TableContainer>

					{!(crmOperatorsQuery.isLoading || crmOperatorsQuery.isFetching) &&
						crmOperatorsQuery.data &&
						crmOperatorsQuery.data.length > 0 && (
							<div className="text-center mt-4">
								<Button
									color="success"
									variant="contained"
									disabled={isSubmitting}
									onClick={() => handleSave()}
								>
									{isSubmitting && (
										<CircularProgress
											size={15}
											color="inherit"
											className="mr-1"
										/>
									)}
									{t("common.button.save")}
								</Button>
							</div>
						)}
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default CRMOperatorsModal
