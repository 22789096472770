import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useTopPanel from 'hooks/useTopPanel';

const CRM = () => {
  const { t, i18n } = useTranslation();
  const { setComponent } = useTopPanel();

  useEffect(() => {
    setComponent(<div className='component-title'>{t('boss.crm.title')}</div>);
  }, [i18n.language]);

  return <div></div>;
};
export default CRM;
