import React from 'react';
import { Outlet } from 'react-router-dom';
import BossCrmSidebarPanel from 'components/sidebar/BossCrmSidebarPanel';

const BossCrmLayout = () => {
  return (
    <div className='flex flex-row relative grow h-full'>
      <div
        className={'aside-container sidebar-full relative  my-shadow-2 grow'}
        style={{ position: 'relative', zIndex: 1, height: 'auto' }}
      >
        <BossCrmSidebarPanel />
      </div>
      <div className={'base-container base-container-small h-auto'}>
        <main className='main-container with-max-width'>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default BossCrmLayout;
