import moment from "moment"
import { patternFormatter } from "react-number-format"
import CRMAudioWave from "./CRMAudioWave"

const CRMCallAudioMessage = ({ data = {} }) => {
	return (
		<div className="call-history-wrapper flex items-center">
			{data?.accountcode === "outbound" ? (
				<div className="call-icon text-base leading-4 border-2 border-green-700 text-green-600 rounded-full w-[42px] h-[42px] min-w-[42px] min-h-[42px] flex items-center justify-center mr-3">
					<i className="bi bi-telephone-outbound leading-4" />
				</div>
			) : data?.accountcode === "inbound" ? (
				<div className="call-icon text-base leading-4 border-2 border-blue-700 text-blue-600 rounded-full w-[42px] h-[42px] min-w-[42px] min-h-[42px] flex items-center justify-center mr-3">
					<i className="bi bi-telephone-inbound leading-4" />
				</div>
			) : (
				""
			)}
			<div className="call-details w-full">
				<div className="text-[12px] text-gray-500">
					{moment(data?.start_stamp * 1000).format("DD.MM.YYYY HH:mm")},
					{data?.accountcode === "outbound" ? (
						<span className="ml-1">
							Chiquvchi qo'ng'iroq:{" "}
							{data?.outbound &&
							data?.outbound?.user &&
							data?.outbound?.user?.name ? (
								<span>
									{data?.outbound?.user?.name}({data?.outbound?.number}) ning
								</span>
							) : (
								""
							)}{" "}
							"
							{data?.gateway
								? patternFormatter(data?.gateway, {
										format: "+998(##) ###-##-##"
								  })
								: ""}
							" raqamidan "
							{data?.destination_number
								? data?.destination_number.length > 4
									? patternFormatter(data?.destination_number, {
											format: "+998(##) ###-##-##"
									  })
									: data?.destination_number
								: ""}
							" raqamiga
						</span>
					) : data?.accountcode === "inbound" ? (
						<span className="ml-2">
							Kiruvchi qo'ng'iroq: "
							{data?.caller_id_number
								? data?.caller_id_number.length > 4
									? patternFormatter(data?.caller_id_number, {
											format: "+998(##) ###-##-##"
									  })
									: data?.caller_id_number
								: ""}
							" dan{" "}
							{data?.inbound &&
							data?.inbound?.user &&
							data?.inbound?.user?.name ? (
								<span>
									{data?.inbound?.user?.name}({data?.inbound?.number}) ning
								</span>
							) : (
								""
							)}{" "}
							"
							{data?.gateway
								? patternFormatter(data?.gateway, {
										format: "+998(##) ###-##-##"
								  })
								: ""}
							" raqamiga
						</span>
					) : (
						""
					)}
				</div>
				<div className="mt-2">
					<CRMAudioWave callId={data?.id} />
				</div>
			</div>
		</div>
	)
}
export default CRMCallAudioMessage
