import { useEffect, useState } from "react"
import FilterAccordion from "../FilterAccordion"
import { useSearchParams } from "react-router-dom"
import { Button } from "@mui/material"

const HomeRoomsFilter = ({ filterRooms = [], disabled = false }) => {
	const [rooms, setRooms] = useState(() =>
		filterRooms && filterRooms.length > 0
			? filterRooms.map((item) => item.rooms)
			: []
	)
	const [searchParams, setSearchParams] = useSearchParams()
	const [selectedRooms, setSelectedRooms] = useState([])

	const handleRoomChange = (value) => {
		let entries = Object.fromEntries(searchParams)
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("rooms")) {
				searchParams.delete(item)
			}
		})
		if (isSelected(value)) {
			selectedRooms.splice(selectedRooms.indexOf(value), 1)
		} else {
			selectedRooms.push(value)
		}
		selectedRooms.forEach((item, index) => {
			searchParams.set(`rooms[${index}]`, item)
		})
		setSearchParams(searchParams)
		setSelectedRooms(selectedRooms)
	}

	const isSelected = (room) => {
		return selectedRooms.includes(room)
	}

	const setDefaultRooms = () => {
		let entries = Object.fromEntries(searchParams)
		let newRooms = []
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("rooms")) {
				newRooms.push(searchParams.get(item))
			}
		})
		setSelectedRooms(newRooms)
	}

	useEffect(() => {
		setDefaultRooms()
	}, [searchParams])

	return (
		<FilterAccordion title="Xonalar soni" code="home-rooms">
			{rooms && rooms.length > 0 && (
				<div className="home-rooms-wrapper">
					{rooms.map((item) => (
						<Button
							className={`room-item${isSelected(item) ? " item-selected" : ""}`}
							color="primary"
							variant={isSelected(item) ? "contained" : "outlined"}
							key={`home-filter-room-${item}`}
							onClick={() => handleRoomChange(item)}
							disabled={disabled}
						>
							{item}
						</Button>
					))}
				</div>
			)}
		</FilterAccordion>
	)
}
export default HomeRoomsFilter
