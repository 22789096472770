import { Line } from "react-chartjs-2"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from "chart.js"
import "chart.js/auto"
import { numericFormatter } from "react-number-format"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const LineChart = ({
	chartDatasets = [],
	chartLabels = [],
	options = {},
	optionPlugins = {},
	plugins = []
}) => {
	return (
		<Line
			options={{
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						usePointStyle: true,
						callbacks: {
							label: function (tooltipItem) {
								return ` ${numericFormatter(tooltipItem.raw.toString(), {
									decimalScale: 3,
									thousandSeparator: " ",
									allowNegative: false
								})}`
							},
							labelPointStyle: function () {
								return {
									pointStyle: "rectRounded",
									rotation: 0
								}
							}
						}
					},
					...optionPlugins
				},
				...options
			}}
			plugins={plugins}
			data={{ labels: chartLabels, datasets: chartDatasets }}
		/>
	)
}
/* <LineChart
	chartLabels={
		dateType === "DAILY"
			? [...salesDateList]
			: dateType === "MONTH"
			? [...salesDateList]
			: [...salesDateList]
	}
	chartDatasets={salesByRoomsData.map((item) => ({
		data: item.value,
		label: item.label,
		borderRadius: 8,
		borderWidth: 2,
		borderColor: item.bgColor,
		backgroundColor: item.bgColor,
		xAxisID: "xAxis",
		yAxisID: "yAxis",
		pointStyle: "circle",
		pointRadius: 5,
		pointHoverRadius: 7,
		// pointBackgroundColor: "white",
		// pointHoverBackgroundColor: item.bgColor,
		pointBorderColor: "transparent",
		pointHoverBorderColor: "transparent",
		pointBorderWidth: 0,
		tension: 0.3
	}))}
	optionPlugins={{
		legend: {
			display: true,
			position: "right",
			align: "center",
			labels: {
				usePointStyle: true,
				pointStyle: "rectRounded"
			}
		},
		tooltip: {
			usePointStyle: true,
			yAlign: "bottom",
			callbacks: {
				labelPointStyle: function () {
					return {
						pointStyle: "circle",
						rotation: 0
					}
				},
				title: () => null
			},
			position: "cursor"
		}
	}}
	options={{
		interaction: {
			mode: "index",
			intersect: false
		}
		// stacked: false
	}}
	plugins={[
		{
			id: "crosshairLabel",
			afterDatasetsDraw(chart, args, plugins) {
				const { ctx } = chart
				// ctx.lineWidth = 1.5
				ctx.strokeStyle = "gray"
				if (chart.crosshair) {
					ctx.save()
					ctx.beginPath()
					chart.crosshair.forEach((line) => {
						ctx.setLineDash([5, 5])
						ctx.moveTo(line.startX, line.startY)
						ctx.lineTo(line.endX, line.endY)
						ctx.stroke()
					})
				}
			},
			afterEvent(chart, args) {
				const {
					chartArea: { left, bottom }
				} = chart
				const xCoor = args.event.x
				const yCoor = args.event.y
				// const xCoor = chart.tooltip._eventPosition.x
				// const yCoor = chart.tooltip._eventPosition.y
				// ctx.lineWidth = 2
				// ctx.strokeStyle = "red"
				if (!args.inChartArea && chart.crosshair) {
					chart.crosshair = undefined
					args.changed = true
				} else if (args.inChartArea) {
					chart.crosshair = [
						{
							startX: left,
							startY: yCoor,
							endX: xCoor,
							endY: yCoor
						},
						{
							startX: xCoor,
							startY: yCoor,
							endX: xCoor,
							endY: bottom
						}
					]
					args.changed = true
				}
			}
		}
	]}
/> */

export default LineChart
