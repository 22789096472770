import { CircularProgress } from "@mui/material"
import DoughnutChart from "components/ui/charts/DoughnutChart"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useCurrency from "hooks/useCurrency"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useQuery } from "react-query"
import { colorSeparators } from "shared/colors"
import { doughnutTooltip } from "utils/chartjsTooltips"

const TotalStatistics = () => {
	const { t } = useTranslation()
	const { currencyData } = useCurrency()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [summedPrices, setSummedPrices] = useState({
		sum: 0,
		valute: 0
	})

	const { data, isLoading, isFetching } = useQuery({
		queryKey: "totalStatistics",
		queryFn: async function () {
			const response = await axiosPrivate.get("/analytic/object/index")
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && Object.keys(data).length > 0) {
				setSummedPrices({
					sum: +data?.insalesum + +data?.paidsum + +data?.waitingsum,
					valute: +data?.insalevalute + +data?.paidvalute + +data?.waitingvalute
				})
			}
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	return (
		<div className="residential-complex-item total-statistics">
			<div className="residential-complex-item-header">
				<div className="residential-complex-item-title total-statistics-title">
					Umumiy statistika
				</div>
			</div>

			<div className="total-statistics-body">
				<div className="total-statistics-body-header">
					<div className="total-statistics-body-title">Umumiy uylar bahosi</div>
					{isLoading || isFetching ? (
						<div className="flex mt-1.5">
							<CircularProgress size={15} color="inherit" />
						</div>
					) : (
						<div>
							<div className="total-statistics-total-sum">
								UZS:{" "}
								<NumericFormat
									value={summedPrices?.sum}
									displayType={"text"}
									allowNegative={false}
									thousandSeparator={" "}
									decimalScale={2}
									className="total-sum-value bg-transparent"
									suffix=" UZS"
								/>
							</div>
							<div className="total-statistics-total-sum">
								USD:{" "}
								<NumericFormat
									value={summedPrices?.valute}
									displayType={"text"}
									allowNegative={false}
									thousandSeparator={" "}
									decimalScale={2}
									className="total-sum-value bg-transparent"
									suffix=" $"
								/>
							</div>
							<div className="total-statistics-total-sum">
								Jami:{" "}
								<NumericFormat
									value={
										+summedPrices?.sum +
										+summedPrices?.valute * currencyData?.sum
									}
									displayType={"text"}
									allowNegative={false}
									thousandSeparator={" "}
									decimalScale={2}
									className="total-sum-value bg-transparent"
									suffix=" UZS"
								/>
							</div>
						</div>
					)}
				</div>

				{isLoading || isFetching ? (
					<div className="flex w-full h-[220px] items-center justify-center">
						<CircularProgress size={30} color="inherit" />
					</div>
				) : data && Object.keys(data).length > 0 ? (
					<div className="total-statistics-chart">
						<DoughnutChart
							chartLabels={["Undirilgan mablag'", "Sotuvda", "Kutilmoqda"]}
							chartDatasets={[
								{
									data: [
										{
											total:
												+data?.paidsum + +data?.paidvalute * currencyData?.sum,
											sum: data?.paidsum,
											valute: data?.paidvalute
										},
										{
											total:
												+data?.insalesum +
												+data?.insalevalute * currencyData?.sum,
											sum: data?.insalesum,
											valute: data?.insalevalute
										},
										{
											total:
												+data?.waitingsum +
												+data?.waitingvalute * currencyData?.sum,
											sum: data?.waitingsum,
											valute: data?.waitingvalute
										}
									],
									backgroundColor: [
										`rgba(${colorSeparators.successColor}, 0.8)`,
										`rgba(${colorSeparators.warningColor}, 0.8)`,
										`rgba(${colorSeparators.baseColorLight}, 0.8)`
									],
									borderWidth: 2,
									hoverOffset: 15,
									hoverBorderWidth: 0,
									borderRadius: 4
								}
							]}
							optionPlugins={{
								legend: {
									display: true,
									position: "bottom",
									align: "center",
									reverse: false,
									labels: {
										usePointStyle: true,
										pointStyle: "rectRounded"
									}
								},
								tooltip: {
									enabled: false,
									external: doughnutTooltip("sum", "valute", "total")
								}
							}}
							options={{
								layout: {
									padding: {
										top: 10,
										bottom: 10
									}
								},
								parsing: {
									key: "total"
								}
							}}
						/>
					</div>
				) : (
					<div>
						<span className="no-data-found-wrapper">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				)}
			</div>
		</div>
	)
}
export default TotalStatistics
