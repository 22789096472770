import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	MenuItem,
	Select
} from "@mui/material"
import SimpleDateField from "components/ui/simple-fields/date-picker/SimpleDateField"
import moment from "moment"
import { Fragment, useState } from "react"

const DateSelectBox = ({
	setStartAndFinishDate = () => {},
	disabled = false
}) => {
	const [selectedOption, setSelectedOption] = useState("ALL")
	const [openSpecialModal, setOpenSpecialModal] = useState(false)

	const handleChange = (value) => {
		switch (value) {
			case "ALL": {
				setStartAndFinishDate({
					startDate: moment()
						.set("years", 2010)
						.startOf("years")
						.format("YYYY-MM-DD"),
					finishDate: moment().format("YYYY-MM-DD")
				})
				break
			}
			case "TODAY": {
				setStartAndFinishDate({
					startDate: moment().startOf("days").format("YYYY-MM-DD"),
					finishDate: moment().endOf("days").format("YYYY-MM-DD")
				})
				break
			}
			case "YESTERDAY": {
				setStartAndFinishDate({
					startDate: moment()
						.subtract(1, "days")
						.startOf("days")
						.format("YYYY-MM-DD"),
					finishDate: moment()
						.subtract(1, "days")
						.endOf("days")
						.format("YYYY-MM-DD")
				})
				break
			}
			case "CURRENT_WEEK": {
				setStartAndFinishDate({
					startDate: moment().startOf("weeks").format("YYYY-MM-DD"),
					finishDate: moment().endOf("weeks").format("YYYY-MM-DD")
				})
				break
			}
			case "CURRENT_MONTH": {
				setStartAndFinishDate({
					startDate: moment().startOf("months").format("YYYY-MM-DD"),
					finishDate: moment().endOf("months").format("YYYY-MM-DD")
				})
				break
			}
			case "SPECIAL": {
				setOpenSpecialModal(true)
				break
			}
			default: {
				setStartAndFinishDate({
					startDate: moment()
						.set("years", 2010)
						.startOf("years")
						.format("YYYY-MM-DD"),
					finishDate: moment().format("YYYY-MM-DD")
				})
				break
			}
		}
		setSelectedOption(value)
	}

	const handleChangeSpecialDates = (value) => {
		setStartAndFinishDate({
			startDate: value.startDate,
			finishDate: value.finishDate
		})
	}

	return (
		<Fragment>
			<FormControl color="formColor" fullWidth>
				<Select
					labelId="date-filter-label"
					label=""
					id="date-filter-chip"
					value={selectedOption}
					onChange={(event) => handleChange(event?.target?.value)}
					color="formColor"
					variant="outlined"
					size="small"
					MenuProps={{
						id: "objectId-select-menu",
						PaperProps: {
							style: {
								maxHeight: 300
							}
						},
						disableScrollLock: true
					}}
					disabled={disabled}
				>
					<MenuItem value="ALL" className="small-size">
						Shu kungacha
					</MenuItem>
					<MenuItem value="TODAY" className="small-size">
						Bugun
					</MenuItem>
					<MenuItem value="YESTERDAY" className="small-size">
						Kecha
					</MenuItem>
					<MenuItem value="CURRENT_WEEK" className="small-size">
						Joriy hafta
					</MenuItem>
					<MenuItem value="CURRENT_MONTH" className="small-size">
						Joriy oy
					</MenuItem>
					<MenuItem
						value="SPECIAL"
						className="small-size"
						onClick={() => setOpenSpecialModal(true)}
					>
						Maxsus
					</MenuItem>
				</Select>
			</FormControl>

			<SpecialDateModal
				open={openSpecialModal}
				setOpen={setOpenSpecialModal}
				handleDates={handleChangeSpecialDates}
			/>
		</Fragment>
	)
}

const SpecialDateModal = ({ open, setOpen, handleDates }) => {
	const [startDate, setStartDate] = useState(null)
	const [finishDate, setFinishDate] = useState(null)

	const handleSave = () => {
		handleDates({ startDate, finishDate })
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span>Maxsus sanani tanlash</span>
				<div className="close-btn-wrapper">
					<IconButton
						variant="onlyIcon"
						color="primary"
						onClick={() => setOpen(false)}
					>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<div className="max-w-[350px] flex items-center">
					<SimpleDateField
						delay={0}
						duration={0}
						label="Boshlanish"
						name="start-date"
						value={startDate}
						setValue={setStartDate}
						size="small"
					/>
					<span className="mx-1.5">-</span>
					<SimpleDateField
						delay={0}
						duration={0}
						label="Tugash"
						name="ens-date"
						value={finishDate}
						setValue={setFinishDate}
						size="small"
					/>
				</div>
				<div className="text-center mt-3">
					<Button
						variant="contained"
						color="success"
						onClick={() => handleSave()}
					>
						Saqlash
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default DateSelectBox
