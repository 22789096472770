import React, { memo, useState } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

const DateFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    if (value?._isValid) {
      searchParams.set('date', date);
      setSearchParams(searchParams);
    } else if (!value) {
      searchParams.delete('date');
      setSearchParams(searchParams);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        id={`date-picker-filter`}
        openTo='day'
        value={
          searchParams.get('date') ? moment(searchParams.get('date')) : null
        }
        onChange={handleChange}
        views={['year', 'month', 'day']}
        inputFormat='DD/MM/yyyy'
        renderInput={(params) => (
          <TextField
            {...params}
            color='formColor'
            variant='outlined'
            fullWidth
            id={'date-filter'}
            name={'date'}
            label={t('common.fields.date')}
            autoComplete='off'
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default memo(DateFilter);
