import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTextField from 'components/ui/form/FormTextField';
import useFormSubmit from 'hooks/useFormSubmit';

const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

const validationSchema = yup.object({
  base_url: yup
    .string()
    .test('is-url-valid', 'settings.crm.validation.notUrl', (value) =>
      isValidUrl(value)
    )
    .required('settings.crm.validation.baseUrl'),
  redirect_uri: yup
    .string()
    .test('is-url-valid', 'settings.crm.validation.notUrl', (value) =>
      isValidUrl(value)
    )
    .required('settings.crm.validation.redirectUri'),
  client_id: yup.string().required('settings.crm.validation.clientId'),
  client_secret: yup.string().required('settings.crm.validation.clientSecret'),
  grant_type: yup.string().required('settings.crm.validation.granType'),
  code: yup.string().required('settings.crm.validation.code'),
});

const StaffAddEditModal = (props) => {
  const { open, setOpen, itemId, setItemId, refetch = () => {}, data } = props;
  const { t } = useTranslation();
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      base_url: '',
      client_id: '',
      client_secret: '',
      grant_type: '',
      code: '',
      redirect_uri: '',
      token: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (itemId) {
        submit(
          { type: 'put', contentType: 'simple' },
          values,
          '/crm/amocrm/setting',
          values.client_id,
          itemId,
          true,
          handleFinishRequest
        );
      } else
        submit(
          { type: 'post', contentType: 'simple' },
          values,
          '/crm/amocrm/setting',
          values.client_id,
          null,
          true,
          handleFinishRequest
        );
    },
  });

  useEffect(
    (data) => {
      if (data?.id) {
        setItemId(data?.id);
        formik.setValues({
          base_url: data.base_url,
          client_id: data.client_id,
          client_secret: data.client_secret,
          grant_type: data.grant_type,
          code: data.code,
          redirect_uri: data.redirect_uri,
          token: data.token,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, open]
  );

  const handleFinishRequest = () => {
    refetch();
    handleClose();
  };

  const handleClose = () => {
    setItemId('');
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {itemId && formik.values.client_id ? (
          <span>
            {t('settings.crm.modal.editTitle', {
              value: formik.values.client_id,
            })}
          </span>
        ) : (
          <span>{t('settings.crm.modal.addTitle')}</span>
        )}
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, lg: 3 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.1}
                label={t('common.fields.baseUrl')}
                fieldName='base_url'
                formik={formik}
              />
            </Grid>

            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                label={t('common.fields.clientId')}
                fieldName='client_id'
                formik={formik}
              />
            </Grid>

            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                label={t('common.fields.clientSecret')}
                fieldName='client_secret'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.1}
                label={t('common.fields.grantType')}
                fieldName='grant_type'
                formik={formik}
              />
            </Grid>

            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                label={t('common.fields.code')}
                fieldName='code'
                formik={formik}
              />
            </Grid>

            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                label={t('common.fields.redirectUri')}
                fieldName='redirect_uri'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                label={t('common.fields.token')}
                fieldName='token'
                formik={formik}
              />
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <FormActionButtons
                delay={0.6}
                isSubmitting={isSubmitting}
                formType='dialog'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(StaffAddEditModal);
