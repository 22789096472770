import { Button, Grid } from '@mui/material';
import EditableTextField from 'components/ui/editable-fields/EditableTextField';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import usePermission from 'hooks/usePermission';
import MiniMap from 'components/MiniMap';
import CRMBlockDroppableCards from '../card/items/CRMBlockDroppableCards';

const CRMBlockDroppableColumn = ({
  data = [],
  setOpenAddModal = () => {},
  setOpenOperatorsModal = () => {},
  leadsData = [],
  setLeadsData = () => {},
}) => {
  const [enabled, setEnabled] = useState(false);
  const sendNotification = useNotification();
  const axiosPrivate = useAxiosPrivate();
  const { hasPermission } = usePermission();

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  const handleUpdateColumn = (column) => async (newName) => {
    try {
      await axiosPrivate.post(
        `/crm/block-item/${column?.id}`,
        JSON.stringify({
          crm_block_id: column?.crm_block_id,
          name: newName,
          order: column?.order,
          _method: 'put',
        }),
        { headers: { 'Content-Type': 'application/json' } }
      );
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
  };

  if (!enabled) {
    return null;
  }

  return (
    <Droppable
      droppableId='crm-block-columns'
      type='COLUMN'
      direction='horizontal'
    >
      {(provided) => (
        <div
          className='flex crm-block-columns-grid'
          id='crm-blocks-wrapper'
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {data &&
            data.length > 0 &&
            data.map((column, index) => (
              <Draggable
                draggableId={`${column.id}`}
                index={index}
                key={`column-${column.id}`}
              >
                {(provided, snapshot) => (
                  <Grid
                    item={true}
                    sm={1}
                    xs={1}
                    className={`crm-block-grid-column${
                      snapshot.isDragging ? ' is-dragging' : ''
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div className='crm-block-grid-column-title-wrapper'>
                      <div className='drag-btn' {...provided.dragHandleProps}>
                        <i className='bi bi-grid-3x2-gap-fill' />
                      </div>
                      <div className='crm-block-grid-column-title relative'>
                        <EditableTextField
                          initialText={column.name}
                          changeFn={handleUpdateColumn(column)}
                          className='text-center'
                        />
                        <div className='absolute top-3.5 right-1 bg-violet-600 rounded px-1 py-0.2 text-white text-xs'>
                          {column?.leads_count || ''}
                        </div>
                      </div>
                    </div>

                    <div className='crm-block-grid-column-body'>
                      <CRMBlockDroppableCards
                        leadData={leadsData[`${column.id}`] || []}
                        columnId={column.id}
                        parentDroppableId={`${column.id}`}
                        setLeadsData={setLeadsData}
                        enableFetchLeads={column?.leads_count !== '0'}
                      />
                    </div>
                  </Grid>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
          <div className='bg-base-1 sticky right-0 flex flex-col'>
            {hasPermission('CRM_ADD_BLOCK_ITEM') && (
              <div className='pl-4 mb-2'>
                <Button
                  type='button'
                  variant='actionLarge'
                  color='primary'
                  onClick={() => setOpenAddModal(true)}
                >
                  <i className='bi bi-plus' />
                </Button>
              </div>
            )}

            {hasPermission('CRM_OPERATOR') && (
              <div className='pl-4'>
                <Button
                  type='button'
                  variant='actionLarge'
                  color='primary'
                  onClick={() => setOpenOperatorsModal(true)}
                >
                  <i className='bi bi-person-gear' />
                </Button>
              </div>
            )}
          </div>
          <MiniMap
            mainContainerId='crm-blocks-wrapper'
            itemsNumber={data.length}
          />
        </div>
      )}
    </Droppable>
  );
};
export default CRMBlockDroppableColumn;
