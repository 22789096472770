import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const CrmBlockFilter = () => {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [selectedBlocks, setSelectedBlocks] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, isFetching } = useQuery({
    queryKey: 'blocksFilterSelect',
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/block');
      return response.data.data;
    },
    onSuccess: (data) => {
      setBlocks(data);
      handleClearSelectBox();
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    const block = searchParams.get('block');
    if (block) setSelectedBlocks(block);
    return () => {};
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    searchParams.set('block', value);
    setSearchParams(searchParams);
    setSelectedBlocks(value);
  };

  const handleClearSelectBox = () => {
    searchParams.delete('block');
    setSearchParams(searchParams);
    setSelectedBlocks('');
  };

  return (
    <FormControl fullWidth color='formColor'>
      <InputLabel id='blocks-filter-label'>
        {t('common.filter.crm_block_id')}
      </InputLabel>
      <Select
        labelId='blocks-filter-label'
        id='blocks-filter-multiple-chip'
        value={selectedBlocks}
        onChange={handleChange}
        input={
          <OutlinedInput
            id='blocks-filter-select-multiple-chip'
            variant='filterField'
            label={t('common.filter.crm_block_id')}
          />
        }
        color='formColor'
        variant='outlined'
        MenuProps={{
          id: 'blocks-filter-select-menu',
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
          disableScrollLock: true,
        }}
        endAdornment={
          <InputAdornment position='end' className='custom-endAdornment'>
            <IconButton
              onClick={() => handleClearSelectBox()}
              size='small'
              variant='onlyIcon'
              sx={{
                display: selectedBlocks ? '' : 'none',
              }}
              className='select-box-end-adornment-button'
            >
              <i className='bi bi-x end-adornment-close-button' />
            </IconButton>
          </InputAdornment>
        }
      >
        {isLoading || isFetching ? (
          <div className='circular-progress-box'>
            <CircularProgress size={25} />
          </div>
        ) : blocks && blocks.length > 0 ? (
          blocks.map((item, index) => (
            <MenuItem value={item.id} key={`block-${index + 1}`}>
              {item.name}
            </MenuItem>
          ))
        ) : (
          <div>
            <span className='no-data-found-wrapper select-box'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </Select>
    </FormControl>
  );
};

export default memo(CrmBlockFilter);
