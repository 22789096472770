import { TextField } from "@mui/material"
import { motion } from "framer-motion"
import React, { Fragment } from "react"
import formLocalizedHelperText from "utils/formLocalizedHelperText"
import { fadeUp } from "utils/motion"

const FormTextField2 = ({
	delay = 0,
	duration = 0.5,
	formik,
	fieldName,
	label,
	type = "text",
	variant = "outlined",
	onKeyDown = () => {},
	readOnly = false,
	disabled = false
}) => {
	const getFieldValue = () => {
		if (fieldName && fieldName.includes(".")) {
			let a = fieldName.split(".")
			let b = formik.values
			for (let i = 0; i < a.length; i++) {
				b = b[a[i]]
			}
			return b
		}
		return formik.values[fieldName] || ""
	}

	const getFieldValidation = (formikF) => {
		if (fieldName && fieldName.includes(".")) {
			let a = fieldName.split(".")
			let b = formik[formikF]
			for (let i = 0; i < a.length; i++) {
				if (b[a[i]]) {
					b = b[a[i]]
				} else {
					b = false
				}
			}
			return b
		}
		return formik[formikF][fieldName] || ""
	}

	return (
		<Fragment>
			{formik && formik.values && formik.touched && formik.errors && (
				<TextField
					component={motion.div}
					variants={fadeUp(30, "tween", delay, duration)}
					initial="hidden"
					animate="show"
					viewport={{ once: true, amount: 0.25 }}
					color="formColor"
					variant={variant}
					fullWidth
					id={fieldName}
					name={fieldName}
					label={label}
					type={type}
					error={
						getFieldValidation("touched") &&
						Boolean(getFieldValidation("errors"))
					}
					helperText={
						getFieldValidation("touched") &&
						formLocalizedHelperText(getFieldValidation("errors"))
					}
					value={getFieldValue()}
					onChange={(event) => {
						formik.setFieldValue(fieldName, event.target.value, true)
					}}
					onKeyDown={onKeyDown}
					InputProps={{
						readOnly: readOnly,
						disabled: disabled
					}}
					autoComplete="off"
				/>
			)}
		</Fragment>
	)
}

export default FormTextField2
