import { memo, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from 'api/axios';
import { fadeUp } from 'utils/motion';
import LanguageMenu from 'components/top-panel/LanguageMenu';
import useNotification from 'hooks/useNotification';
import FormPatternField from 'components/ui/form/FormPatternField';
import FormTextField from 'components/ui/form/FormTextField';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';
import Facebook from './Facebook';

const CustomerPreferences = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sendNotification = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [keyValue, setKeyValue] = useState(null);

  const goalList = [
    { name: t('customerPreferences.goalList.residence') },
    { name: t('customerPreferences.goalList.investment') },
    { name: t('customerPreferences.goalList.other') },
  ];

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['target', searchParams.get('k')],
    queryFn: async function () {
      const response = await axios.get(`/target?key=${searchParams.get('k')}`);
      return response.data.data;
    },
    retry: false,
  });
  const getValidations = (val) => {
    switch (val) {
      case 'name':
        return yup.string().required('customerPreferences.validation.name');
      case 'room':
        return yup.string().required('customerPreferences.validation.room');
      case 'goal':
        return yup.string().required('customerPreferences.validation.goal');
      case 'phone':
        return yup
          .string()
          .length(13, 'customerPreferences.validation.phoneValid')
          .required('customerPreferences.validation.phone');

      default:
        break;
    }
  };

  const initialValidationSchema = useMemo(() => {
    if (data?.columns?.length) {
      const validation = {};
      data?.columns?.forEach((field) => {
        validation[field.var_name] = getValidations(field.var_name);
      });
      return yup.object(validation);
    }
    return yup.object({});
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      room: '',
      goal: '',
    },
    validationSchema: initialValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        let newValues = {
          ...values,
        };
        if (keyValue) {
          newValues.key = keyValue;
        } else {
          delete newValues.key;
        }

        const response = await axios.post('/metalead', newValues, {
          headers: { 'Content-Type': 'application/json' },
        });
        if (response.data && response.data.status) {
          // eslint-disable-next-line no-undef
          if (typeof window.fbq === 'function')
            // eslint-disable-next-line no-undef
            window.fbq('trackCustom', 'UserTarget', newValues);
          sendNotification({
            msg: t('customerPreferences.alerts.success'),
            variant: 'success',
          });
          formik.resetForm();
          // navigate(window.location.origin);
          // window.close(``, `_parent`, ``);
          navigate(-1);
        }
        setIsSubmitting(false);
      } catch (error) {
        console.error(error);
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (searchParams.get('k')) {
      setKeyValue(searchParams.get('k'));
    } else {
      setKeyValue(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (data?.columns?.length) {
      data?.columns?.forEach((field) => {
        if (field.var_name === 'phone') {
          formik.setFieldValue('phone', '+998', false);
        }
      });
    }
  }, [data?.columns]);

  return (
    <div className='customer-preferences-wrapper'>
      {data?.target?.pixel_id ? (
        <Facebook pixelId={data?.target?.pixel_id} />
      ) : null}
      <div className='absolute top-5 right-5'>
        <LanguageMenu animate={true} />
      </div>
      <div className='form-wrapper'>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={1}
            rowSpacing={0.1}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            {data?.columns?.map(({ var_name }, i) => (
              <Grid item={true} sm={12} xs={12} key={var_name}>
                {var_name === 'phone' ? (
                  <FormPatternField
                    delay={i * 0.1}
                    label={t(`customerPreferences.fields.${var_name}`)}
                    fieldName={var_name}
                    formik={formik}
                    format='+############'
                    disabled={isSubmitting}
                    autoComplete='off'
                  />
                ) : var_name === 'room' ? (
                  <FormSimpleSelectField
                    label={t(`customerPreferences.fields.${var_name}`)}
                    formik={formik}
                    options={[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]}
                    itemLabel='id'
                    fieldName={var_name}
                    isItemSizeSmall={true}
                    disabled={isSubmitting}
                    delay={i * 0.1}
                  />
                ) : var_name === 'goal' ? (
                  <FormSimpleSelectField
                    label={t(`customerPreferences.fields.${var_name}`)}
                    formik={formik}
                    options={goalList}
                    itemValue='name'
                    fieldName={var_name}
                    isItemSizeSmall={true}
                    disabled={isSubmitting}
                    delay={i * 0.1}
                  />
                ) : (
                  <FormTextField
                    delay={i * 0.1}
                    label={t(`customerPreferences.fields.${var_name}`)}
                    fieldName={var_name}
                    formik={formik}
                    disabled={isSubmitting}
                    autoComplete='off'
                  />
                )}
              </Grid>
            ))}
            <Grid item={true} sm={12} xs={12}>
              <div className='text-center mt-3'>
                <Button
                  color='success'
                  variant='contained'
                  type='submit'
                  component={motion.button}
                  variants={fadeUp(30, 'tween', 0.2, 0.5)}
                  initial='hidden'
                  animate='show'
                  viewport={{ once: true, amount: 0.25 }}
                  disabled={isSubmitting}
                >
                  {(isSubmitting || isFetching || isLoading) && (
                    <CircularProgress
                      size={15}
                      color='inherit'
                      className='mr-1'
                    />
                  )}
                  {t('common.button.send')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};
export default memo(CustomerPreferences);
