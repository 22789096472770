import { ButtonBase, CircularProgress, Grid } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useQuery } from "react-query"

const AveragePriceForSquare = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)

	const { data, isLoading, isFetching } = useQuery({
		queryKey: "averagePriceForSquare",
		queryFn: async function () {
			const response = await axiosPrivate.get("/analytic/object/avarage")
			return response.data.data
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	return (
		<div className="residential-complex-item average-price-for-square">
			<div className="residential-complex-item-header">
				<div className="residential-complex-item-title average-price-for-square-title">
					1 m<sup>2</sup> maydon uchun o'rtacha narx
				</div>
			</div>

			<div className="average-price-for-square-body">
				<Grid
					container
					spacing={2}
					columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
				>
					{isLoading || isFetching ? (
						<Grid item={true} sm={12} xs={12}>
							<div className="flex items-center justify-center h-[100px]">
								<CircularProgress size={30} color="inherit" />
							</div>
						</Grid>
					) : data && data.length > 0 ? (
						data.map((item, index) => (
							<Grid
								item={true}
								lg={3}
								md={4}
								sm={6}
								xs={12}
								key={`average-price-for-square-item-${index}`}
							>
								<ButtonBase className="average-price-for-square-item">
									<div className="object-title">
										<i className="bi bi-buildings text-xl mr-1" />
										<div className="object-name">{item?.name}</div>
									</div>
									<div className="average-prices-wrapper">
										<div className="price-item">
											<div className="label">Sotilgan xonadonlar uchun</div>
											<div className="value">
												<NumericFormat
													value={item?.saled}
													displayType={"text"}
													allowNegative={false}
													thousandSeparator={" "}
													decimalScale={3}
													className="bg-transparent"
													suffix=" UZS"
												/>
											</div>
										</div>
										<div className="price-item">
											<div className="label">Sotuvdagi xonadonlar uchun</div>
											<div className="value">
												<NumericFormat
													value={item?.insale}
													displayType={"text"}
													allowNegative={false}
													thousandSeparator={" "}
													decimalScale={3}
													className="bg-transparent"
													suffix=" UZS"
												/>
											</div>
										</div>
										<div className="price-item">
											<div className="label">O'rtacha</div>
											<div className="value">
												<NumericFormat
													value={item?.avarage}
													displayType={"text"}
													allowNegative={false}
													thousandSeparator={" "}
													decimalScale={3}
													className="bg-transparent"
													suffix=" UZS"
												/>
											</div>
										</div>
									</div>
								</ButtonBase>
							</Grid>
						))
					) : (
						<Grid item={true} sm={12} xs={12}>
							<div className="no-data-found-wrapper h-[100px]">
								<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
								{t("common.global.noDataFound")}
							</div>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	)
}
export default AveragePriceForSquare
