import {
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const AllCalls = ({ data, isFetching }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangePage = (event, newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  };

  return (
    <div className='calls-history-tab-item'>
      <TableContainer className='tab-table-container' component='div'>
        <Table stickyHeader component='div'>
          <TableHead component='div'>
            <TableRow component='div'>
              <TableCell component='div'>Qo'ng'iroqlar</TableCell>
              <TableCell component='div'>Kimdan</TableCell>
              <TableCell component='div'>Kimga</TableCell>
              <TableCell component='div'>Davomiyligi</TableCell>
              <TableCell component='div'>Ichki raqam</TableCell>
              <TableCell component='div'>Status</TableCell>
            </TableRow>
          </TableHead>
          {isFetching ? (
            <TableBody className='overflow-hidden' component='div'>
              <TableRow component='div'>
                <TableCell colSpan={6}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : data && data?.data && data?.data?.length > 0 ? (
            <TableBody className='overflow-hidden' component='div'>
              {data?.data?.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={'row-' + rowIndex}
                    // component={Link}
                    // to={`/admin/crm/lead/edit/${row?.crm_lead_id}`}
                    // target='_blank'
                    className='no-underline'
                  >
                    <TableCell size='small' component='div'>
                      <div className='flex items-center'>
                        {row?.accountcode == 'outbound' ? (
                          <div className='call-icon text-base leading-4 border-2 border-green-700 text-green-600 rounded-full w-8 h-8 min-w-[2rem] min-h-[2rem] flex items-center justify-center mr-2'>
                            <i className='bi bi-telephone-outbound leading-4' />
                          </div>
                        ) : row?.accountcode == 'inbound' ? (
                          <div className='call-icon text-base leading-4 border-2 border-blue-700 text-blue-600 rounded-full w-8 h-8 min-w-[2rem] min-h-[2rem] flex items-center justify-center mr-2'>
                            <i className='bi bi-telephone-inbound leading-4' />
                          </div>
                        ) : row?.accountcode == 'missed' ? (
                          <div className='call-icon text-base leading-4 border-2 border-red-700 text-red-600 rounded-full w-8 h-8 min-w-[2rem] min-h-[2rem] flex items-center justify-center mr-2'>
                            <i className='bi bi-telephone-x leading-4' />
                          </div>
                        ) : (
                          ''
                        )}
                        <div className='text-[12px] text-gray-500'>
                          {moment(row?.created_at).format('DD/MM/YYYY HH:mm')}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {row?.caller_id_name}
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {row?.destination_number}
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {moment.utc(row?.user_talk_time * 1000).format('mm:ss')}
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {row?.accountcode === 'inbound'
                        ? row?.destination_number
                        : row?.caller_id_name}
                    </TableCell>
                    <TableCell size='small' component='div'>
                      {row?.user_talk_time == 0
                        ? 'Javobsiz'
                        : 'Muvaffaqqiyatli'}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody component='div'>
              <TableRow component='div'>
                <TableCell colSpan={6}>
                  <span className='text-gray-400 flex items-center justify-center'>
                    <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                    {t('common.global.noDataFound')}
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {data && data?.data && data?.data?.length > 0 && (
        <div className='p-3 mb-2 mt-4 flex items-center justify-center rounded-lg my-shadow-2'>
          <Pagination
            count={Math.ceil(data?.total / data.per_page) || 1}
            page={searchParams.get('page') || 1}
            onChange={handleChangePage}
            variant='outlined'
            color='primary'
            showFirstButton
            showLastButton
          />
        </div>
      )}
    </div>
  );
};
export default AllCalls;
