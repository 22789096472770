import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import useTopPanel from 'hooks/useTopPanel';
import SearchInput from 'components/SearchInput';
import BaseTable from 'components/ui/tables/BaseTable';
import ArrearsFilterComponent from 'components/ui/filters/ArrearsFilterComponent';

const Arrears = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setComponent(<div className='component-title'>{t('arrears.title')}</div>);
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full'>
            <Button
              variant='filterOutlined'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setExpanded((prev) => !prev)}
            >
              {t('common.button.filter')}
            </Button>
            <SearchInput inputKey='name' />
          </div>
          <div className='header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4'>
            <Button
              variant='action'
              color='info'
              onClick={() => {
                setRefetch(true);
              }}
              className='!mr-2'
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='action'
              color='default'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
        {expanded && (
          <div className='my-shadow-2 rounded-lg px-4 w-full mt-2'>
            <ArrearsFilterComponent />
          </div>
        )}
      </div>

      <div className='component-table-wrapper'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='dictionary/debitors'
          emitTableColumns={{ open, setOpen }}
          tableName='arrears'
          headCells={[
            { code: 'name', label: t('common.table.contractName') },
            { code: 'custom', label: t('common.table.custom') },
            { code: 'phone', label: t('common.table.phone') },
            { code: 'sum', label: t('common.table.arrears') },
            { code: 'date', label: t('common.table.debtPaymentDate') },
          ]}
          columns={[
            { code: 'name' },
            { code: 'custom' },
            {
              code: 'phone',
              type: 'multipleFields',
              fields: ['phone', 'phone2'],
            },
            { code: 'sum' },
            { code: 'date', type: 'date' },
          ]}
          actionContractView={true}
        />
      </div>
    </div>
  );
};

export default Arrears;
