import React from 'react';
import { t } from 'i18next';

function RenderTitle({ item, no_hours }) {
  const turnTOHour = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours / 10 > 1 ? hours : `0${hours}`}:${
      minutes / 10 > 1 ? minutes : `0${minutes}`
    }:${seconds / 10 > 1 ? seconds : `0${seconds}`}`;
  };
  return (
    <div className='text-sm'>
      <span className=''>{item?.name || ''}</span>
      {!no_hours && (
        <p>
          <span>{t('boss.crm.calls.duration')}: </span>
          <span className='text-blue-700'>
            {turnTOHour(item?.totaltalk) || ''}
          </span>
        </p>
      )}
    </div>
  );
}

export default RenderTitle;
