import { Button } from '@mui/material';
import { Fragment, useState } from 'react';
import CallsHistoryDrawer from './CallsHistoryDrawer';

const CallsHistory = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Fragment>
      <Button
        variant='telephone'
        color='primary'
        onClick={() => setOpenDrawer(true)}
      >
        <i className='bi bi-telephone' />
      </Button>

      <CallsHistoryDrawer open={openDrawer} setOpen={setOpenDrawer} />
    </Fragment>
  );
};
export default CallsHistory;
