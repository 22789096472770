import {
	Button,
	CircularProgress,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	TextField
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { colors } from "shared/colors"

const CommentStaffSelect = ({
	selectedStaff = {},
	setSelectedStaff = () => {}
}) => {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [searchName, setSearchName] = useState("")
	const [staffsList, setStaffsList] = useState([])
	const [staffsCloneList, setStaffsCloneList] = useState([])
	let anchorEl = document.getElementById("lead-comment-staff-btn")

	const { isLoading, isFetching } = useQuery({
		queryKey: "user_idSelect",
		queryFn: async function () {
			const response = await axiosPrivate.get("/dictionary/staffes")
			return response.data.data
		},
		onSuccess: (data) => {
			setStaffsList([...data])
			setStaffsCloneList([...data])
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	const handleChangeType = (item) => {
		setSelectedStaff(item)
		menuToggle()
	}
	const menuToggle = () => {
		setOpen((prev) => !prev)
		resetSearch()
	}

	const handleSearchName = (name) => {
		setSearchName(name)
		if (name) {
			setStaffsList([
				...staffsList.filter((item) =>
					item.name.toLowerCase().includes(name.toLowerCase())
				)
			])
		} else {
			setStaffsList([...staffsCloneList])
		}
	}

	const resetSearch = () => {
		setSearchName("")
		setStaffsList([...staffsCloneList])
	}

	return (
		<div className="staffs-wrapper">
			<Button
				id="lead-comment-staff-btn"
				className="lead-comment-staff-btn"
				onClick={menuToggle}
				variant="select-menu"
				size="extraSmall"
			>
				{selectedStaff?.name}
			</Button>
			<Menu
				open={open}
				anchorEl={anchorEl}
				disableScrollLock={true}
				onClose={menuToggle}
				className="lead-comment-staffs-menu"
				PaperProps={{
					sx: {
						marginTop: "0.5rem"
					}
				}}
			>
				{isLoading || isFetching ? (
					<div className="circular-progress-box">
						<CircularProgress size={20} />
					</div>
				) : staffsCloneList && staffsCloneList.length > 0 ? (
					<div>
						<div className="mx-2">
							<TextField
								color="primary"
								variant="outlined"
								fullWidth
								id="search"
								name="search"
								label=""
								autoComplete="off"
								value={searchName}
								onInput={(event) => handleSearchName(event.target?.value)}
								InputProps={{
									endAdornment: (
										<InputAdornment
											position="end"
											className="custom-endAdornment"
										>
											{searchName ? (
												<IconButton
													onClick={() => resetSearch()}
													variant="onlyIcon"
												>
													<i className="bi bi-x text-lg leading-4" />
												</IconButton>
											) : (
												<IconButton variant="onlyIcon" disabled disableRipple>
													<i className="bi bi-search text-sm leading-4" />
												</IconButton>
											)}
										</InputAdornment>
									)
								}}
								className="lead-comment-staff-search-input"
								sx={{
									"& .MuiOutlinedInput-root.Mui-focused i": {
										color: colors.baseColor
									},
									"& .MuiOutlinedInput-root i": {
										color: colors.grayColor
									}
								}}
							/>
						</div>
						{staffsList.length > 0 ? (
							staffsList.map((item, index) => (
								<MenuItem
									key={`comment-staff-${index}`}
									onClick={() => handleChangeType(item)}
									className={
										selectedStaff?.id === item?.id
											? "lead-comment-staff-item is-active"
											: "lead-comment-staff-item"
									}
								>
									{item.name}
								</MenuItem>
							))
						) : (
							<div>
								<span className="no-data-found-wrapper text-[12px] leading-4 px-4">
									<i className="bi bi-exclamation-octagon text-base mr-1" />{" "}
									Xodim topilmadi!
								</span>
							</div>
						)}
					</div>
				) : (
					<div>
						<span className="no-data-found-wrapper select-box px-4">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				)}
			</Menu>
		</div>
	)
}
export default CommentStaffSelect
