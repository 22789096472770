const colors = {
	blackColor: "#000000",
	whiteColor: "#ffffff",
	baseColor: "#32064A",
	baseColorLight: "#8e2bc4",
	baseColorLightF15: "#8e2bc426",
	baseColorLightHover: "#32064a26",
	baseColorDisabled: "#bb8cd3",
	baseColorHover: "#7e1bb4",
	baseColorActive: "#360052",
	baseColorOutline: "#6901a06c",
	baseColorDeprecatedBg: "#bb89d5",
	baseColorDeprecatedBorder: "#8c3ab8",
	base1: "#e0dae4",
	base2: "#d7c6df",
	base3: "#bb89d5",
	base4: "#7a1aae",
	baseColorActiveDeprecatedF30: "#dbc8e64e",
	baseColorActiveDeprecatedD02: "#d5c5de",
	warningColor: "#f8a62a",
	successColor: "#57c820",
	infoColor: "#0288d1",
	errorColor: "#f40606",
	shadowColor: "#bb89d585",
	formColor: "#007bb2",
	footerColor: "#333333",
	grayColor: "#9ca3af"
}

const colorSeparators = {
	blackColor: "0, 0, 0",
	whiteColor: "255, 255, 255",
	baseColor: "50, 6, 74",
	baseColorLight: "142, 43, 196",
	baseColorLightF15: "142, 43, 196, 0.149",
	baseColorLightHover: "50, 6, 74, 0.149",
	baseColorDisabled: "187, 140, 211",
	baseColorHover: "126, 27, 180",
	baseColorActive: "54, 0, 82",
	baseColorOutline: "105, 1, 160, 0.424",
	baseColorDeprecatedBg: "187, 137, 213",
	baseColorDeprecatedBorder: "140, 58, 184",
	base1: "224, 218, 228",
	base2: "215, 198, 223",
	base3: "187, 137, 213",
	base4: "122, 26, 174",
	baseColorActiveDeprecatedF30: "219, 200, 230, 0.306",
	baseColorActiveDeprecatedD02: "213, 197, 222",
	warningColor: "248, 166, 42",
	successColor: "87, 200, 32",
	infoColor: "2, 136, 209",
	errorColor: "245, 5, 5",
	formColor: "0, 123, 178",
	footerColor: "51, 51, 51"
}

module.exports = { colors, colorSeparators }
