import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Pagination
} from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const PaymentTermModal = ({ open, setOpen, data = {} }) => {
	const { t } = useTranslation()
	const [page, setPage] = useState(1)

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span>
					{data?.label}({data?.items.length} ta)
				</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<div className="payment-term-modal-wrapper">
					{data?.items && data?.items.length > 0 ? (
						<div className="items-wrapper">
							{data?.items
								.slice(4 * (page - 1), page * 4)
								.map((item, index) => (
									<div key={`item-${index}`} className="item-data">
										<div className="item-row">
											<div className="item-label">Mijoz</div>
											<div className="item-value">{item?.custom}</div>
										</div>
										<div className="item-row">
											<div className="item-label">Telefon</div>
											<div className="item-value">{item?.phone}</div>
										</div>
										<div className="item-row">
											<div className="item-label">Shartnoma</div>
											<div className="item-value">{item?.name}</div>
										</div>
										<div className="item-row">
											<div className="item-label">
												O'rtacha kechikish muddati
											</div>
											<div className="item-value">{item?.days} kun</div>
										</div>
									</div>
								))}
							<div className="p-3 mb-2 mt-4 flex items-center justify-center rounded-lg my-shadow-2">
								<Pagination
									count={Math.ceil(data?.items.length / 4) || 1}
									page={page}
									onChange={(event, newPage) => {
										setPage(newPage)
									}}
									variant="outlined"
									color="primary"
									showFirstButton
									showLastButton
								/>
							</div>
						</div>
					) : (
						<div>
							<span className="no-data-found-wrapper">
								<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
								{t("common.global.noDataFound")}
							</span>
						</div>
					)}
				</div>
			</DialogContent>
		</Dialog>
	)
}
export default PaymentTermModal
