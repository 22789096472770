const CITIZENSHIP_TYPE = {
	UZBEKISTAN: {
		code: "UZBEKISTAN",
		label: "common.typeList.citizenshipType.uzbekistan"
	},
	FOREIGN_CITIZEN: {
		code: "FOREIGN_CITIZEN",
		label: "common.typeList.citizenshipType.foreignCitizen"
	}
}

export default CITIZENSHIP_TYPE
