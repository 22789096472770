import {
	Button,
	ButtonBase,
	Checkbox,
	FormControl,
	FormControlLabel,
	Tab,
	Tabs,
	TextField,
	ToggleButton,
	ToggleButtonGroup
} from "@mui/material"
import SimpleSelectField from "components/ui/simple-fields/select/SimpleSelectField"
import { Fragment, useEffect, useState } from "react"
import { motion } from "framer-motion"
import { fadeUp, stepperItem } from "utils/motion"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import { useFormik } from "formik"
import * as yup from "yup"
import useNotification from "hooks/useNotification"
import { NumericFormat } from "react-number-format"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import StepThreeTabOne from "./step-three-tabs/StepThreeTabOne"
import StepThreeTabTwo from "./step-three-tabs/StepThreeTabTwo"
import useCurrency from "hooks/useCurrency"
import CurrencySubContent from "components/ui/text-formats/CurrencySubContent"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useQuery } from "react-query"
import { formatSingle } from "utils/formatOddDecimal"

const validationSchema = yup.object({
	month: yup
		.number()
		.default(1)
		.required("To'lov muddati(necha oyda)ni kiritish majburiy!"),
	date: yup
		.date()
		.nullable()
		.typeError("Vaqti to'g'ri bo'lishi kerak!")
		.min(
			new Date("Sat Jan 01 2000 00:00:00 GMT+0500"),
			"Sana 2000-yildan katta bo'lishi lozim!"
		)
		.required("Birinchi to'lov sanasini kiritish majburiy!"),
	startDate: yup
		.date()
		.nullable()
		.typeError("Vaqti to'g'ri bo'lishi kerak!")
		.min(
			new Date("Sat Jan 01 2000 00:00:00 GMT+0500"),
			"Sana 2000-yildan katta bo'lishi lozim!"
		)
		.required("Boshlang'ich to'lov sanasini kiritish majburiy!"),
	sum: yup
		.number()
		.default(0)
		.required("Shartnoma summasini kiritish majburiy!"),
	sumdiscount: yup.number().default(0).min(0).optional(),
	left: yup.number().required("Qoldiq summa kiritish majburiy!"),
	discount: yup.number().default(0).optional(),
	discountPercent: yup.number().default(0).min(0).max(100).optional(),
	startPrice: yup.number().default(0).optional(),
	startPricePercent: yup.number().default(0).min(0).max(100).optional(),
	lastPrice: yup.number().default(0).optional(),
	lastPricePercent: yup.number().default(0).min(0).max(100).optional(),
	interval: yup
		.number()
		.default(1)
		.required("Vaqt oralig'ini kiritish majburiy!"),
	valute: yup.number().default(1).optional(),
	startPrice: yup
		.number()
		.default(0)
		.required("Boshlang'ich to'lov miqdorini kiritish majburiy!"),
	price: yup
		.number()
		.default(0)
		.required(
			<span>
				1 m<sup>2</sup> narxini kiritish majburiy!
			</span>
		),
	comment: yup.string().optional(),
	contractName: yup.string().required("Shartnoma raqamini kiritish majburiy!")
})

const StepThree = ({ appear, direction, next, back, homeData, setData }) => {
	const [repairData, setRepairData] = useState("")
	const [isRepaired, setIsRepaired] = useState("0")
	const [paymentDataList, setPaymentDataList] = useState([])
	const [paymentDataList2, setPaymentDataList2] = useState([])
	const [calculateByPrice, setCalculateByPrice] = useState(false)
	const [tabValue, setTabValue] = useState("automatic")
	const { currencyData, updateCurrencyData } = useCurrency()
	const [selectedCurrency, setSelectedCurrency] = useState("0")
	const [selectedCurrency2, setSelectedCurrency2] = useState("0")
	const sendNotification = useNotification()
	const axiosPrivate = useAxiosPrivate()
	const [contractTypes, setContractTypes] = useState([])
	const [contractType, setContractType] = useState(0)

	const formik = useFormik({
		initialValues: {
			month: "1",
			date: "",
			startDate: "",
			sum: "",
			sumdiscount: "",
			left: "",
			discountPercent: "0",
			discount: "0",
			interval: "1",
			valute: 1,
			startPricePercent: "0",
			startPrice: "0",
			lastPricePercent: "0",
			lastPrice: "0",
			price: "",
			pricediscount: "",
			comment: "",
			contractName: ""
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {}
	})

	const { data: contractTypesData } = useQuery({
		queryKey: "contractTypes",
		queryFn: async function () {
			const response = await axiosPrivate.get("/dictionary/contracttypes")
			return response.data.data
		},
		onSuccess: (data) => {
			setContractTypes(
				data.map((item) => {
					return { code: item.id, label: item.name }
				})
			)
		},
		retry: false
	})

	const handleNext = () => {
		if (!formik.isValid) {
			formik.handleSubmit()
		}
		let currMonth =
			contractType === 3 ? formik.values.month - 1 : formik.values.month
		let allSumPaymentDataList = paymentDataList.reduce(
			(acc, curr) => acc + curr?.sum,
			0
		)
		if (selectedCurrency === "0" && selectedCurrency2 === "1") {
			allSumPaymentDataList *= formik.values.valute
		}
		if (selectedCurrency === "1" && selectedCurrency2 === "0") {
			allSumPaymentDataList /= formik.values.valute
		}
		let allSumPaymentDataList2 = paymentDataList2.reduce(
			(acc, curr) => acc + curr?.sum,
			0
		)
		let difference = Math.abs(allSumPaymentDataList - formik.values.left)
		if (selectedCurrency === "0" && selectedCurrency2 === "1") {
			difference /= formik.values.valute
			difference /= currMonth
		}

		console.log("paymentDataList = ", paymentDataList)
		console.log("paymentDataList2 = ", paymentDataList2)
		console.log("allSumPaymentDataList = ", allSumPaymentDataList)
		console.log("allSumPaymentDataList2 = ", allSumPaymentDataList2)
		console.log("difference = ", difference)
		console.log("formik.values = ", formik.values)

		if (
			repairData &&
			formik.isValid &&
			((tabValue === "automatic" &&
				paymentDataList.length === parseInt(currMonth) &&
				difference < 10) ||
				(tabValue === "manually" &&
					paymentDataList2.length === parseInt(currMonth) &&
					allSumPaymentDataList2 === formik.values.left))
		) {
			setData({
				price: formik.values.price,
				sum: formik.values.sum, // this filed in used for show contract sum in ContractAdd.jsx, not in StepFour.jsx
				start_price: formik.values.startPrice,
				startDate: formik.values.startDate,
				month: formik.values.month,
				payments:
					tabValue === "automatic"
						? addExtraRowToList(paymentDataList)
						: tabValue === "manually"
						? addExtraRowToList(paymentDataList2)
						: [],
				discount: formik.values.discount,
				name: formik.values.contractName,
				comment: formik.values.comment,
				isrepaired: isRepaired,
				isvalute: selectedCurrency,
				valute: formik.values.valute,
				type: contractType
			})
			next()
		} else if (!repairData) {
			sendNotification({
				msg: "Iltimos uyning ta'mir turini tanlang!",
				variant: "warning"
			})
		} else if (
			(tabValue === "automatic" &&
				paymentDataList.length !== parseInt(currMonth)) ||
			(tabValue === "manually" && paymentDataList2.length !== currMonth)
		) {
			sendNotification({
				msg: "Jadvaldagi to'lovlar soni kreditni qaytarish muddati bilan mos emas!",
				variant: "warning"
			})
		} else if (
			(tabValue === "automatic" &&
				allSumPaymentDataList !== formik.values.left) ||
			(tabValue === "manually" && allSumPaymentDataList2 !== formik.values.left)
		) {
			sendNotification({
				msg: "Qoldiq summada xatolik bor!",
				variant: "warning"
			})
		}
	}

	const addExtraRowToList = (pList) => {
		if (contractType === 3) {
			let lastEl = pList[pList.length - 1]
			return [
				...pList,
				{
					id: lastEl.id + 1,
					date: moment(lastEl.date).add(1, "month").format("YYYY-MM-DD"),
					sum: formik.values.lastPrice
				}
			]
		}
		return pList
	}

	const handleRepairType = (type, data) => {
		Array.from(document.getElementsByClassName("repair-type")).forEach((item) =>
			item.classList.remove("item-selected")
		)
		if (type === "repaired") {
			setIsRepaired("1")
			document.getElementById("repaired").classList.add("item-selected")
		} else if (type === "noRepaired") {
			setIsRepaired("0")
			document.getElementById("non-repaired").classList.add("item-selected")
		}
		formik.setFieldValue("price", data.price, true)
		formik.setFieldValue("pricediscount", data.price, true)
		formik.setFieldValue(
			"sum",
			parseFloat(data.square) * parseFloat(data.price),
			true
		)
		formik.setFieldValue(
			"sumdiscount",
			parseFloat(data.square) * parseFloat(data.price),
			true
		)
		formik.setFieldValue(
			"left",
			parseFloat(data.square) * parseFloat(data.price) -
				formik.values.startPrice -
				formik.values.discount,
			true
		)
		setRepairData(data)
	}

	const getPaymentData = () => {
		if (
			!isNaN(formik.values.month) &&
			!isNaN(formik.values.left) &&
			formik.values.date &&
			!isNaN(formik.values.interval)
		) {
			let arr = []
			let currM =
				contractType === 3 ? formik.values.month - 1 : formik.values.month
			for (let i = 1; i <= currM; i++) {
				arr.push({
					id: i,
					date: moment(formik.values.date)
						.add(formik.values.interval * (i - 1), "month")
						.format("YYYY-MM-DD"),
					left:
						formatSingle(
							formik.values.left * 100 -
								Math.floor(formik.values.left / currM) * (i - 1) * 100,
							2
						) / 100,
					sum: Math.floor(formik.values.left / currM)
				})
			}
			if (arr.length > 1) {
				arr[arr.length - 1] = {
					...arr[arr.length - 1],
					sum: arr[arr.length - 1].left
				}
			}
			setPaymentDataList(arr)
		}
	}

	const handleCurrencyChange = (value) => {
		if (selectedCurrency === value) {
			return
		}
		setSelectedCurrency(value)
		setSelectedCurrency2(value)
		if (formik.values.valute && formik.values.valute > 0) {
			if (value === "1") {
				formik.setFieldValue(
					"price",
					parseFloat(
						parseFloat(
							(formik.values.price || "0") / formik.values.valute
						).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"sum",
					parseFloat(
						parseFloat(
							(formik.values.sum || "0") / formik.values.valute
						).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"startPrice",
					parseFloat(
						parseFloat(
							(formik.values.startPrice || "0") / formik.values.valute
						).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"left",
					parseFloat(
						parseFloat(
							(formik.values.left || "0") / formik.values.valute
						).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"discount",
					parseFloat(
						parseFloat(
							(formik.values.discount || "0") / formik.values.valute
						).toFixed(1)
					),
					true
				)
			} else if (value === "0") {
				formik.setFieldValue(
					"price",
					parseFloat((formik.values.price || "0") * formik.values.valute),
					true
				)
				formik.setFieldValue(
					"sum",
					parseFloat((formik.values.sum || "0") * formik.values.valute),
					true
				)
				formik.setFieldValue(
					"startPrice",
					parseFloat((formik.values.startPrice || "0") * formik.values.valute),
					true
				)
				formik.setFieldValue(
					"left",
					parseFloat((formik.values.left || "0") * formik.values.valute),
					true
				)
				formik.setFieldValue(
					"discount",
					parseFloat((formik.values.discount || "0") * formik.values.valute),
					true
				)
			}
		}
	}
	const handleCurrencyChange2 = (value) => {
		if (selectedCurrency2 !== value) {
			setSelectedCurrency2(value)
		}
	}
	const handleContractType = (value) => {
		setContractType(value)
		if (value !== 3) {
			formik.setFieldValue("lastPrice", 0, true)
			formik.setFieldValue("lastPricePercent", 0, true)
			formik.setFieldValue(
				"left",
				formik.values.sumdiscount - formik.values.startPrice
			)
		}
	}

	const handleChangePriceForSquare = (value) => {
		formik.setFieldValue("price", value, true)
		if (repairData?.square) {
			let newSum = +value * +repairData?.square
			let newDiscount = (newSum * formik.values.discountPercent) / 100
			let newStartPrice =
				((newSum - newDiscount) * formik.values.startPricePercent) / 100
			let newLastPrice = 0
			if (contractType === 3) {
				newLastPrice =
					((newSum - newDiscount) * formik.values.lastPricePercent) / 100
				formik.setFieldValue("lastPrice", newLastPrice)
			}
			let newLeft = newSum - newDiscount - newStartPrice - newLastPrice
			formik.setFieldValue("sum", newSum)
			formik.setFieldValue("left", newLeft)
			formik.setFieldValue("discount", newDiscount)
			formik.setFieldValue("startPrice", newStartPrice)
			formik.setFieldValue(
				"pricediscount",
				(value * (100 - formik.values.discountPercent)) / 100
			)
			formik.setFieldValue("sumdiscount", newSum - newDiscount)
		}
	}

	const handleChangePriceDiscount = (value) => {
		formik.setFieldValue("pricediscount", value, true)
		let change = (formik.values.price - value) * homeData?.square
		if (value > 0) formik.setFieldValue("discount", change)
		else {
			formik.setFieldValue("discount", formik.values.price * homeData?.square)
		}
		formik.setFieldValue("sumdiscount", formik.values.sum - change)
		formik.setFieldValue("discountPercent", (change / formik.values.sum) * 100)
		let newStartPrice =
			((formik.values.sum - change) * formik.values.startPricePercent) / 100
		let newLastPrice = 0
		if (contractType === 3) {
			newLastPrice =
				((formik.values.sum - change) * formik.values.lastPricePercent) / 100
			formik.setFieldValue("lastPrice", newLastPrice)
		}
		let newLeft = formik.values.sum - change - newStartPrice - newLastPrice
		formik.setFieldValue("left", newLeft)
		formik.setFieldValue("startPrice", newStartPrice)
	}

	const handleChangeSumDiscount = (value) => {
		formik.setFieldValue("sumdiscount", value)
		formik.setFieldValue(
			"pricediscount",
			formatSingle(value / homeData?.square, 2),
			true
		)
		formik.setFieldValue("discount", formik.values.sum - value)
		formik.setFieldValue(
			"discountPercent",
			formatSingle(((formik.values.sum - value) * 100) / formik.values.sum)
		)
		let newStartPrice = (value * formik.values.startPricePercent) / 100
		let newLastPrice = 0
		if (contractType === 3) {
			newLastPrice = (value * formik.values.lastPricePercent) / 100
			formik.setFieldValue("lastPrice", newLastPrice)
		}
		let newLeft = value - newStartPrice - newLastPrice
		formik.setFieldValue("left", newLeft)
		formik.setFieldValue("startPrice", newStartPrice)
	}

	const handleBlurSum = () => {
		if (
			repairData?.square &&
			calculateByPrice &&
			formik.values.sum !== formik.values.price * repairData?.square
		) {
			let changedPrice = Math.ceil(formik.values.sum / repairData?.square)
			let mod = changedPrice % 100
			let newPrice = mod > 0 ? changedPrice - mod + 100 : changedPrice
			formik.setFieldValue("price", newPrice, true)
			if (repairData?.square) {
				let newSum = +newPrice * +repairData?.square
				let newDiscount = newSum - +formik.values.sum
				let newStartPrice =
					((newSum - newDiscount) * formik.values.startPricePercent) / 100
				let newLastPrice = 0
				if (contractType === 3) {
					newLastPrice =
						((newSum - newDiscount) * formik.values.lastPricePercent) / 100
					formik.setFieldValue("lastPrice", newLastPrice)
				}
				let newLeft = newSum - newDiscount - newStartPrice - newLastPrice
				formik.setFieldValue("sum", newSum)
				formik.setFieldValue("left", newLeft)
				formik.setFieldValue("discount", newDiscount)
				formik.setFieldValue("startPrice", newStartPrice)
				formik.setFieldValue(
					"pricediscount",
					(newPrice * (100 - formik.values.discountPercent)) / 100
				)
				formik.setFieldValue("sumdiscount", newSum - newDiscount)
			}
		}
	}

	const handleChangeStartPricePercent = (value) => {
		let isValid = value <= 100 - formik.values.lastPricePercent
		formik.setFieldValue(
			"startPricePercent",
			isValid ? value : 100 - formik.values.lastPricePercent,
			true
		)
		let newStartPrice = isValid
			? formik.values.sumdiscount * (value / 100)
			: formik.values.sumdiscount *
			  ((100 - formik.values.lastPricePercent) / 100)
		let newLeft =
			formik.values.sumdiscount - newStartPrice - formik.values.lastPrice
		formik.setFieldValue("left", newLeft, true)
		formik.setFieldValue("startPrice", newStartPrice, true)
	}

	const handleChangeStartPrice = (value) => {
		let isValid = value <= formik.values.sumdiscount - formik.values.lastPrice
		formik.setFieldValue(
			"startPrice",
			isValid ? value : formik.values.sumdiscount - formik.values.lastPrice,
			true
		)
		let newStartPricePercent = isValid
			? formatSingle((100 * value) / formik.values.sumdiscount, 2)
			: formatSingle(
					(100 * (formik.values.sumdiscount - formik.values.lastPrice)) /
						formik.values.sumdiscount,
					2
			  )
		formik.setFieldValue(
			"left",
			isValid ? formik.values.sumdiscount - value - formik.values.lastPrice : 0,
			true
		)
		formik.setFieldValue("startPricePercent", newStartPricePercent, true)
	}

	const handleChangeLastPricePercent = (value) => {
		let isValid = value <= 100 - formik.values.startPricePercent
		formik.setFieldValue(
			"lastPricePercent",
			isValid ? value : 100 - formik.values.startPricePercent,
			true
		)
		let newLastPrice = isValid
			? formik.values.sumdiscount * (value / 100)
			: formik.values.sumdiscount *
			  ((100 - formik.values.startPricePercent) / 100)
		let newLeft =
			formik.values.sumdiscount - newLastPrice - formik.values.startPrice
		formik.setFieldValue("left", newLeft, true)
		formik.setFieldValue("lastPrice", newLastPrice, true)
	}

	const handleChangeLastPrice = (value) => {
		let isValid = value <= formik.values.sumdiscount - formik.values.startPrice
		formik.setFieldValue(
			"lastPrice",
			isValid ? value : formik.values.sumdiscount - formik.values.startPrice,
			true
		)
		let newLastPricePercent = isValid
			? formatSingle((100 * value) / formik.values.sumdiscount, 2)
			: formatSingle(
					(100 * (formik.values.sumdiscount - formik.values.startPrice)) /
						formik.values.sumdiscount,
					2
			  )
		formik.setFieldValue(
			"left",
			isValid
				? formik.values.sumdiscount - value - formik.values.startPrice
				: 0,
			true
		)
		formik.setFieldValue("lastPricePercent", newLastPricePercent, true)
	}

	const handleChangeDiscountPercent = (value) => {
		let newDiscountPercent = value <= 100 ? value : 100
		let newDiscount = (+formik.values.sum * +newDiscountPercent) / 100

		formik.setFieldValue("discountPercent", newDiscountPercent, true)

		formik.setFieldValue("startPricePercent", 0, true)
		formik.setFieldValue("startPrice", 0, true)

		if (contractType === 3) {
			formik.setFieldValue("lastPrice", 0, true)
			formik.setFieldValue("lastPricePercent", 0, true)
		}

		formik.setFieldValue("left", formik.values.sum - newDiscount, true)
		formik.setFieldValue("discount", newDiscount, true)

		formik.setFieldValue(
			"pricediscount",
			+formik.values.price - (+formik.values.price * newDiscountPercent) / 100,
			true
		)

		formik.setFieldValue("sumdiscount", +formik.values.sum - newDiscount, true)
	}

	const handleChangeDiscount = (value) => {
		let newDiscount = value <= formik.values.sum ? value : formik.values.sum
		let newDiscountPercent = (newDiscount * 100) / formik.values.sum

		formik.setFieldValue("discount", newDiscount, true)

		formik.setFieldValue("startPricePercent", 0, true)
		formik.setFieldValue("startPrice", 0, true)

		if (contractType === 3) {
			formik.setFieldValue("lastPrice", 0, true)
			formik.setFieldValue("lastPricePercent", 0, true)
		}

		formik.setFieldValue("left", formik.values.sum - newDiscount, true)

		formik.setFieldValue("discountPercent", newDiscountPercent, true)

		formik.setFieldValue(
			"pricediscount",
			+formik.values.price - (+formik.values.price * newDiscountPercent) / 100,
			true
		)

		formik.setFieldValue("sumdiscount", +formik.values.sum - newDiscount, true)
	}

	useEffect(() => {
		convertSumToUsd()
	}, [selectedCurrency2])

	const convertSumToUsd = () => {
		let factor = 1

		if (selectedCurrency === "0") {
			if (selectedCurrency2 === "0") {
				factor = formik.values.valute
			} else {
				factor = 1 / formik.values.valute
			}
		} else {
			if (selectedCurrency2 === "0") {
				factor = formik.values.valute
			} else {
				factor = 1 / formik.values.valute
			}
		}
		let left = 0,
			lastSum = 0,
			currentSum = 0,
			updatedData = []
		for (let i = 0; i < paymentDataList.length; i++) {
			if (i == 0) {
				left = Math.round(paymentDataList[i].left * factor)
				lastSum = Math.round(paymentDataList[i].sum * factor)
			} else {
				left = left - lastSum
			}
			if (i == paymentDataList.length - 1) {
				currentSum = left
			} else {
				currentSum = Math.round(paymentDataList[i].sum * factor)
			}

			updatedData.push({
				...paymentDataList[i],
				left: left,
				sum: currentSum,
				isvalute: selectedCurrency2
			})
		}
		setPaymentDataList(updatedData)
	}

	useEffect(() => {
		getPaymentData()
	}, [formik.values])

	useEffect(() => {
		if (homeData) {
			formik.setFieldValue(
				"contractName",
				`${homeData?.blocks?.objects?.short}-${homeData?.blocks?.objects?.number}`,
				true
			)
			setSelectedCurrency(homeData?.isvalute || "0")
		}
	}, [homeData])

	useEffect(() => {
		updateCurrencyData()
		if (formik.values.valute === 1) {
			formik.values.valute = currencyData?.sum
		}
	}, [])

	// console.log("paymentDataList = ", paymentDataList)

	return (
		<motion.div
			variants={stepperItem({
				direction: direction,
				duration: 0
			})}
			initial="hidden"
			animate={appear ? "show" : "hidden"}
		>
			<div className="component-add-wrapper">
				<div className="component-add-body overflow-y-auto h-full">
					<div className="home-repair-type">
						<div className="title">Shartnoma to'lovlari</div>
						<div className="repair-types">
							<ButtonBase
								className="repair-type repair-type-non-repaired !mr-2"
								id="non-repaired"
								onClick={() =>
									handleRepairType("noRepaired", {
										price: homeData?.norepaired,
										square: homeData?.square
									})
								}
							>
								<div className="flex items-center justify-between w-full">
									<span>Ta'mirsiz</span>
									<span>
										1 m<sup>2</sup>:{" "}
										<CurrencyFormat
											value={homeData?.norepaired}
											suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
										/>
									</span>
								</div>
								{formik.values.valute &&
									formik.values.valute > 0 &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={parseFloat(
													formik.values.valute *
														parseFloat(homeData?.norepaired)
												)}
												className="ml-1"
											/>
										</div>
									)}
								<div className="flex items-center justify-between w-full mt-2">
									<span>
										{homeData?.square} m<sup>2</sup>
									</span>
									<span>
										{homeData?.square && homeData?.norepaired && (
											<CurrencyFormat
												value={homeData?.square * homeData?.norepaired}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</span>
								</div>
								{formik.values.valute &&
									formik.values.valute > 0 &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={parseFloat(
													formik.values.valute *
														parseFloat(homeData?.square * homeData?.norepaired)
												)}
												className="ml-1"
											/>
										</div>
									)}
							</ButtonBase>
							<ButtonBase
								className="repair-type repair-type-repaired !ml-2"
								id="repaired"
								onClick={() =>
									handleRepairType("repaired", {
										price: homeData?.repaired,
										square: homeData?.square
									})
								}
							>
								<div className="flex items-center justify-between w-full">
									<span>Ta'mirli</span>
									<span>
										1 m<sup>2</sup>:{" "}
										<CurrencyFormat
											value={homeData?.repaired}
											suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
										/>
									</span>
								</div>
								{formik.values.valute &&
									formik.values.valute > 0 &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={parseFloat(
													formik.values.valute * parseFloat(homeData?.repaired)
												)}
												className="ml-1"
											/>
										</div>
									)}
								<div className="flex items-center justify-between w-full mt-2">
									<span>
										{homeData?.square} m<sup>2</sup>
									</span>
									<span>
										{homeData?.square && homeData?.repaired && (
											<CurrencyFormat
												value={homeData?.square * homeData?.repaired}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</span>
								</div>
								{formik.values.valute &&
									formik.values.valute > 0 &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={parseFloat(
													formik.values.valute *
														parseFloat(homeData?.square * homeData?.repaired)
												)}
												className="ml-1"
											/>
										</div>
									)}
							</ButtonBase>
						</div>
						<div className="flex justify-center mt-4">
							<ToggleButtonGroup
								color="primary"
								value={selectedCurrency}
								exclusive
								onChange={(event) => handleCurrencyChange(event.target.value)}
								aria-label="currency"
							>
								<ToggleButton color="primary" value="0">
									UZS
								</ToggleButton>
								<ToggleButton color="primary" value="1">
									USD
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
					</div>

					<div className="home-payment-wrapper mt-3">
						<div className="home-payment-details">
							<div className="flex flex-row justify-between">
								<div className="w-1/2 mx-20">
									<NumericFormat
										id="price-currency-field"
										name="price"
										label={
											<span>
												1 m<sup>2</sup> narxi
											</span>
										}
										value={formik.values.price}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											handleChangePriceForSquare(formattedValue)
										}}
										error={formik.touched.price && Boolean(formik.errors.price)}
										helperText={formik.touched.price && formik.errors.price}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.1, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
										inputProps={{
											readOnly: calculateByPrice
										}}
									/>

									<div className="flex gap-2">
										<div className="grow-0">
											<NumericFormat
												id="start-price-currency-field"
												name="price-discount"
												label={
													<span>
														1 m<sup>2</sup> narxi Chegimalik
													</span>
												}
												value={formik.values.pricediscount}
												error={
													formik.touched.startPricePercent &&
													Boolean(formik.errors.startPricePercent)
												}
												helperText={
													formik.touched.startPricePercent &&
													formik.errors.startPricePercent
												}
												component={motion.div}
												variants={fadeUp(30, "tween", 0.2, 0.5)}
												initial="hidden"
												animate="show"
												viewport={{ once: true, amount: 0.25 }}
												color="formColor"
												variant="outlined"
												fullWidth
												customInput={TextField}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={1}
												onChange={(event) => {
													let formattedValue =
														event.target.value &&
														parseFloat(event.target.value.split(" ").join(""))
													handleChangePriceDiscount(formattedValue)
												}}
											/>
										</div>

										<div className="grow">
											<NumericFormat
												id="start-price-currency-field"
												name="start-price"
												label={`Shartnoma Summasi Chegirmadan So'ng -${
													selectedCurrency === "1" ? " $" : " UZS"
												}`}
												value={formik.values.sumdiscount}
												onChange={(event) => {
													let formattedValue =
														event.target.value &&
														parseFloat(event.target.value.split(" ").join(""))
													handleChangeSumDiscount(formattedValue)
												}}
												error={
													formik.touched.startPrice &&
													Boolean(formik.errors.startPrice)
												}
												helperText={
													formik.touched.startPrice && formik.errors.startPrice
												}
												component={motion.div}
												variants={fadeUp(30, "tween", 0.2, 0.5)}
												initial="hidden"
												animate="show"
												viewport={{ once: true, amount: 0.25 }}
												color="formColor"
												variant="outlined"
												fullWidth
												customInput={TextField}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={1}
											/>
										</div>
									</div>

									{selectedCurrency === "1" && !isNaN(formik.values.price) && (
										<CurrencySubContent value={formik.values.price} />
									)}

									<FormControl fullWidth color="formColor" type="checkbox">
										<FormControlLabel
											control={
												<Checkbox
													id="calculate-by-price-or-sum-checkbox"
													name="calculate-by-price-or-sum-checkbox"
													checked={calculateByPrice}
													onChange={(event) => {
														setCalculateByPrice(event.target.checked)
													}}
												/>
											}
											label="Jami summa orqali hisoblash"
										/>
									</FormControl>

									<NumericFormat
										id="sum-currency-field"
										name="sum"
										label="Shartnoma summasi"
										value={formik.values.sum}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("sum", formattedValue, true)
										}}
										onBlur={() => handleBlurSum()}
										error={formik.touched.sum && Boolean(formik.errors.sum)}
										helperText={formik.touched.sum && formik.errors.sum}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.1, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
										inputProps={{
											readOnly: !calculateByPrice
										}}
									/>
									{selectedCurrency === "1" && !isNaN(formik.values.sum) && (
										<CurrencySubContent value={formik.values.sum} />
									)}

									<div className="flex gap-2">
										<div className="grow-0">
											<NumericFormat
												id="start-price-currency-field"
												name="start-price"
												label="Boshlang'ich to'lov -%"
												value={formik.values.startPricePercent}
												onChange={(event) => {
													handleChangeStartPricePercent(event.target.value)
												}}
												error={
													formik.touched.startPricePercent &&
													Boolean(formik.errors.startPricePercent)
												}
												helperText={
													formik.touched.startPricePercent &&
													formik.errors.startPricePercent
												}
												component={motion.div}
												variants={fadeUp(30, "tween", 0.2, 0.5)}
												initial="hidden"
												animate="show"
												viewport={{ once: true, amount: 0.25 }}
												color="formColor"
												variant="outlined"
												fullWidth
												customInput={TextField}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={1}
											/>
										</div>

										<div className="grow">
											<NumericFormat
												id="start-price-currency-field"
												name="start-price"
												label={`Boshlang'ich to'lov -${
													selectedCurrency === "1" ? " $" : " UZS"
												}`}
												value={formik.values.startPrice}
												onChange={(event) => {
													let formattedValue =
														event.target.value &&
														parseFloat(event.target.value.split(" ").join(""))
													handleChangeStartPrice(formattedValue)
												}}
												error={
													formik.touched.startPrice &&
													Boolean(formik.errors.startPrice)
												}
												helperText={
													formik.touched.startPrice && formik.errors.startPrice
												}
												component={motion.div}
												variants={fadeUp(30, "tween", 0.2, 0.5)}
												initial="hidden"
												animate="show"
												viewport={{ once: true, amount: 0.25 }}
												color="formColor"
												variant="outlined"
												fullWidth
												customInput={TextField}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={1}
											/>
										</div>
									</div>
									{selectedCurrency === "1" &&
										!isNaN(formik.values.startPrice) && (
											<CurrencySubContent value={formik.values.startPrice} />
										)}

									{contractType === 3 && (
										<Fragment>
											<div className="flex gap-2">
												<div className="grow-0">
													<NumericFormat
														id="last-price-currency-field"
														name="last-price"
														label="Oxirgi to'lov -%"
														value={formik.values.lastPricePercent}
														onChange={(event) => {
															handleChangeLastPricePercent(event.target.value)
														}}
														component={motion.div}
														variants={fadeUp(30, "tween", 0.2, 0.5)}
														initial="hidden"
														animate="show"
														viewport={{ once: true, amount: 0.25 }}
														color="formColor"
														variant="outlined"
														fullWidth
														customInput={TextField}
														allowNegative={false}
														thousandSeparator={" "}
														decimalScale={1}
													/>
												</div>

												<div className="grow">
													<NumericFormat
														id="last-price-currency-field"
														name="last-price"
														label={`Oxirgi to'lov -${
															selectedCurrency === "1" ? " $" : " UZS"
														}`}
														value={formik.values.lastPrice}
														onChange={(event) => {
															let formattedValue =
																event.target.value &&
																parseFloat(
																	event.target.value.split(" ").join("")
																)
															handleChangeLastPrice(formattedValue)
														}}
														component={motion.div}
														variants={fadeUp(30, "tween", 0.2, 0.5)}
														initial="hidden"
														animate="show"
														viewport={{ once: true, amount: 0.25 }}
														color="formColor"
														variant="outlined"
														fullWidth
														customInput={TextField}
														allowNegative={false}
														thousandSeparator={" "}
														decimalScale={1}
													/>
												</div>
											</div>
											{selectedCurrency === "1" &&
												!isNaN(formik.values.lastPrice) && (
													<CurrencySubContent value={formik.values.lastPrice} />
												)}
										</Fragment>
									)}

									<NumericFormat
										id="left-currency-field"
										name="left"
										label="Qoldiq summa"
										value={formik.values.left}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("left", formattedValue, true)
										}}
										error={formik.touched.left && Boolean(formik.errors.left)}
										helperText={formik.touched.left && formik.errors.left}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.3, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
										inputProps={{
											readOnly: true
										}}
									/>
									{selectedCurrency === "1" && !isNaN(formik.values.left) && (
										<CurrencySubContent value={formik.values.left} />
									)}

									<NumericFormat
										id="month"
										name="month"
										label="Kredit qaytarish muddati(oy)"
										value={formik.values.month}
										onChange={(event) => {
											formik.setFieldValue(
												"month",
												event.target.value
													? parseFloat(event.target.value)
													: "",
												true
											)
										}}
										error={formik.touched.month && Boolean(formik.errors.month)}
										helperText={formik.touched.month && formik.errors.month}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										decimalScale={0}
									/>

									<NumericFormat
										id="interval"
										name="interval"
										label="Vaqt oralig'i"
										value={formik.values.interval}
										onChange={(event) => {
											formik.setFieldValue(
												"interval",
												event.target.value
													? parseFloat(event.target.value)
													: "",
												true
											)
										}}
										error={
											formik.touched.interval && Boolean(formik.errors.interval)
										}
										helperText={
											formik.touched.interval && formik.errors.interval
										}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.5, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										decimalScale={0}
									/>
								</div>

								<div className="w-1/2 mx-20">
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											id="start-date-picker"
											openTo="day"
											value={formik.values.startDate}
											onChange={(newValue) => {
												if (
													newValue &&
													newValue._isValid &&
													newValue > moment("2000-1-1")
												) {
													formik.setFieldValue(
														"startDate",
														moment(newValue).format("YYYY-MM-DD"),
														true
													)
												} else {
													formik.setFieldValue("startDate", "", true)
												}
											}}
											views={["year", "month", "day"]}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => (
												<TextField
													{...params}
													component={motion.div}
													variants={fadeUp(30, "tween", 0.5, 0.5)}
													initial="hidden"
													animate="show"
													viewport={{ once: true, amount: 0.25 }}
													color="formColor"
													variant="outlined"
													fullWidth
													id="start"
													name="start"
													label="Shartnoma sanasi"
													error={
														formik.touched.startDate &&
														Boolean(formik.errors.startDate)
													}
													helperText={
														formik.touched.startDate && formik.errors.startDate
													}
													autoComplete="off"
												/>
											)}
										/>
									</LocalizationProvider>

									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											id="date-date-picker"
											openTo="day"
											value={formik.values.date}
											onChange={(newValue) => {
												if (
													newValue &&
													newValue._isValid &&
													newValue > moment("2000-1-1")
												) {
													formik.setFieldValue(
														"date",
														moment(newValue).format("YYYY-MM-DD"),
														true
													)
												} else {
													formik.setFieldValue("date", "", true)
												}
											}}
											views={["year", "month", "day"]}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => (
												<TextField
													{...params}
													component={motion.div}
													variants={fadeUp(30, "tween", 0.5, 0.5)}
													initial="hidden"
													animate="show"
													viewport={{ once: true, amount: 0.25 }}
													color="formColor"
													variant="outlined"
													fullWidth
													id="date"
													name="date"
													label="Birinchi to'lov sanasi"
													error={
														formik.touched.date && Boolean(formik.errors.date)
													}
													helperText={formik.touched.date && formik.errors.date}
													autoComplete="off"
												/>
											)}
										/>
									</LocalizationProvider>

									<div className="flex gap-2">
										<div className="grow-0">
											<NumericFormat
												id="discount-currency-field"
												name="discount"
												label="Chegirma miqdori - %"
												value={formik.values.discountPercent}
												onChange={(event) => {
													handleChangeDiscountPercent(event.target.value)
												}}
												error={
													formik.touched.discount &&
													Boolean(formik.errors.discount)
												}
												helperText={
													formik.touched.discount && formik.errors.discount
												}
												component={motion.div}
												variants={fadeUp(30, "tween", 0.3, 0.5)}
												initial="hidden"
												animate="show"
												viewport={{ once: true, amount: 0.25 }}
												color="formColor"
												variant="outlined"
												fullWidth
												customInput={TextField}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={1}
											/>
										</div>
										<div className="grow">
											<NumericFormat
												id="discount-currency-field"
												name="discount"
												label={`Chegirma miqdori - ${
													selectedCurrency === "1" ? " $" : " UZS"
												}`}
												value={formik.values.discount}
												onChange={(event) => {
													let formattedValue =
														event.target.value &&
														parseFloat(event.target.value.split(" ").join(""))
													handleChangeDiscount(formattedValue)
												}}
												error={
													formik.touched.discount &&
													Boolean(formik.errors.discount)
												}
												helperText={
													formik.touched.discount && formik.errors.discount
												}
												component={motion.div}
												variants={fadeUp(30, "tween", 0.3, 0.5)}
												initial="hidden"
												animate="show"
												viewport={{ once: true, amount: 0.25 }}
												color="formColor"
												variant="outlined"
												fullWidth
												customInput={TextField}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={1}
											/>
										</div>
									</div>
									{selectedCurrency === "1" &&
										!isNaN(formik.values.discount) && (
											<CurrencySubContent value={formik.values.discount} />
										)}
									<TextField
										component={motion.div}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										id="contractName"
										name="contractName"
										label="Shartnoma raqami"
										error={
											formik.touched.contractName &&
											Boolean(formik.errors.contractName)
										}
										helperText={
											formik.touched.contractName && formik.errors.contractName
										}
										value={formik.values.contractName}
										onChange={(event) => {
											formik.setFieldValue(
												"contractName",
												event.target.value,
												true
											)
										}}
										autoComplete="off"
									/>

									<TextField
										component={motion.div}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										id="comment"
										name="comment"
										label="Komentariya"
										multiline
										rows={3}
										error={
											formik.touched.comment && Boolean(formik.errors.comment)
										}
										helperText={formik.touched.comment && formik.errors.comment}
										value={formik.values.comment}
										onChange={(event) => {
											formik.setFieldValue("comment", event.target.value, true)
										}}
										autoComplete="off"
									/>
									<SimpleSelectField
										delay={0}
										label="Shartnoma turi"
										name="contractName"
										value={contractType}
										changeFn={handleContractType}
										options={contractTypes}
									/>
									<NumericFormat
										id="valute"
										name="valute"
										label="Dollar kursi"
										value={formik.values.valute}
										onChange={(event) => {
											formik.setFieldValue(
												"valute",
												event.target.value ? parseFloat(event.target.value) : 0,
												true
											)
										}}
										error={
											formik.touched.valute && Boolean(formik.errors.valute)
										}
										helperText={formik.touched.valute && formik.errors.valute}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.5, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										decimalScale={0}
									/>
								</div>
							</div>
						</div>
					</div>

					{!isNaN(formik.values.month) &&
						!isNaN(formik.values.left) &&
						formik.values.date &&
						!isNaN(formik.values.interval) && (
							<Fragment>
								<div className="step-three-payment-tables-wrapper">
									<Tabs
										value={tabValue}
										onChange={(event, newValue) => setTabValue(newValue)}
										className="contract-step-three-table-tabs"
									>
										<Tab
											label="To'lov jadvalini avtomatik shakllantirish"
											value="automatic"
										/>
										<Tab
											label="To'lov jadvalini qo'lda shakllantirish"
											value="manually"
										/>
									</Tabs>
								</div>

								{tabValue === "automatic" && (
									<div>
										<div className="flex justify-center mt-4">
											<ToggleButtonGroup
												color="primary"
												value={selectedCurrency2}
												exclusive
												onChange={(event) =>
													handleCurrencyChange2(event.target.value)
												}
												aria-label="currency2"
											>
												<ToggleButton color="primary" value="0">
													UZS
												</ToggleButton>
												<ToggleButton color="primary" value="1">
													USD
												</ToggleButton>
											</ToggleButtonGroup>
										</div>
										<StepThreeTabOne
											month={formik.values.month}
											left={formik.values.left}
											date={formik.values.date}
											interval={formik.values.interval}
											paymentDataList={paymentDataList}
											setPaymentDataList={setPaymentDataList}
											currency={selectedCurrency}
											currency2={selectedCurrency2}
										/>
									</div>
								)}

								{tabValue === "manually" && (
									<StepThreeTabTwo
										month={formik.values.month}
										left={formik.values.left}
										date={formik.values.date}
										interval={formik.values.interval}
										paymentDataList={paymentDataList2}
										setPaymentDataList={setPaymentDataList2}
										currency={selectedCurrency}
									/>
								)}
							</Fragment>
						)}

					<div className="text-center my-4">
						<Button
							onClick={back}
							color="inherit"
							variant="contained"
							className="!mr-2"
						>
							Ortga
						</Button>
						<Button
							color="success"
							variant="contained"
							className="!ml-2"
							onClick={handleNext}
						>
							Oldinga
						</Button>
					</div>
				</div>
			</div>
		</motion.div>
	)
}

export default StepThree
