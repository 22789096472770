import { Button } from "@mui/material"
import DashboardFilterDrawer from "components/dashboard/DashboardFilterDrawer"
import DashboardObjectsSlider from "components/dashboard/DashboardObjectsSlider"
import DashboardTable from "components/ui/tables/DashboardTable"
import useTopPanel from "hooks/useTopPanel"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const Dashboard = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()
	const [refetch, setRefetch] = useState(false)
	const [filterData, setFilterData] = useState({})
	const [open, setOpen] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const [isFilterLoading, setIsFilterLoading] = useState(false)
	const [objectsError, setObjectsError] = useState(false)

	useEffect(() => {
		setComponent(<div className="component-title">{t("dashboard.title")}</div>)
	}, [i18n.language])

	return (
		<div className="component-list-wrapper">
			<DashboardObjectsSlider
				setObjectsError={setObjectsError}
				setFilterData={setFilterData}
				setIsFilterLoading={setIsFilterLoading}
			/>
			<div className="component-list-header mb-2">
				<div className="header-actions-container pt-2 pb-2 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full">
						<Button
							variant="filterOutlined"
							color="primary"
							startIcon={<i className="bi bi-filter" />}
							className="!mr-2 !my-2"
							onClick={() =>
								!(objectsError || isFilterLoading) &&
								setExpanded((prev) => !prev)
							}
							disabled={objectsError || isFilterLoading}
						>
							{t("common.button.filter")}
						</Button>
					</div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								!objectsError && setRefetch(true)
							}}
							disable={`${refetch}`}
							disabled={objectsError}
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="default"
							className="!ml-2"
							onClick={() => !objectsError && setOpen(true)}
							disabled={objectsError}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
			</div>
			<div className="component-table-wrapper flex-auto">
				{!objectsError ? (
					<DashboardTable
						emitRefetch={{ refetch, setRefetch }}
						emitTableColumns={{ open, setOpen }}
						dataPath="admin/home/objecthomes"
						setIsFilterLoading={setIsFilterLoading}
					/>
				) : (
					<div className="my-6">
						<span className="no-data-found-wrapper">
							<i className="bi bi-exclamation-octagon text-xl mr-1 leading-3" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				)}
			</div>
			{!objectsError && (
				<DashboardFilterDrawer
					open={expanded}
					setOpen={setExpanded}
					filterData={filterData}
					isLoading={isFilterLoading}
				/>
			)}
		</div>
	)
}
export default Dashboard
