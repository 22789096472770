import { useEffect, useState } from "react"
import FilterAccordion from "../FilterAccordion"
import { Button } from "@mui/material"
import { useSearchParams } from "react-router-dom"

const HomeStageFilter = ({ filterStages = 1, disabled = false }) => {
	const [stages, setStages] = useState(
		Array.from({ length: filterStages }, (value, index) => index + 1)
	)
	const [searchParams, setSearchParams] = useSearchParams()
	const [selectedStages, setSelectedStages] = useState([])

	const handleStageChange = (value) => {
		let entries = Object.fromEntries(searchParams)
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("stage")) {
				searchParams.delete(item)
			}
		})
		if (isSelected(value)) {
			selectedStages.splice(selectedStages.indexOf(value), 1)
		} else {
			selectedStages.push(value)
		}
		selectedStages.forEach((item, index) => {
			searchParams.set(`stage[${index}]`, item)
		})
		setSearchParams(searchParams)
		setSelectedStages(selectedStages)
	}

	const isSelected = (stage) => {
		return selectedStages.includes(stage)
	}

	const setDefaultStages = () => {
		let entries = Object.fromEntries(searchParams)
		let newStages = []
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("stage")) {
				newStages.push(parseInt(searchParams.get(item)))
			}
		})
		setSelectedStages(newStages)
	}

	useEffect(() => {
		setDefaultStages()
	}, [searchParams])

	return (
		<FilterAccordion title="Qavati" code="home-stage">
			{stages && stages.length > 0 && (
				<div className="home-stages-wrapper">
					{stages.map((item) => (
						<Button
							className={`stage-item${
								isSelected(item) ? " item-selected" : ""
							}`}
							color="primary"
							variant={isSelected(item) ? "contained" : "outlined"}
							key={`home-filter-stage-${item}`}
							onClick={() => handleStageChange(item)}
							disabled={disabled}
						>
							{item}
						</Button>
					))}
				</div>
			)}
		</FilterAccordion>
	)
}
export default HomeStageFilter
