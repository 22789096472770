import { ButtonBase, CircularProgress } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import BaseTooltip from "../ui/tooltips/BaseTooltip"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Pagination } from "swiper/modules"
import "swiper/css/free-mode"
import "swiper/css/pagination"
import SuccessTooltip from "../ui/tooltips/SuccessTooltip"
import WarningTooltip from "../ui/tooltips/WarningTooltip"

const DashboardObjectsSlider = ({
	setObjectsError = () => {},
	setFilterData = () => {},
	setIsFilterLoading = () => {}
}) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [searchParams, setSearchParams] = useSearchParams()
	const [selectedObjectsId, setSelectedObjectsId] = useState([])
	const [hasError, setHasError] = useState(false)
	const { data, isLoading, isFetching } = useQuery({
		queryKey: "objects",
		queryFn: async function () {
			const response = await axiosPrivate.get("/admin/object/objects")
			return response.data.data
		},
		enabled: !hasError,
		onSuccess: (data) => {
			setFilterData(data)
		},
		onError: (error) => {
			setHasError(true)
			setObjectsError(true)
		},
		retry: false
	})

	const handleObjectId = (objectId) => {
		let entries = Object.fromEntries(searchParams)
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("object")) {
				searchParams.delete(item)
			}
		})
		if (isSelected(objectId)) {
			selectedObjectsId.splice(selectedObjectsId.indexOf(objectId), 1)
		} else {
			selectedObjectsId.push(objectId)
		}
		selectedObjectsId.forEach((item, index) => {
			searchParams.set(`object[${index}]`, item)
		})
		setSearchParams(searchParams)
		setSelectedObjectsId(selectedObjectsId)
	}

	const isSelected = (objectId) => {
		return selectedObjectsId.includes(objectId)
	}

	const setDefaultObjects = () => {
		let entries = Object.fromEntries(searchParams)
		let newObjectsId = []
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("object")) {
				newObjectsId.push(parseInt(searchParams.get(item)))
			}
		})
		setSelectedObjectsId(newObjectsId)
	}

	useEffect(() => {
		setDefaultObjects()
	}, [searchParams])

	useEffect(() => {
		setIsFilterLoading(isLoading || isFetching)
	}, [isLoading, isFetching])

	return (
		<div className="dashboard-objects-slider-wrapper">
			{isLoading || isFetching ? (
				<div className="circular-progress-box h-[100px]">
					<CircularProgress size={25} />
				</div>
			) : data && data?.objects && data?.objects.length > 0 ? (
				<Swiper
					slidesPerView={1}
					spaceBetween={30}
					freeMode={true}
					// pagination={{
					// 	clickable: true
					// }}
					pagination={false}
					breakpoints={{
						640: {
							slidesPerView: 3,
							spaceBetween: 20
						},
						768: {
							slidesPerView: 4,
							spaceBetween: 25
						},
						1024: {
							slidesPerView: 6,
							spaceBetween: 20
						}
					}}
					modules={[FreeMode, Pagination]}
				>
					{data?.objects.map((objectItem) => (
						<SwiperSlide key={objectItem?.id}>
							<ButtonBase
								id={`object-${objectItem?.id}`}
								className={`object-item${
									isSelected(objectItem?.id) ? " item-selected" : ""
								}`}
								onClick={() => handleObjectId(objectItem?.id)}
							>
								<div className="object-item-body">
									<div className="object-title">
										<BaseTooltip
											title={objectItem?.name}
											arrow={true}
											placement="top"
											enterDelay={500}
										>
											<span>{objectItem?.name}</span>
										</BaseTooltip>
									</div>
									<div className="object-home-type-indicator">
										<SuccessTooltip
											arrow={true}
											placement="top"
											title={`Sotilgan xonadonlar soni - ${objectItem.sale} ta`}
											offset={[0, -10]}
										>
											<span
												className="home-type-indicator sold"
												style={{
													"--indicator-width": `${
														(objectItem.sale * 100) / objectItem.total
													}%`
												}}
											></span>
										</SuccessTooltip>
										<WarningTooltip
											arrow={true}
											placement="top"
											title={`Buyurtmalar soni - ${objectItem.order} ta`}
											offset={[0, -10]}
										>
											<span
												className="home-type-indicator order"
												style={{
													"--indicator-width": `${
														(objectItem.order * 100) / objectItem.total
													}%`
												}}
											></span>
										</WarningTooltip>
									</div>
									<div className="object-home-type-number">
										Xonadonlar soni: {objectItem.total}
									</div>
									<div className="object-home-type-number">
										Bo'sh xonadonlar soni: {objectItem.active}
									</div>
									<div className="object-home-type-number">
										Buyurtmalar: {objectItem.order}
									</div>
								</div>
							</ButtonBase>
						</SwiperSlide>
					))}
				</Swiper>
			) : (
				<div className="my-6">
					<span className="no-data-found-wrapper">
						<i className="bi bi-exclamation-octagon text-xl mr-1 leading-3" />{" "}
						{t("common.global.noBlocksFound")}
					</span>
				</div>
			)}
		</div>
	)
}

export default DashboardObjectsSlider
