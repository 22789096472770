import { ButtonBase, CircularProgress, Grid } from "@mui/material"
import ObjectSelectBox from "components/boss/ObjectSelectBox"
import DoughnutChart from "components/ui/charts/DoughnutChart"
import SimpleDateField from "components/ui/simple-fields/date-picker/SimpleDateField"
import SimpleSelectField from "components/ui/simple-fields/select/SimpleSelectField"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import moment from "moment"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useQueries } from "react-query"
import PaymentTermModal from "../PaymentTermModal"

const TabTwo = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [currencyType, setCurrencyType] = useState("SUM")
	const [expectedPaymentsDate, setExpectedPaymentsDate] = useState(
		moment().format("YYYY-MM-DD")
	)
	const [selectedObjectId, setSelectedObjectId] = useState("ALL")
	const [openPaymentTermModal, setOpenPaymentTermModal] = useState(false)
	const [currPaymentTermData, setCurrPaymentTermData] = useState({})

	const [paymentTerm, expectedPayments] = useQueries([
		{
			queryKey: "paymentTerm",
			queryFn: async function () {
				const response = await axiosPrivate.get("/analytic/payment/lifetime")
				return response.data.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
		{
			queryKey: [
				"expectedPayments",
				currencyType,
				selectedObjectId,
				expectedPaymentsDate
			],
			queryFn: async function () {
				const response = await axiosPrivate.get(
					`/analytic/payment/income?currency=${currencyType}&date=${expectedPaymentsDate}${
						selectedObjectId !== "ALL" ? `&objects[0]=${selectedObjectId}` : ""
					}`
				)
				return response.data.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
	])

	const calculateLeftPartIndicator = (total, part) => {
		let a = ((total - part) * 100) / total
		return a > 1 ? a : 1
	}

	const calculateRightPartIndicator = (total, part) => {
		let a = (part * 100) / total
		return a > 1 ? a : 1
	}

	return (
		<div className="boss-payment-tab-wrapper tab-two">
			<Grid container spacing={2} columns={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
				<Grid item={true} lg={6} md={8} sm={16} xs={16}>
					<div className="payment-term item-wrapper">
						<div className="item-header">
							<div className="item-title">To'lov muddati</div>
						</div>
						<div className="item-body">
							<div className="chart-wrapper">
								{paymentTerm.isLoading || paymentTerm.isFetching ? (
									<div className="flex w-full items-center justify-center">
										<CircularProgress size={30} color="inherit" />
									</div>
								) : paymentTerm.data &&
								  Object.keys(paymentTerm.data).length > 0 ? (
									<DoughnutChart
										chartLabels={[
											"O'z vaqtida to'layotganlar",
											"Bir oygacha to'layotganlar",
											"Uch oygacha kechikib to'layotganlar",
											"Bir yilgacha kechikib to'layotganlar",
											"Bir yildan oshib kechikib to'layotganlar",
											"Umuman to'lamaganlar"
										]}
										chartDatasets={[
											{
												data: [
													paymentTerm.data?.intime.length,
													paymentTerm.data?.inmonth.length,
													paymentTerm.data?.inthreemonth.length,
													paymentTerm.data?.inyear.length,
													paymentTerm.data?.moreyear.length,
													paymentTerm.data?.never.length
												],
												backgroundColor: [
													"#0a6a0ab0",
													"#f0dd0db0",
													"#f0990db0",
													"#990399b0",
													"#400269b0",
													"#bf1307a6"
												],
												borderWidth: 4,
												hoverBorderWidth: 0,
												hoverOffset: 15,
												borderRadius: 4
											}
										]}
										options={{
											layout: {
												padding: {
													top: 10,
													bottom: 10
												}
											}
										}}
										optionPlugins={{
											legend: {
												display: false
											},
											tooltip: {
												usePointStyle: true,
												callbacks: {
													label: function (tooltipItem) {
														return ` ${tooltipItem.raw} ta`
													},
													labelPointStyle: function () {
														return {
															pointStyle: "rectRounded",
															rotation: 0
														}
													}
												}
											}
										}}
										plugins={[
											{
												id: "doughnutLabel",
												beforeDatasetsDraw: function (chart) {
													const { ctx, data, height } = chart
													ctx.restore()
													const centerX = chart.getDatasetMeta(0).data[0].x
													const centerY = chart.getDatasetMeta(0).data[0].y
													let count =
														data.datasets[0]?.data &&
														data.datasets[0]?.data.length > 0
															? data.datasets[0].data.reduce(
																	(acc, curr) => acc + curr,
																	0
															  )
															: 0
													ctx.font = `bold ${(height / 110).toFixed(
														2
													)}rem 'Inter', sans-serif`
													ctx.fillStyle = "black"
													ctx.textAlign = "center"
													ctx.textBaseline = "middle"
													ctx.fillText(count, centerX, centerY)
													ctx.save()
												}
											}
										]}
									/>
								) : (
									<div>
										<span className="no-data-found-wrapper">
											<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
											{t("common.global.noDataFound")}
										</span>
									</div>
								)}
							</div>
							<div className="chart-data-items">
								<ButtonBase
									className="chart-data-item-wrapper item-odd"
									onClick={() => {
										setCurrPaymentTermData({
											label: "O'z vaqtida to'layotganlar",
											items: paymentTerm.data?.intime
										})
										setOpenPaymentTermModal(true)
									}}
								>
									<div className="chart-data-item-body">
										<div className="item-title">
											<div className="item-indicator indicator-intime"></div>
											O'z vaqtida to'layotganlar
										</div>
										<div className="item-value">
											{paymentTerm.data?.intime.length}
										</div>
									</div>
								</ButtonBase>
								<ButtonBase
									className="chart-data-item-wrapper item-even"
									onClick={() => {
										setCurrPaymentTermData({
											label: "Bir oygacha to'layotganlar",
											items: paymentTerm.data?.inmonth
										})
										setOpenPaymentTermModal(true)
									}}
								>
									<div className="chart-data-item-body">
										<div className="item-title">
											<div className="item-indicator indicator-inmonth"></div>
											Bir oygacha to'layotganlar
										</div>
										<div className="item-value">
											{paymentTerm.data?.inmonth.length}
										</div>
									</div>
								</ButtonBase>
								<ButtonBase
									className="chart-data-item-wrapper item-odd"
									onClick={() => {
										setCurrPaymentTermData({
											label: "Uch oygacha kechikib to'layotganlar",
											items: paymentTerm.data?.inthreemonth
										})
										setOpenPaymentTermModal(true)
									}}
								>
									<div className="chart-data-item-body">
										<div className="item-title">
											<div className="item-indicator indicator-inthreemonth"></div>
											Uch oygacha kechikib to'layotganlar
										</div>
										<div className="item-value">
											{paymentTerm.data?.inthreemonth.length}
										</div>
									</div>
								</ButtonBase>
								<ButtonBase
									className="chart-data-item-wrapper item-even"
									onClick={() => {
										setCurrPaymentTermData({
											label: "Bir yilgacha kechikib to'layotganlar",
											items: paymentTerm.data?.inyear
										})
										setOpenPaymentTermModal(true)
									}}
								>
									<div className="chart-data-item-body">
										<div className="item-title">
											<div className="item-indicator indicator-inyear"></div>
											Bir yilgacha kechikib to'layotganlar
										</div>
										<div className="item-value">
											{paymentTerm.data?.inyear.length}
										</div>
									</div>
								</ButtonBase>
								<ButtonBase
									className="chart-data-item-wrapper item-odd"
									onClick={() => {
										setCurrPaymentTermData({
											label: "Bir yildan oshib kechikib to'layotganlar",
											items: paymentTerm.data?.moreyear
										})
										setOpenPaymentTermModal(true)
									}}
								>
									<div className="chart-data-item-body">
										<div className="item-title">
											<div className="item-indicator indicator-moreyear"></div>
											Bir yildan oshib kechikib to'layotganlar
										</div>
										<div className="item-value">
											{paymentTerm.data?.moreyear.length}
										</div>
									</div>
								</ButtonBase>
								<ButtonBase
									className="chart-data-item-wrapper item-even"
									onClick={() => {
										setCurrPaymentTermData({
											label: "Umuman to'lamaganlar",
											items: paymentTerm.data?.never
										})
										setOpenPaymentTermModal(true)
									}}
								>
									<div className="chart-data-item-body">
										<div className="item-title">
											<div className="item-indicator indicator-never"></div>
											Umuman to'lamaganlar
										</div>
										<div className="item-value">
											{paymentTerm.data?.never.length}
										</div>
									</div>
								</ButtonBase>
							</div>
						</div>
					</div>
				</Grid>
				<Grid item={true} lg={10} md={8} sm={16} xs={16}>
					<div className="expected-payment item-wrapper">
						<div className="item-header">
							<div className="item-title">Kutilayotgan to'lovlar</div>
							<div className="item-actions">
								<div className="max-w-[100px]">
									<SimpleSelectField
										delay={0}
										duration={0}
										options={[
											{
												code: "SUM",
												label: "UZS"
											},
											{
												code: "VALUTE",
												label: "USD"
											}
										]}
										label=""
										name="currencySelectBox"
										size="small"
										value={currencyType}
										changeFn={(value) => setCurrencyType(value)}
									/>
								</div>
								<div className="max-w-[140px] mx-3">
									<SimpleDateField
										delay={0}
										duration={0}
										label=""
										name="date"
										size="small"
										openTo="month"
										views={["year", "month"]}
										inputFormat="MMMM, yyyy"
										value={expectedPaymentsDate}
										setValue={setExpectedPaymentsDate}
									/>
								</div>
								<div className="min-w-[120px]">
									<ObjectSelectBox
										value={selectedObjectId}
										setValue={setSelectedObjectId}
										size="small"
									/>
								</div>
							</div>
						</div>
						<div className="item-body">
							{expectedPayments.isLoading || expectedPayments.isFetching ? (
								<div className="flex mt-4 h-[320px] w-full items-center justify-center">
									<CircularProgress size={30} color="inherit" />
								</div>
							) : expectedPayments?.data &&
							  Object.keys(expectedPayments?.data).length > 0 ? (
								<div className="expected-payment-items">
									<div className="expected-payment-item-wrapper">
										<div className="item-title">
											Oylik to'lov garfigi bo'yicha kutilayotgan to'lovlar
										</div>
										<div className="item-details">
											<div className="total-value">
												<NumericFormat
													value={expectedPayments?.data?.totaldebt}
													displayType={"text"}
													allowNegative={false}
													thousandSeparator={" "}
													decimalScale={2}
													className="bg-transparent"
													suffix={currencyType === "SUM" ? " UZS" : " $"}
												/>
											</div>
											<div className="divided-values">
												<div
													className="payments divided-item"
													style={{
														"--indicator-width": `${(expectedPayments?.data
															?.totaldebt > 0
															? calculateLeftPartIndicator(
																	expectedPayments?.data?.totaldebt,
																	expectedPayments?.data?.debtsum
															  )
															: 50
														).toFixed(2)}%`
													}}
												>
													<div className="divided-item-indicator"></div>
													<div className="divided-item-value">
														<NumericFormat
															value={
																expectedPayments?.data?.totaldebt -
																expectedPayments?.data?.debtsum
															}
															displayType={"text"}
															allowNegative={false}
															thousandSeparator={" "}
															decimalScale={2}
															className="bg-transparent"
															suffix={currencyType === "SUM" ? " UZS" : " $"}
														/>
													</div>
												</div>
												<div
													className="expected-payments divided-item"
													style={{
														"--indicator-width": `${(expectedPayments?.data
															?.totaldebt > 0
															? calculateRightPartIndicator(
																	expectedPayments?.data?.totaldebt,
																	expectedPayments?.data?.debtsum
															  )
															: 50
														).toFixed(2)}%`
													}}
												>
													<div className="divided-item-indicator"></div>
													<div className="divided-item-value">
														<NumericFormat
															value={expectedPayments?.data?.debtsum}
															displayType={"text"}
															allowNegative={false}
															thousandSeparator={" "}
															decimalScale={2}
															className="bg-transparent"
															suffix={currencyType === "SUM" ? " UZS" : " $"}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="expected-payment-item-wrapper">
										<div className="item-title">
											Oylik to'lov garfigi bo'yicha muddati o'tgan to'lovlar
										</div>
										<div className="item-details">
											<div className="total-value">
												<NumericFormat
													value={expectedPayments?.data?.olddebt}
													displayType={"text"}
													allowNegative={false}
													thousandSeparator={" "}
													decimalScale={2}
													className="bg-transparent"
													suffix={currencyType === "SUM" ? " UZS" : " $"}
												/>
											</div>
											<div className="divided-values">
												<div
													className="payments divided-item"
													style={{
														"--indicator-width": `${(expectedPayments?.data
															?.olddebt > 0
															? calculateLeftPartIndicator(
																	expectedPayments?.data?.olddebt,
																	expectedPayments?.data?.oldsum
															  )
															: 50
														).toFixed(2)}%`
													}}
												>
													<div className="divided-item-indicator"></div>
													<div className="divided-item-value">
														<NumericFormat
															value={
																expectedPayments?.data?.olddebt -
																expectedPayments?.data?.oldsum
															}
															displayType={"text"}
															allowNegative={false}
															thousandSeparator={" "}
															decimalScale={2}
															className="bg-transparent"
															suffix={currencyType === "SUM" ? " UZS" : " $"}
														/>
													</div>
												</div>
												<div
													className="expected-payments divided-item"
													style={{
														"--indicator-width": `${(expectedPayments?.data
															?.olddebt > 0
															? calculateRightPartIndicator(
																	expectedPayments?.data?.olddebt,
																	expectedPayments?.data?.oldsum
															  )
															: 50
														).toFixed(2)}%`
													}}
												>
													<div className="divided-item-indicator"></div>
													<div className="divided-item-value">
														<NumericFormat
															value={expectedPayments?.data?.oldsum}
															displayType={"text"}
															allowNegative={false}
															thousandSeparator={" "}
															decimalScale={2}
															className="bg-transparent"
															suffix={currencyType === "SUM" ? " UZS" : " $"}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="status-indicators">
										<div className="status-item status-payments">
											<div className="indicator-item"></div>To'lovlar
										</div>
										<div className="status-item status-expected-payments">
											<div className="indicator-item"></div>Kutilayotgan
											to'lovlar
										</div>
									</div>
								</div>
							) : (
								<div>
									<span className="no-data-found-wrapper">
										<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
										{t("common.global.noDataFound")}
									</span>
								</div>
							)}
						</div>
					</div>
				</Grid>
			</Grid>

			{openPaymentTermModal && (
				<PaymentTermModal
					open={openPaymentTermModal}
					setOpen={setOpenPaymentTermModal}
					data={currPaymentTermData}
				/>
			)}
		</div>
	)
}
export default TabTwo
