import React, { memo, useRef, useState } from 'react';
import SuccessTooltip from 'components/ui/tooltips/SuccessTooltip';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ContractDownloaderByLang = ({ contractQuery }) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const menuToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <SuccessTooltip
        arrow={true}
        placement='top'
        title={t('contract.view.action.download')}
      >
        <Button
          id='contract-downloader-by-language'
          ref={ref}
          variant='action'
          color='success'
          className='!mx-4'
          onClick={menuToggle}
        >
          <i className='bi bi-download' />
        </Button>
        {/* </Link> */}
      </SuccessTooltip>

      <Menu
        open={open}
        anchorEl={ref?.current}
        // anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={menuToggle}
        className='language-menu -translate-x-2.5 -translate-y-2'
      >
        <Link
          target='_blank'
          to={`${process.env.REACT_APP_BACKEND_URL}/doc/${contractQuery?.data?.id}?lang=uz`}
          className='no-underline'
        >
          <MenuItem
            onClick={menuToggle}
            lang='uz'
            className={`language-menu-item`}
          >
            {t('common.language.short.uz')}
          </MenuItem>
        </Link>
        <Link
          target='_blank'
          to={`${process.env.REACT_APP_BACKEND_URL}/doc/${contractQuery?.data?.id}?lang=uz_kr`}
          className='no-underline'
        >
          <MenuItem
            onClick={menuToggle}
            lang='uz_kr'
            className={`language-menu-item`}
          >
            {t('common.language.short.uz_kr')}
          </MenuItem>
        </Link>
        <Link
          target='_blank'
          to={`${process.env.REACT_APP_BACKEND_URL}/doc/${contractQuery?.data?.id}?lang=ru`}
          className='no-underline'
        >
          <MenuItem
            onClick={menuToggle}
            lang='ru'
            className={`language-menu-item`}
          >
            {t('common.language.short.ru')}
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export default memo(ContractDownloaderByLang);
