import { FormControl, FormControlLabel, Switch } from "@mui/material"
import { motion } from "framer-motion"
import React from "react"
import { fadeUp } from "utils/motion"

const SimpleSwitchField = ({
	delay = 0,
	duration = 0,
	label,
	setValue,
	value,
	options = [],
	disabled = false
}) => {
	return (
		<FormControl
			component={motion.div}
			variants={fadeUp(30, "tween", delay, duration)}
			initial="hidden"
			animate="show"
			viewport={{ once: true, amount: 0.25 }}
			color="formColor"
		>
			<FormControlLabel
				control={
					<Switch
						id={label.toLowerCase()}
						name={label.toLowerCase()}
						checked={
							value
								? options.find((item) => item.value === value).checked
								: false
						}
						disabled={disabled}
						onChange={(event) => {
							setValue(
								options.find((item) => item.checked === event.target.checked)
									.value
							)
						}}
					/>
				}
				label={label}
			/>
		</FormControl>
	)
}

export default SimpleSwitchField
