const getShaxmatkaFieldDataByBlocks = (blocks, fieldName) => {
	if (blocks && blocks.length > 0) {
		return Array.from(
			new Set(
				blocks.reduce((acc, block) => {
					let uniqFieldDataByBlock = Array.from(
						new Set(
							block?.homes
								.map((item) =>
									item[fieldName] ? parseFloat(item[fieldName]) : null
								)
								.filter(
									(item) => item !== 0 && item !== null && item !== undefined
								)
						)
					)
					return [...acc, ...uniqFieldDataByBlock]
				}, [])
			)
		).sort((a, b) => a - b)
	}
	return []
}

export default getShaxmatkaFieldDataByBlocks
