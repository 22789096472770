const {
	Accordion,
	AccordionSummary,
	AccordionDetails
} = require("@mui/material")

const FilterAccordion = ({ title = "", code = "", children }) => {
	return (
		<Accordion
			sx={{
				boxShadow: "none"
			}}
			className="dashboard-filter-accordion"
		>
			<AccordionSummary
				aria-controls={`${code}-content`}
				id={`${code}-header`}
				data-text={title}
				expandIcon={<i className="bi bi-chevron-right expand-icon" />}
				className="dashboard-filter-accordion-summary"
			>
				<i className="bi bi-funnel filter-icon" />
				<span className="accordion-title">{title}</span>
			</AccordionSummary>
			<AccordionDetails className="dashboard-filter-accordion-details">
				{children}
			</AccordionDetails>
		</Accordion>
	)
}
export default FilterAccordion
