import React from 'react';
import BaseTooltip from './tooltips/BaseTooltip';
import { Button } from '@mui/material';
import usePrevNext from 'hooks/usePrevNext';
import { useTranslation } from 'react-i18next';

const BackButton = ({ defaultPath = '' }) => {
  const { prev } = usePrevNext();
  const { t } = useTranslation();
  return (
    <BaseTooltip
      title={t('common.button.back')}
      arrow
      placement='bottom'
      enterDelay={1500}
    >
      <Button
        variant='action'
        color='primary'
        onClick={() => {
          if (defaultPath) {
            prev(defaultPath);
          } else {
            prev();
          }
        }}
        className='!mr-2'
      >
        <i className='bi bi-arrow-left flex items-center justify-center' />
      </Button>
    </BaseTooltip>
  );
};

export default BackButton;
