import { Box, Drawer, IconButton, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import AllCalls from './tabs/AllCalls';
import IncomeCalls from './tabs/IncomeCalls';
import OutcomeCalls from './tabs/OutcomeCalls';
import NotAnsweredCalls from './tabs/NotAnsweredCalls';

const CallsHistoryDrawer = ({ open, setOpen }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Drawer anchor='right' open={open} onClose={() => setOpen(false)}>
      <div className='close-btn-wrapper'>
        <IconButton
          variant='onlyIcon'
          color='primary'
          onClick={() => setOpen(false)}
        >
          <i className='bi bi-x' />
        </IconButton>
      </div>
      <div className='calls-history-drawer-wrapper'>
        <div className='drawer-title'>Qo'ng'iroqlar tarixi</div>
        <div className='drawer-body'>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={handleChangeTab}
              type='callsHistory'
            >
              <Tab label='Barchasi' value={0} />
              <Tab label='Kiruvchi' value={1} />
              <Tab label='Chiquvchi' value={2} />
              <Tab label='Qabul qilinmagan' value={3} />
            </Tabs>
          </Box>

          <div className='calls-history-tabs-wrapper'>
            {tabIndex == 0 && <AllCalls />}
            {tabIndex == 1 && <IncomeCalls />}
            {tabIndex == 2 && <OutcomeCalls />}
            {tabIndex == 3 && <NotAnsweredCalls />}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default CallsHistoryDrawer;
