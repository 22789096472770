import React, { memo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
// import { useQuery } from 'react-query';
import * as yup from 'yup';
import {
  //   CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTextField from 'components/ui/form/FormTextField';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormFileUploadField from 'components/ui/form/FormFileUploadField';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';

// import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useFormSubmit from 'hooks/useFormSubmit';

const validationSchema = yup.object({
  name: yup.string().required('settings.contracts.validation.name'),
  lang: yup.string().required('settings.contracts.validation.lang'),
  from: yup.number().required('settings.contracts.validation.from'),
  till: yup.number().required('settings.contracts.validation.till'),
  object_id: yup.number().required('settings.contracts.validation.objectId'),
  file: yup.mixed().required('settings.contracts.validation.file'),
});

const TargetAddEditModal = (props) => {
  const { open, setOpen, setRefetch, itemId, setItemId } = props;
  const { t } = useTranslation();
  //   const axiosPrivate = useAxiosPrivate();
  //   const [hasError, setHasError] = useState(false);
  const { submit, isSubmitting } = useFormSubmit();

  const languages = [
    {
      code: 'uz',
      label: t('common.language.short.uz'),
    },
    {
      code: 'uz_kr',
      label: t('common.language.short.uz_kr'),
    },
    {
      code: 'ru',
      label: t('common.language.short.ru'),
    },
  ];
  const formik = useFormik({
    initialValues: {
      name: '',
      file: null,
      from: '',
      till: '',
      lang: '',
      object_id: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //   if (itemId) {
      submit(
        { type: 'post', contentType: 'formData' },
        values,
        '/admin/template',
        values.name,
        itemId,
        false,
        handleFinishRequest
      );
      //   } else
      //     submit(
      //       { type: 'post', contentType: 'simple' },
      //       values,
      //       '/crm/target',
      //       values.name,
      //       null,
      //       false,
      //       handleFinishRequest
      //     );
    },
  });

  //   const { isLoading, isFetching } = useQuery({
  //     queryKey: 'staffSingle',
  //     queryFn: async function () {
  //       const response = await axiosPrivate.get(`/crm/target/index/${itemId}`);
  //       return response.data.data;
  //     },
  //     onSuccess: (data) => {
  //       formik.setValues({
  //         name: data.name,
  //         login: data.login,
  //         password: '',
  //         status: data.status,
  //       });
  //     },
  //     enabled: !hasError && !!itemId,
  //     onError: (error) => {
  //       setHasError(true);
  //     },
  //     retry: false,
  //   });

  const handleFinishRequest = () => {
    setRefetch(true);
    handleClose();
  };

  const handleClose = () => {
    setItemId('');
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {itemId && formik.values.name ? (
          <span>
            {t('settings.contracts.modal.editTitle', {
              value: formik.values.name,
            })}
          </span>
        ) : (
          <span>{t('settings.contracts.modal.addTitle')}</span>
        )}
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {/* {isLoading || isFetching ? (
          <div className='circular-progress-box py-5'>
            <CircularProgress size={35} />
          </div>
        ) : ( */}
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, lg: 3 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.1}
                label={t('common.table.name')}
                fieldName='name'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormSelectField
                delay={0}
                duration={0}
                fieldName='object_id'
                label={t('common.fields.objectName')}
                formik={formik}
                path={'/admin/object/index'}
                isItemSizeSmall={true}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormSimpleSelectField
                delay={0}
                fieldName='lang'
                label={t('common.language.title')}
                formik={formik}
                options={languages}
                itemValue='code'
                itemLabel='label'
                isItemSizeSmall={true}
              />
            </Grid>

            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                label={t('common.table.from')}
                fieldName='from'
                type='number'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                label={t('common.table.till')}
                fieldName='till'
                type='number'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormFileUploadField
                delay={0.2}
                accept='.doc, .docx'
                fieldName='file'
                formik={formik}
                label={t('common.xlsxUploader.clickToUpload')}
                btnLabel={t('xlsxUploader.clickToUpload')}
                fileSize={1}
              />
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <FormActionButtons
                delay={0.6}
                isSubmitting={isSubmitting}
                formType='dialog'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </Grid>
          </Grid>
        </form>
        {/* )} */}
      </DialogContent>
    </Dialog>
  );
};

export default memo(TargetAddEditModal);
