import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormCheckboxField from "components/ui/form/FormCheckboxField"
import FormTextField from "components/ui/form/FormTextField"
import { useFormik } from "formik"
import useFormSubmit from "hooks/useFormSubmit"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

const validationSchema = yup.object({
	name: yup.string().required("crm.block.addModal.validation.name"),
	isfinished: yup.string().optional()
})

const CRMBlockAddModal = (props) => {
	const { open, setOpen, refetchFn, lastOrder = 0 } = props
	const { t } = useTranslation()
	const { submit, isSubmitting } = useFormSubmit()

	const formik = useFormik({
		initialValues: {
			name: "",
			isfinished: "0"
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			submit(
				{ type: "post", contentType: "simple" },
				{ ...values, order: lastOrder + 1 },
				"/crm/block",
				{
					title: t("crm.block.addModal.alerts.success")
				},
				null,
				true,
				handleFinish
			)
		}
	})

	const handleFinish = () => {
		refetchFn()
		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span className="pr-5">{t("crm.block.addModal.title")}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="pt-2">
					<Grid
						container
						spacing={{ xs: 2, sm: 2, md: 2 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12, md: 12 }}
					>
						<Grid item={true} md={6} sm={12} xs={12}>
							<FormTextField
								delay={0.1}
								label={t("common.fields.simpleName")}
								fieldName="name"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} md={6} sm={12} xs={12}>
							<FormCheckboxField
								delay={0.2}
								label={t("common.fields.beFinished")}
								fieldName="isfinished"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								delay={0.3}
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default CRMBlockAddModal
