function colorGenerator() {
	// return "#" + Math.random().toString(16).substr(-6)
	let letters = "0123456789ABCDEF".split(""),
		color = "#"
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)]
	}
	return color
}

export default colorGenerator
