import { TextField } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"

const EditableTextField = ({
	initialText = "",
	changeFn = () => {},
	className = ""
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [prevText, setPrevText] = useState(initialText)
	const [text, setText] = useState(initialText)
	const inputRef = useRef(null)

	const handleClick = () => {
		setIsEditing(true)
	}

	const handleChange = (event) => {
		if (event.target.value) {
			setText(event.target.value)
		}
	}

	const handleBlur = (event) => {
		setIsEditing(false)
		if (prevText !== text) {
			changeFn(text)
		}
		setPrevText(event.target.value)
	}

	useEffect(() => {
		if (isEditing) {
			inputRef.current.focus()
		}
	}, [isEditing])

	return (
		<div onClick={handleClick} className={className}>
			{isEditing ? (
				<TextField
					color="formColor"
					variant="outlined"
					fullWidth
					type="text"
					value={text}
					onChange={handleChange}
					onBlur={handleBlur}
					ref={inputRef}
					autoFocus={true}
					size="small"
				/>
			) : (
				<div>{text}</div>
			)}
		</div>
	)
}

export default EditableTextField
