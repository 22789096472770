import { Button, Drawer } from "@mui/material"
import HomeTypeFilter from "./items/HomeTypeFilter"
import HomeAreaFilter from "./items/HomeAreaFilter"
import HomeStageFilter from "./items/HomeStageFilter"
import HomeRepairTypeFilter from "./items/HomeRepairTypeFilter"
import HomeRoomsFilter from "./items/HomeRoomsFilter"
import ObjectsFilter from "./items/ObjectsFilter"
import { useLocation, useNavigate } from "react-router-dom"

const DashboardFilterDrawer = ({
	open,
	setOpen,
	filterData = {},
	isLoading = false
}) => {
	const location = useLocation()
	const navigate = useNavigate()

	const handleResetFiler = () => {
		navigate(location.pathname, { replace: true })
	}

	return (
		<Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
			<div className="dashboard-filter-drawer-wrapper">
				<div className="mb-3 text-end">
					<Button
						color="error"
						variant="outlined"
						size="small"
						disabled={isLoading}
						onClick={() => handleResetFiler()}
					>
						<i className="bi bi-arrow-repeat text-lg mr-1" />
						Tozalash
					</Button>
				</div>
				<HomeTypeFilter disabled={isLoading} />
				<HomeAreaFilter
					min={parseInt(filterData?.minsquare)}
					max={parseInt(filterData?.maxsquare)}
					disabled={isLoading}
				/>
				<HomeStageFilter
					filterStages={parseInt(filterData?.maxstage)}
					disabled={isLoading}
				/>
				<HomeRepairTypeFilter disabled={isLoading} />
				<HomeRoomsFilter filterRooms={filterData?.room} disabled={isLoading} />
				<ObjectsFilter objects={filterData?.objects} disabled={isLoading} />
			</div>
		</Drawer>
	)
}
export default DashboardFilterDrawer
