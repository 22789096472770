const TASK_STATUS_TYPE = {
	STATUS_ACTIVE: {
		code: "1",
		label: "Aktiv"
	},
	STATUS_DONE: {
		code: "2",
		label: "Bajarildi"
	},
	STATUS_REJECTED: {
		code: "3",
		label: "Rad etilgan"
	}
}

export default TASK_STATUS_TYPE
