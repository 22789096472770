import { Button, CircularProgress } from "@mui/material"
import ObjectSelectBox from "components/boss/ObjectSelectBox"
import BarChart from "components/ui/charts/BarChart"
import LineChart from "components/ui/charts/LineChart"
import SimpleToggleButtonGroup from "components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useTopPanel from "hooks/useTopPanel"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { numericFormatter } from "react-number-format"
import { useQuery } from "react-query"
import { colors } from "shared/colors"
import colorGenerator from "utils/colorGenerator"
import { Tooltip } from "chart.js"

const localeMoment = moment
Tooltip.positioners.cursor = function (chartElements, coordinates) {
	return coordinates
}

const Sale = () => {
	const { t, i18n } = useTranslation()
	const { setComponent } = useTopPanel()
	const axiosPrivate = useAxiosPrivate()
	// const [hasError, setHasError] = useState(false)
	const [bgColors, setBgColors] = useState({})
	const [salesData, setSalesData] = useState([])
	const [salesByRoomsData, setSalesByRoomsData] = useState([])
	const [salesByStagesData, setSalesByStagesData] = useState([])
	const [salesByRepairTypeData, setSalesByRepairTypeData] = useState([])
	const [salesDateList, setSalesDateList] = useState(
		Array.from({ length: localeMoment().daysInMonth() }, (x, i) =>
			localeMoment().startOf("month").add(i, "days").format("DD")
		)
	)
	const [selectedObjectId, setSelectedObjectId] = useState("ALL")
	const [roomsOrSquare, setRoomsOrSquare] = useState("ROOMS")
	const [lineChartToggleOption, setLineChartToggleOption] = useState("ROOMS")
	const [dateType, setDateType] = useState("DAILY")
	const [dateTypes, setDateTypes] = useState([
		{
			code: "DAILY",
			label: "Kunlik",
			parserCode: "months",
			isFourYears: false
		},
		{ code: "MONTH", label: "Oylik", parserCode: "years", isFourYears: false },
		{ code: "YEAR", label: "Yillik", parserCode: "years", isFourYears: true }
	])
	const [startAndFinishDates, setStartAndFinishDates] = useState({
		startDate: moment().startOf("months").format("YYYY-MM-DD"),
		finishDate: moment().endOf("months").format("YYYY-MM-DD")
	})

	const { isLoading, isFetching, isError } = useQuery({
		queryKey: ["analyticSales", startAndFinishDates, selectedObjectId],
		queryFn: async function () {
			const response = await axiosPrivate.get(
				`/analytic/sales/index?type=${dateType}&start=${
					startAndFinishDates.startDate
				}&finish=${startAndFinishDates.finishDate}${
					selectedObjectId !== "ALL" ? `&objects[0]=${selectedObjectId}` : ""
				}`
			)
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && data.length > 0) {
				setSalesData([...data])
				setSalesByRoomsData(
					getRoomsOrStagesFromData([...data], "rooms", "xonali")
				)
				setSalesByStagesData(
					getRoomsOrStagesFromData([...data], "stages", "qavatli")
				)
				setSalesByRepairTypeData(getRepairTypesFromData([...data]))
			}
		},
		// enabled: !hasError,
		// onError: (error) => {
		// 	setHasError(true)
		// },
		retry: false
	})

	const getRoomsOrStagesFromData = (data, fieldName, label) => {
		let newBgColors = { ...bgColors }
		let uniqArr =
			data && data.length > 0
				? Object.keys(data[0][fieldName]).filter((item) => !!item)
				: []

		if (uniqArr.length > 0) {
			if (newBgColors.hasOwnProperty(fieldName)) {
				if (newBgColors[fieldName].length < uniqArr.length) {
					newBgColors = {
						...bgColors,
						[fieldName]: Array.from({ length: uniqArr.length }, () =>
							colorGenerator()
						)
					}
				}
			} else {
				newBgColors = {
					...bgColors,
					[fieldName]: Array.from({ length: uniqArr.length }, () =>
						colorGenerator()
					)
				}
			}
			setBgColors(newBgColors)
			return uniqArr.map((item, index) => ({
				label: !isNaN(item) ? `${item} ${label}` : item,
				value: data.map((el) =>
					el[fieldName]
						? Object.keys(el[fieldName]).reduce(
								(acc, curr) => acc + (curr === item ? el[fieldName][curr] : 0),
								0
						  )
						: 0
				),
				bgColor: newBgColors[fieldName][index]
			}))
		}
		return []
	}

	const getRepairTypesFromData = (data) => {
		let newBgColors = { ...bgColors }
		if (!newBgColors.hasOwnProperty("types")) {
			newBgColors = {
				...bgColors,
				types: Array.from({ length: 3 }, () => colorGenerator())
			}
		}
		setBgColors(newBgColors)
		let newRepaired = []
		let newNoRepaired = []
		let newDeal = []
		data.forEach((item) => {
			newRepaired.push(item.types["Tamirlik"])
			newNoRepaired.push(item.types["Tamirsiz"])
			newDeal.push(item.types["Kelishilgan holda"])
		})
		return [
			{
				label: "Ta'mirli",
				value: newRepaired,
				bgColor: newBgColors.types[0]
			},
			{
				label: "Ta'mirsiz",
				value: newNoRepaired,
				bgColor: newBgColors.types[1]
			},
			{
				label: "Kelishilgan holda",
				value: newDeal,
				bgColor: newBgColors.types[2]
			}
		]
	}

	const handleDateType = (value) => {
		setDateType(value)
		let currDateType = dateTypes.find((item) => item.code === value)

		if (currDateType.isFourYears) {
			let newStartDate = moment()
				.subtract(4, currDateType.parserCode)
				.startOf(currDateType.parserCode)
				.format("YYYY-MM-DD")
			setStartAndFinishDates({
				startDate: newStartDate,
				finishDate: moment().endOf(currDateType.parserCode).format("YYYY-MM-DD")
			})
			updateSalesDateList(currDateType, newStartDate)
		} else {
			let newStartDate = moment()
				.startOf(currDateType.parserCode)
				.format("YYYY-MM-DD")
			setStartAndFinishDates({
				startDate: newStartDate,
				finishDate: moment().endOf(currDateType.parserCode).format("YYYY-MM-DD")
			})
			updateSalesDateList(currDateType, newStartDate)
		}
	}

	const handlePrevMonth = () => {
		let currDateType = dateTypes.find((item) => item.code === dateType)

		let subtractNumber = currDateType.isFourYears ? 4 : 1

		let newStartDate = moment(startAndFinishDates.startDate, "YYYY-MM-DD")
			.subtract(subtractNumber, currDateType.parserCode)
			.startOf(currDateType.parserCode)
			.format("YYYY-MM-DD")

		let newFinishDate = moment(startAndFinishDates.finishDate, "YYYY-MM-DD")
			.subtract(subtractNumber, currDateType.parserCode)
			.endOf(currDateType.parserCode)
			.format("YYYY-MM-DD")

		setStartAndFinishDates({
			startDate: newStartDate,
			finishDate: newFinishDate
		})

		updateSalesDateList(currDateType, newStartDate)
	}

	const handleNextMonth = () => {
		let currDateType = dateTypes.find((item) => item.code === dateType)

		let addNumber = currDateType.isFourYears ? 4 : 1

		let newStartDate = moment(startAndFinishDates.startDate, "YYYY-MM-DD")
			.add(addNumber, currDateType.parserCode)
			.startOf(currDateType.parserCode)
			.format("YYYY-MM-DD")

		let newFinishDate = moment(startAndFinishDates.finishDate, "YYYY-MM-DD")
			.add(addNumber, currDateType.parserCode)
			.endOf(currDateType.parserCode)
			.format("YYYY-MM-DD")

		setStartAndFinishDates({
			startDate: newStartDate,
			finishDate: newFinishDate
		})

		updateSalesDateList(currDateType, newStartDate)
	}

	const updateSalesDateList = (currDateType, newStartDate) => {
		let newSalesDateList =
			currDateType.parserCode === "months"
				? Array.from(
						{ length: localeMoment(newStartDate, "YYYY-MM-DD").daysInMonth() },
						(x, i) =>
							localeMoment(newStartDate, "YYYY-MM-DD")
								.startOf("months")
								.add(i, "days")
								.format("DD")
				  )
				: currDateType.parserCode === "years" && !currDateType.isFourYears
				? Array.from({ length: 12 }, (x, i) =>
						localeMoment(newStartDate, "YYYY-MM-DD")
							.startOf("years")
							.add(i, "months")
							.format("MMMM")
				  )
				: Array.from({ length: 5 }, (x, i) =>
						localeMoment(newStartDate, "YYYY-MM-DD")
							.startOf("years")
							.add(i, "years")
							.format("YYYY")
				  )
		setSalesDateList(newSalesDateList)
	}

	const getDataSetWithOptions = (item) => {
		return {
			data: item.value,
			label: item.label,
			borderRadius: 8,
			borderWidth: 2,
			borderColor: item.bgColor,
			backgroundColor: item.bgColor,
			xAxisID: "xAxis",
			yAxisID: "yAxis",
			pointStyle: "circle",
			pointRadius: 5,
			pointHoverRadius: 7,
			// pointBorderColor: "transparent",
			// pointHoverBorderColor: "transparent",
			// pointBorderWidth: 0,
			tension: 0.3
		}
	}

	useEffect(() => {
		setComponent(<div className="component-title">{t("boss.sale.title")}</div>)
		localeMoment.locale(i18n.language === "uz" ? "uz-latn" : "uz")
	}, [i18n.language])

	return (
		<div className="component-wrapper boss-sale-wrapper">
			<div className="sale-actions-wrapper">
				<div className="sale-actions-body">
					<SimpleToggleButtonGroup
						delay={0}
						duration={0}
						value={roomsOrSquare}
						changeFn={(value) => setRoomsOrSquare(value)}
						options={[
							{
								code: "ROOMS",
								label: "Xonadonlar soni"
							},
							{
								code: "SQUARE",
								label: "Xonadon maydoni"
							}
						]}
						size="small"
					/>
					<div className="flex items-center">
						<div className="min-w-[250px]">
							<SimpleToggleButtonGroup
								delay={0}
								duration={0}
								value={dateType}
								changeFn={handleDateType}
								options={dateTypes}
								size="small"
								fullWidth={true}
							/>
						</div>
						<div className="w-full ml-4">
							<ObjectSelectBox
								value={selectedObjectId}
								setValue={setSelectedObjectId}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="sale-chart">
				<div className="chart-header">
					<Button
						variant="action"
						color="secondary"
						onClick={() => handlePrevMonth()}
						disabled={isLoading || isFetching}
					>
						<i className="bi bi-chevron-left" />
					</Button>
					<div className="chart-title">
						{dateType === "DAILY" &&
							`${localeMoment(
								startAndFinishDates.startDate,
								"YYYY-MM-DD"
							).format("MMMM")}, `}
						{dateType !== "YEAR" &&
							localeMoment(startAndFinishDates.startDate, "YYYY-MM-DD").year()}
						{dateType === "YEAR" &&
							`${localeMoment(
								startAndFinishDates.startDate,
								"YYYY-MM-DD"
							).year()} - ${localeMoment(
								startAndFinishDates.finishDate,
								"YYYY-MM-DD"
							).year()}`}
					</div>
					<Button
						variant="action"
						color="secondary"
						onClick={() => handleNextMonth()}
						disabled={isLoading || isFetching}
					>
						<i className="bi bi-chevron-right" />
					</Button>
				</div>
				{isError ? (
					<div>
						<span className="no-data-found-wrapper">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				) : salesData.length > 0 ? (
					<div className="h-[300px]">
						<BarChart
							chartLabels={
								dateType === "DAILY"
									? [...salesDateList]
									: dateType === "MONTH"
									? [...salesDateList]
									: [...salesDateList]
							}
							chartDatasets={[
								{
									data:
										roomsOrSquare === "ROOMS"
											? [...salesData].map((item) => item.count)
											: [...salesData].map((item) => item.square),
									borderRadius: 4,
									borderWidth: 2,
									borderColor: colors.baseColorLight,
									backgroundColor: colors.baseColorOutline,
									hoverBackgroundColor: colors.baseColorLight,
									tension: 0.3
								}
							]}
							optionPlugins={{
								tooltip: {
									usePointStyle: true,
									yAlign: "bottom",
									callbacks: {
										label: function (tooltipItem) {
											return numericFormatter(tooltipItem.raw.toString(), {
												decimalScale: 3,
												thousandSeparator: " ",
												allowNegative: false,
												suffix: roomsOrSquare === "SQUARE" ? " m2" : ""
											})
										},
										title: () => null
									},
									displayColors: false
								}
							}}
							options={{
								scales: {
									y: {
										ticks: {
											callback: function (value) {
												return numericFormatter(value.toString(), {
													decimalScale: 3,
													thousandSeparator: " ",
													allowNegative: false,
													suffix: roomsOrSquare === "SQUARE" ? " m2" : ""
												})
											}
										}
									}
								}
							}}
						/>
					</div>
				) : (
					<div className="h-[300px] flex items-center justify-center">
						<CircularProgress size={30} color="inherit" />
					</div>
				)}
			</div>
			<div className="sale-actions-wrapper">
				<div className="sale-actions-body">
					<SimpleToggleButtonGroup
						delay={0}
						duration={0}
						value={lineChartToggleOption}
						changeFn={(value) => setLineChartToggleOption(value)}
						options={[
							{
								code: "ROOMS",
								label: "Xonalar soni"
							},
							{
								code: "STAGES",
								label: "Qavatlar"
							},
							{
								code: "REPAIR_TYPE",
								label: "Xonadon turi"
							}
						]}
						size="small"
					/>
				</div>
			</div>
			<div className="sale-chart">
				<div className="chart-header">
					<div className="chart-title">
						{dateType === "DAILY" &&
							`${localeMoment(
								startAndFinishDates.startDate,
								"YYYY-MM-DD"
							).format("MMMM")}, `}
						{dateType !== "YEAR" &&
							localeMoment(startAndFinishDates.startDate, "YYYY-MM-DD").year()}
						{dateType === "YEAR" &&
							`${localeMoment(
								startAndFinishDates.startDate,
								"YYYY-MM-DD"
							).year()} - ${localeMoment(
								startAndFinishDates.finishDate,
								"YYYY-MM-DD"
							).year()}`}
					</div>
				</div>
				{isError ? (
					<div>
						<span className="no-data-found-wrapper">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				) : salesData.length > 0 ? (
					<div className="h-[300px]">
						<LineChart
							chartLabels={
								dateType === "DAILY"
									? [...salesDateList]
									: dateType === "MONTH"
									? [...salesDateList]
									: [...salesDateList]
							}
							chartDatasets={
								lineChartToggleOption === "ROOMS"
									? salesByRoomsData.map((item) => getDataSetWithOptions(item))
									: lineChartToggleOption === "STAGES"
									? salesByStagesData.map((item) => getDataSetWithOptions(item))
									: salesByRepairTypeData.map((item) =>
											getDataSetWithOptions(item)
									  )
							}
							optionPlugins={{
								legend: {
									display: true,
									position: "right",
									align: "center",
									labels: {
										usePointStyle: true,
										pointStyle: "rectRounded"
									}
								},
								tooltip: {
									usePointStyle: true,
									yAlign: "bottom",
									callbacks: {
										labelPointStyle: function () {
											return {
												pointStyle: "rectRounded",
												rotation: 0
											}
										},
										title: () => null
									},
									position: "cursor"
								}
							}}
							options={{
								interaction: {
									mode: "index",
									intersect: false
								}
							}}
							plugins={[
								{
									id: "crosshairLabel",
									afterDatasetsDraw(chart, args, plugins) {
										const { ctx } = chart
										ctx.strokeStyle = "gray"
										if (chart.crosshair) {
											ctx.save()
											ctx.beginPath()
											chart.crosshair.forEach((line) => {
												ctx.setLineDash([5, 5])
												ctx.moveTo(line.startX, line.startY)
												ctx.lineTo(line.endX, line.endY)
												ctx.stroke()
											})
										}
										ctx.setLineDash([])
									},
									afterEvent(chart, args) {
										const {
											chartArea: { left, right, top, bottom }
										} = chart
										const xCoor = args.event.x
										const yCoor = args.event.y
										if (!args.inChartArea && chart.crosshair) {
											chart.crosshair = undefined
											args.changed = true
										} else if (args.inChartArea) {
											/* chart.crosshair = [
												{
													startX: left,
													startY: yCoor,
													endX: xCoor,
													endY: yCoor
												},
												{
													startX: xCoor,
													startY: yCoor,
													endX: xCoor,
													endY: bottom
												}
											] */
											chart.crosshair = [
												{
													startX: left,
													startY: yCoor,
													endX: right,
													endY: yCoor
												},
												{
													startX: xCoor,
													startY: top,
													endX: xCoor,
													endY: bottom
												}
											]
											args.changed = true
										}
									}
								}
							]}
						/>
					</div>
				) : (
					<div className="h-[300px] flex items-center justify-center">
						<CircularProgress size={30} color="inherit" />
					</div>
				)}
			</div>
		</div>
	)
}
export default Sale
