import {
	CircularProgress,
	IconButton,
	InputAdornment,
	MenuItem,
	Popper,
	TextField
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useDebounce from "hooks/useDebounce"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { colors } from "shared/colors"

const CRMLeadSearch = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [isMouseOverList, setIsMouseOverList] = useState(false)
	const [open, setOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isNoDataFound, setIsNoDataFound] = useState(false)
	const [searchTxt, setSearchTxt] = useState("")
	const [searchResults, setSearchResults] = useState([])

	let anchorEl = document.getElementById("crm-search-field")

	const searchTxtDebounced = useDebounce(searchTxt, 300)

	const handleKeyDown = async (event) => {
		if (event.keyCode === 13) {
			await handleSearch()
		}
	}

	const handleSearch = async () => {
		if (searchTxtDebounced && searchTxtDebounced.length >= 3) {
			setIsLoading(true)
			setIsNoDataFound(false)
			try {
				let response = await axiosPrivate.post(
					"/crm/crm-lead/search",
					JSON.stringify({ search: searchTxtDebounced }),
					{
						headers: { "Content-Type": "application/json" }
					}
				)
				if (
					response &&
					response.data &&
					response.data.status &&
					response.data.data
				) {
					setSearchResults(response.data.data)
					setIsNoDataFound(response.data.data.length === 0 || false)
				}
				setIsLoading(false)
			} catch (error) {
				setIsLoading(false)
			}
		}
	}

	const getHighlightedValue = (value) => {
		return value && value.length > 0
			? value.replaceAll(
					searchTxtDebounced,
					`<span class="bg-base-color-disabled">${searchTxtDebounced}</span>`
			  )
			: ""
	}

	useEffect(() => {
		if (searchTxtDebounced) {
			handleSearch()
		} else {
		}
	}, [searchTxtDebounced])

	return (
		<div className="crm-lead-search-wrapper lg:w-[300px] w-[200px]">
			<TextField
				color="secondary"
				variant="outlined"
				fullWidth
				id="crm-search-field"
				name="crm-search-field"
				label={t("common.global.search")}
				value={searchTxt}
				onChange={(event) => setSearchTxt(event.target.value)}
				onKeyDown={handleKeyDown}
				autoComplete="off"
				onFocus={() => setOpen(true)}
				onBlur={() => {
					if (!isMouseOverList) {
						setOpen(false)
					}
				}}
				className="h-[40px]"
				InputProps={{
					endAdornment: (
						<InputAdornment position="end" className="custom-endAdornment">
							{searchTxt ? (
								<IconButton
									onClick={() => {
										setSearchTxt("")
										setSearchResults([])
									}}
									variant="onlyIcon"
								>
									<i className="bi bi-x end-adornment-close-button" />
								</IconButton>
							) : (
								<IconButton variant="onlyIcon" disabled disableRipple>
									<i className="bi bi-search text-base leading-4" />
								</IconButton>
							)}
						</InputAdornment>
					)
				}}
				sx={{
					"& .MuiOutlinedInput-root.Mui-focused i": {
						color: colors.baseColorLight
					},
					"& .MuiOutlinedInput-root i": {
						color: colors.grayColor
					}
				}}
			/>

			<Popper
				open={open}
				anchorEl={anchorEl}
				className="lead-search-popper-wrapper"
				onMouseOver={() => setIsMouseOverList(true)}
				onMouseOut={() => setIsMouseOverList(false)}
			>
				{isLoading ? (
					<div className="flex items-center justify-center my-[14px]">
						<CircularProgress size={20} color="inherit" />
					</div>
				) : searchResults && searchResults.length > 0 ? (
					searchResults.map((item, index) => (
						<Link
							to={`/admin/crm/lead/edit/${item.id}`}
							target="_blank"
							className="column-card no-underline"
							key={`search-result-item-${index}`}
							onClick={() => setOpen(false)}
						>
							<MenuItem>
								<div className="text-gray-900 text-sm leading-5">
									<div
										dangerouslySetInnerHTML={{
											__html: getHighlightedValue(item?.name)
										}}
									></div>
									{item?.contacts &&
										item?.contacts.length > 0 &&
										item?.contacts.map((contact, contactInd) => (
											<div
												key={`item-contact-${contactInd}`}
												className="text-gray-700 text-[12px] leading-4"
											>
												{contact?.phones &&
													contact?.phones.length > 0 &&
													contact?.phones.map((phone, phoneInd) => (
														<div
															key={`contact-${contactInd}-phone-${phoneInd}`}
															dangerouslySetInnerHTML={{
																__html: getHighlightedValue(phone?.phone)
															}}
														></div>
													))}
											</div>
										))}
								</div>
							</MenuItem>
						</Link>
					))
				) : (
					isNoDataFound && (
						<div>
							<span className="no-data-found-wrapper select-box my-2">
								<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
								{t("common.global.noDataFound")}
							</span>
						</div>
					)
				)}
			</Popper>
		</div>
	)
}
export default CRMLeadSearch
