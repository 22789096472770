import { Grid } from "@mui/material"
import useTopPanel from "hooks/useTopPanel"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import ContractsTerm from "./items/contracts-term/ContractsTerm"
import PaymentsByGraph from "./items/PaymentsByGraph"
import ClientContracts from "./items/ClientContracts"

const Contract = () => {
	const { t, i18n } = useTranslation()
	const { setComponent } = useTopPanel()

	useEffect(() => {
		setComponent(
			<div className="component-title">{t("boss.contract.title")}</div>
		)
	}, [i18n.language])

	return (
		<div className="component-wrapper boss-contract-wrapper">
			<Grid
				container
				rowSpacing={1}
				columnSpacing={2.5}
				columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
			>
				<Grid item={true} lg={4} md={6} sm={12} xs={12}>
					<ContractsTerm />
				</Grid>
				<Grid item={true} lg={4} md={6} sm={12} xs={12}>
					<PaymentsByGraph />
				</Grid>
				<Grid item={true} lg={4} md={6} sm={12} xs={12}>
					<ClientContracts />
				</Grid>
			</Grid>
		</div>
	)
}
export default Contract
