import {
	Button,
	ButtonBase,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import DateSelectBox from "./DateSelectBox"
import moment from "moment"
import { NumericFormat } from "react-number-format"

const SaleOfHomes = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [total, setTotal] = useState(0)
	const [openModal, setOpenModal] = useState(false)
	const [selectedObject, setSelectedObject] = useState({})
	const [startAndFinishDate, setStartAndFinishDate] = useState({
		startDate: moment()
			.set("years", 2010)
			.startOf("years")
			.format("YYYY-MM-DD"),
		finishDate: moment().format("YYYY-MM-DD")
	})

	const { data, isLoading, isFetching } = useQuery({
		queryKey: ["saleOfHomes", startAndFinishDate],
		queryFn: async function () {
			const response = await axiosPrivate.get(
				`/analytic/object/sale?from=${startAndFinishDate.startDate}&till=${startAndFinishDate.finishDate}`
			)
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && data.length > 0) {
				setTotal(data.reduce((acc, curr) => acc + curr?.count, 0))
			}
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	return (
		<div className="residential-complex-item sale-of-homes">
			<div className="residential-complex-item-header">
				<div className="residential-complex-item-title sale-of-homes-title">
					Xonadonlar sotuvi
				</div>
				<div className="w-[150px]">
					<DateSelectBox
						setStartAndFinishDate={setStartAndFinishDate}
						disabled={isLoading || isFetching}
					/>
				</div>
			</div>

			<div className="sale-of-homes-body">
				<ButtonBase className="total-sales">
					{isLoading || isFetching ? (
						<div className="flex mr-2">
							<CircularProgress size={20} color="inherit" />
						</div>
					) : (
						<NumericFormat
							value={total}
							displayType={"text"}
							allowNegative={false}
							thousandSeparator={" "}
							decimalScale={3}
							className="sales-number bg-transparent"
						/>
					)}{" "}
					sotilgan uylar soni
				</ButtonBase>
				<div className="sales-wrapper">
					{isLoading || isFetching ? (
						<div className="flex items-center justify-center h-[200px]">
							<CircularProgress size={30} color="inherit" />
						</div>
					) : data && data.length > 0 ? (
						data.map((item, index) => (
							<div key={`sale-item-${index}`} className="sale-item">
								<i className="bi bi-buildings text-2xl mr-1" />
								<div className="object-name">{item?.name}</div>
								<span className="divider">|</span>
								<div className="sale-count">
									Sotilgan: <span>{item?.count}</span>
								</div>
								<Button
									variant="action"
									color="secondary"
									onClick={() => {
										setSelectedObject(item)
										setOpenModal(true)
									}}
								>
									<i className="bi bi-chevron-right" />
								</Button>
							</div>
						))
					) : (
						<div className="no-data-found-wrapper h-[100px]">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</div>
					)}
				</div>
			</div>

			<SaleOfHomesByObject
				open={openModal}
				setOpen={setOpenModal}
				object={selectedObject}
				startAndFinishDate={startAndFinishDate}
			/>
		</div>
	)
}

const SaleOfHomesByObject = ({
	open,
	setOpen,
	object = {},
	startAndFinishDate = {}
}) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [totalDetails, setTotalDetails] = useState({})

	const { data, isLoading, isFetching } = useQuery({
		queryKey: ["saleOfHomesByObject", object],
		queryFn: async function () {
			const response = await axiosPrivate.get(
				`/analytic/object/blocks/${object?.id}?from=${startAndFinishDate.startDate}&till=${startAndFinishDate.finishDate}`
			)
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && data.length > 0) {
				let newTotalDetails = {
					insale: 0,
					order: 0,
					contract: 0,
					repaired: 0,
					norepaired: 0
				}
				data.forEach((item) => {
					newTotalDetails.insale += item?.insale
					newTotalDetails.order += item?.order
					newTotalDetails.contract += item?.contract
					newTotalDetails.repaired += item?.repaired
					newTotalDetails.norepaired += item?.norepaired
				})
				setTotalDetails(newTotalDetails)
			}
		},
		enabled: !!object?.id,
		retry: false
	})

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="sale-of-homes-by-object"
			aria-describedby="sale-of-homes-by-object-modal"
			fullWidth
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="sale-of-homes-by-object">
				<span>Xonadonlar sotuvi</span>
				<div className="close-btn-wrapper">
					<IconButton
						variant="onlyIcon"
						color="primary"
						onClick={() => setOpen(false)}
					>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<div className="sale-of-homes-by-object-modal-wrapper">
					{isLoading || isFetching ? (
						<div className="w-full h-[100px] flex items-center justify-center">
							<CircularProgress size={30} color="inherit" />
						</div>
					) : data && data.length > 0 ? (
						<div>
							<div className="header-details">
								<div className="header-details-item all-homes">
									<div className="object-name">
										<i className="bi bi-buildings text-2xl mr-1" />
										{object?.name}
									</div>
									<div className="homes-number total-homes">
										<div className="label">Jami uylar</div>
										<div className="value">
											{totalDetails?.insale +
												totalDetails?.order +
												totalDetails?.contract}
										</div>
									</div>
								</div>
								<div className="header-details-item ordered-homes homes-number">
									<div className="label">Buyurtma</div>
									<div className="value">{totalDetails?.order}</div>
								</div>
								<div className="header-details-item in-sale-homes homes-number">
									<div className="label">Sotuvda</div>
									<div className="value">{totalDetails?.insale}</div>
								</div>
								<div className="header-details-item in-sale-homes">
									<div className="homes-number">
										<div className="label">Sotilgan</div>
										<div className="value">{totalDetails?.contract}</div>
									</div>
									<div className="divider" />
									<div className="homes-by-repair">
										<div className="homes-by-repair-item repaired">
											Ta'mirli: <span>{totalDetails?.repaired}</span>
										</div>
										<div className="homes-by-repair-item no-repaired">
											Ta'mirsiz: <span>{totalDetails?.norepaired}</span>
										</div>
									</div>
								</div>
							</div>
							<div className="body-details">
								<Grid
									container
									spacing={2}
									columns={{ xs: 12, sm: 12, md: 12 }}
								>
									{data.map((item, index) => (
										<Grid
											item={true}
											md={6}
											sm={12}
											xs={12}
											key={`block-sale-homes-${index}`}
										>
											<div className="block-sale-homes-item">
												<div className="item-header">
													<div className="block-name">
														<i className="bi bi-building text-xl mr-1" />
														{item?.name}
													</div>
													<div className="item-total-homes">
														Jami uylar:{" "}
														<span>
															{item?.insale + item?.order + item?.contract}
														</span>
													</div>
												</div>
												<div className="item-body">
													<div className="item-sale-homes ordered-homes homes-number">
														<div className="label">Buyurtma</div>
														<div className="value">{item?.order}</div>
													</div>
													<div className="item-sale-homes in-sale-homes homes-number">
														<div className="label">Sotuvda</div>
														<div className="value">{item?.insale}</div>
													</div>
													<div className="item-sale-homes in-sale-homes">
														<div className="homes-number">
															<div className="label">Sotilgan</div>
															<div className="value">{item?.contract}</div>
														</div>
														<div className="divider" />
														<div className="homes-by-repair">
															<div className="homes-by-repair-item repaired">
																Ta'mirli: <span>{item?.repaired}</span>
															</div>
															<div className="homes-by-repair-item no-repaired">
																Ta'mirsiz: <span>{item?.norepaired}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Grid>
									))}
								</Grid>
							</div>
						</div>
					) : (
						<div className="no-data-found-wrapper w-full h-[100px]">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</div>
					)}
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default SaleOfHomes
