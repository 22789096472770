import { Chip, CircularProgress, Grid } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useTopPanel from "hooks/useTopPanel"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { taskStatusTypeVariants } from "shared/tableColVariantsList"

const Tasks = () => {
	const { setComponent } = useTopPanel()
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)

	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: "tasksList",
		queryFn: async function () {
			const response = await axiosPrivate.get("/crm/task")
			return response.data.data
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	useEffect(() => {
		setComponent(<div className="component-title">Topshiriqlar</div>)
	}, [])

	const setOrderStatus = (item) => {
		let result = taskStatusTypeVariants.filter(
			(variant) => variant.code === item
		)
		if (result.length > 0) {
			return (
				<Chip
					label={t(result[0].label)}
					variant="tableBadgeMini"
					color={result[0].color}
					sx={{
						height: "20px",
						fontSize: "0.65rem",
						borderRadius: "6px",
						"& .MuiChip-label": {
							padding: "0 4px"
						}
					}}
				/>
			)
		}
		return ""
	}

	return (
		<div className="component-list-wrapper bg-base-color-light-hover rounded-lg">
			<div className="tasks-wrapper">
				<div className="tasks-columns-wrapper">
					{isLoading || isFetching ? (
						<div className="circular-progress-box min-h-[500px] h-full w-full">
							<CircularProgress size={40} />
						</div>
					) : (
						data &&
						Object.keys(data).length > 0 && (
							<Grid container spacing={3} className="tasks-columns-grid">
								<div className="flex tasks-columns-grid">
									<Grid
										item={true}
										sm={1}
										xs={1}
										className="tasks-grid-column"
										id="tasks-wrapper"
									>
										<div className="tasks-grid-column-title-wrapper">
											<div className="tasks-grid-column-title">
												O'tkazib yuborilgan topshiriqlar
											</div>
										</div>

										<div className="tasks-grid-column-body">
											<div className="h-full">
												{data.left &&
													data.left.length > 0 &&
													data.left.map((item, itemIndex) => (
														<Link
															to={`/admin/crm/lead/edit/${item?.crm_lead_id}#task-${item?.id}`}
															target="_blank"
															className="task-card no-underline"
															key={`data-future-${itemIndex}`}
														>
															<div className="card-date text-end text-[12px] text-gray-500">
																{moment(item?.date, "YYYY-MM-DD HH:mm").format(
																	"DD.MM.YYYY HH:mm"
																)}
															</div>
															<div className="card-title">{item?.title}</div>
															<div className="card-status text-end">
																{setOrderStatus(item?.status)}
															</div>
														</Link>
													))}
											</div>
										</div>
									</Grid>
									<Grid item={true} sm={1} xs={1} className="tasks-grid-column">
										<div className="tasks-grid-column-title-wrapper">
											<div className="tasks-grid-column-title">
												Bugungi topshiriqlar
											</div>
										</div>

										<div className="tasks-grid-column-body">
											<div className="h-full">
												{data.today &&
													data.today.length > 0 &&
													data.today.map((item, itemIndex) => (
														<Link
															to={`/admin/crm/lead/edit/${item?.crm_lead_id}#task-${item?.id}`}
															target="_blank"
															className="task-card no-underline"
															key={`data-future-${itemIndex}`}
														>
															<div className="card-date text-end text-[12px] text-gray-500">
																{moment(item?.date, "YYYY-MM-DD HH:mm").format(
																	"DD.MM.YYYY HH:mm"
																)}
															</div>
															<div className="card-title">{item?.title}</div>
															<div className="card-status text-end">
																{setOrderStatus(item?.status)}
															</div>
														</Link>
													))}
											</div>
										</div>
									</Grid>
									<Grid item={true} sm={1} xs={1} className="tasks-grid-column">
										<div className="tasks-grid-column-title-wrapper">
											<div className="tasks-grid-column-title">
												Ertangi topshiriqlar
											</div>
										</div>

										<div className="tasks-grid-column-body">
											<div className="h-full">
												{data.tomorrow &&
													data.tomorrow.length > 0 &&
													data.tomorrow.map((item, itemIndex) => (
														<Link
															to={`/admin/crm/lead/edit/${item?.crm_lead_id}#task-${item?.id}`}
															target="_blank"
															className="task-card no-underline"
															key={`data-future-${itemIndex}`}
														>
															<div className="card-date text-end text-[12px] text-gray-500">
																{moment(item?.date, "YYYY-MM-DD HH:mm").format(
																	"DD.MM.YYYY HH:mm"
																)}
															</div>
															<div className="card-title">{item?.title}</div>
															<div className="card-status text-end">
																{setOrderStatus(item?.status)}
															</div>
														</Link>
													))}
											</div>
										</div>
									</Grid>
									<Grid item={true} sm={1} xs={1} className="tasks-grid-column">
										<div className="tasks-grid-column-title-wrapper">
											<div className="tasks-grid-column-title">
												Kelajakdagi topshiriqlar
											</div>
										</div>

										<div className="tasks-grid-column-body">
											<div className="h-full">
												{data.future &&
													data.future.length > 0 &&
													data.future.map((item, itemIndex) => (
														<Link
															to={`/admin/crm/lead/edit/${item?.crm_lead_id}#task-${item?.id}`}
															target="_blank"
															className="task-card no-underline"
															key={`data-future-${itemIndex}`}
														>
															<div className="card-date text-end text-[12px] text-gray-500">
																{moment(item?.date, "YYYY-MM-DD HH:mm").format(
																	"DD.MM.YYYY HH:mm"
																)}
															</div>
															<div className="card-title">{item?.title}</div>
															<div className="card-status text-end">
																{setOrderStatus(item?.status)}
															</div>
														</Link>
													))}
											</div>
										</div>
									</Grid>
								</div>
							</Grid>
						)
					)}
				</div>
			</div>
		</div>
	)
}
export default Tasks
