import usePermission from "hooks/usePermission"
import { Navigate, useLocation } from "react-router-dom"

const RequireChildAuth = ({ permissionCode = "", children }) => {
	const { hasPermission, permissions } = usePermission()
	const location = useLocation()

	// console.log("location = ", location)

	/* return permissions &&
		permissions.length > 0 &&
		hasPermission(permissionCode) ? (
		children
	) : (
		<Navigate to={location.pathname.split("/", 3).join("/")} replace />
	) */

	return permissions &&
		permissions.length > 0 &&
		hasPermission(permissionCode) ? (
		children
	) : location.state && location.state.allowed ? (
		<Navigate to={location.pathname + location.search} replace />
	) : (
		<Navigate to={location.pathname.split("/", 3).join("/")} replace />
	)
}

export default RequireChildAuth
