const CURRENCY_TYPE = {
	SUM: {
		code: "0",
		label: "UZS"
	},
	VALUTE: {
		code: "1",
		label: "USD"
	}
}

export default CURRENCY_TYPE
