import { Button } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import { useState } from "react"

const CRMOperatorSync = () => {
	const [isLoading, setIsLoading] = useState(false)
	const axiosPrivate = useAxiosPrivate()
	const sendNotification = useNotification()

	const handleOperatorSync = async () => {
		try {
			setIsLoading(true)
			const response = await axiosPrivate.post(
				"/crm/operator/sync",
				{},
				{
					headers: { "Content-Type": "application/json" }
				}
			)
			setIsLoading(false)
		} catch (error) {
			sendNotification({
				msg: error?.response?.data?.message || error?.message,
				variant: "error"
			})
			setIsLoading(false)
		}
	}

	return (
		<Button
			variant="actionLarge"
			color="info"
			onClick={() => {
				handleOperatorSync()
			}}
			disable={`${isLoading}`}
		>
			<i className={`bi bi-arrow-repeat${isLoading ? " animate-spin" : ""}`} />
		</Button>
	)
}
export default CRMOperatorSync
