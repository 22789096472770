import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import moment from "moment"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import OrderPaymentAddModal from "./OrderPaymentAddModal"
import ORDER_TYPE from "shared/orderTypeList"

const OrderPaymentsTable = ({
	paymentsList = [],
	refetchFn = () => {},
	orderStatus = "3"
}) => {
	const { t } = useTranslation()
	const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false)

	return (
		<div>
			<div className="flex items-center justify-between mb-2">
				<div className="text-lg font-medium text-base-color">
					{t("order.view.payments.title")}
				</div>
				{orderStatus === ORDER_TYPE.NEW.code && (
					<Button
						color="success"
						variant="outlined"
						onClick={() => setOpenAddPaymentModal(true)}
					>
						{t("order.view.payments.addPayment")}
					</Button>
				)}
			</div>
			<TableContainer className="flex-auto h-full">
				<Table
					stickyHeader
					sx={{ minWidth: 750, height: "max-content" }}
					aria-labelledby="tableTitle"
				>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell>{t("common.table.paymentId")}</TableCell>
							<TableCell>{t("common.table.paymentAmount")}</TableCell>
							<TableCell>{t("common.table.staff")}</TableCell>
							<TableCell>{t("common.table.date")}</TableCell>
						</TableRow>
					</TableHead>
					{paymentsList && paymentsList.length > 0 ? (
						<TableBody className="overflow-hidden">
							{paymentsList.map((row, rowIndex) => {
								return (
									<TableRow hover tabIndex={-1} key={"row-" + rowIndex}>
										<TableCell>{rowIndex + 1}</TableCell>
										<TableCell>{row?.id}</TableCell>
										<TableCell>
											<CurrencyFormat
												value={row?.sum}
												suffix={row?.isvalute === "1" ? " $" : " UZS"}
											/>
										</TableCell>
										<TableCell>{row?.staff?.name}</TableCell>
										<TableCell>
											{moment(row?.date, "YYYY-MM-DD").format("DD.MM.YYYY")}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					) : (
						<TableBody>
							<TableRow>
								<TableCell colSpan={5}>
									<span className="text-gray-400 flex items-center justify-center">
										<i className="bi bi-exclamation-octagon text-xl leading-4 mr-1" />{" "}
										{t("common.global.noDataFound")}
									</span>
								</TableCell>
							</TableRow>
						</TableBody>
					)}
				</Table>
			</TableContainer>

			{openAddPaymentModal && (
				<OrderPaymentAddModal
					open={openAddPaymentModal}
					setOpen={setOpenAddPaymentModal}
					refetch={refetchFn}
				/>
			)}
		</div>
	)
}
export default OrderPaymentsTable
