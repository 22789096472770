import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import FilterAccordion from "../FilterAccordion"
import { Checkbox, FormControl, FormControlLabel } from "@mui/material"
import HOME_TYPE from "shared/homeTypeList"
import { useSearchParams } from "react-router-dom"

const HomeTypeFilter = ({ disabled = false }) => {
	const { t } = useTranslation()
	const [selectedHomeTypes, setSelectedHomeTypes] = useState([])
	const [searchParams, setSearchParams] = useSearchParams()

	const handleHomeType = (objectId) => {
		let entries = Object.fromEntries(searchParams)
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("types")) {
				searchParams.delete(item)
			}
		})
		if (isSelected(objectId)) {
			selectedHomeTypes.splice(selectedHomeTypes.indexOf(objectId), 1)
		} else {
			selectedHomeTypes.push(objectId)
		}
		selectedHomeTypes.forEach((item, index) => {
			searchParams.set(`types[${index}]`, item)
		})
		setSearchParams(searchParams)
		setSelectedHomeTypes(selectedHomeTypes)
	}

	const isSelected = (objectId) => {
		return selectedHomeTypes.includes(objectId)
	}

	const setDefaultHomeTypes = () => {
		let entries = Object.fromEntries(searchParams)
		let newObjectsId = []
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("types")) {
				newObjectsId.push(searchParams.get(item))
			}
		})
		setSelectedHomeTypes(newObjectsId)
	}

	useEffect(() => {
		setDefaultHomeTypes()
	}, [searchParams])

	return (
		<FilterAccordion title="Holati" code="home-type">
			<FormControl fullWidth color="formColor" type="filterCheckbox">
				<FormControlLabel
					control={
						<Checkbox
							id="home-type-free"
							name={t(HOME_TYPE.ACTIVE.label)}
							checked={selectedHomeTypes.includes(HOME_TYPE.ACTIVE.code)}
							onChange={(event) => {
								handleHomeType(HOME_TYPE.ACTIVE.code)
							}}
							size="small"
							disabled={disabled}
						/>
					}
					label={t(HOME_TYPE.ACTIVE.label)}
				/>
			</FormControl>
			<FormControl fullWidth color="formColor" type="filterCheckbox">
				<FormControlLabel
					control={
						<Checkbox
							id="home-type-ordered"
							name={t(HOME_TYPE.TIME.label)}
							checked={selectedHomeTypes.includes(HOME_TYPE.TIME.code)}
							onChange={(event) => {
								handleHomeType(HOME_TYPE.TIME.code)
							}}
							size="small"
							disabled={disabled}
						/>
					}
					label={t(HOME_TYPE.TIME.label)}
				/>
			</FormControl>
			<FormControl fullWidth color="formColor" type="filterCheckbox">
				<FormControlLabel
					control={
						<Checkbox
							id="home-type-sold"
							name={t(HOME_TYPE.ORDERED.label)}
							checked={selectedHomeTypes.includes(HOME_TYPE.ORDERED.code)}
							onChange={(event) => {
								handleHomeType(HOME_TYPE.ORDERED.code)
							}}
							size="small"
							disabled={disabled}
						/>
					}
					label={t(HOME_TYPE.ORDERED.label)}
				/>
			</FormControl>
		</FilterAccordion>
	)
}
export default HomeTypeFilter
