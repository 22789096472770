import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { PermissionContext } from "context"
import { CircularProgress } from "@mui/material"

const PermissionProvider = ({ children, parentCode }) => {
	const [permissions, setPermissions] = useState(null)
	const axiosPrivate = useAxiosPrivate()

	const { isLoading, isFetching, refetch } = useQuery({
		queryKey: "permissions",
		queryFn: async function () {
			const response = await axiosPrivate.get(
				`/admin/permission/child?code=${parentCode}`
			)
			return response?.data?.data || []
		},
		onSuccess: (data) => {
			setPermissions(data.map((item) => item.code))
		},
		enabled: false,
		retry: false
	})

	const hasPermission = (value) => {
		return permissions && permissions.length > 0
			? permissions.includes(value)
			: false
	}

	useEffect(() => {
		setPermissions(null)
		refetch()
	}, [parentCode])

	return isLoading || isFetching ? (
		<div className="h-full flex flex-col items-center justify-center text-lg">
			<CircularProgress size={40} color="warning" />
		</div>
	) : permissions ? (
		<PermissionContext.Provider value={{ hasPermission, permissions }}>
			{children}
		</PermissionContext.Provider>
	) : (
		""
	)
}

export default PermissionProvider
