import React from 'react';
import '../style.css';

function ProgressItem({ item, maximum, width, color }) {
  // console.log(width);
  return (
    <div className='w-full'>
      <div style={{ width: 'calc(100% - 50px)' }}>
        <div
          className='progress-item-bar relative flex text-sm'
          style={{ width: `${width * 100}%`, minWidth: 'fit-content' }}
        >
          <div
            className='progress-item-member text-right px-2 flex items-center justify-end'
            style={{
              width: '10%',
              minWidth: 'fit-content',
              backgroundColor: color,
              height: '25px',
              borderRadius: '5px 0 0 5px',
            }}
          >
            <span className='text-white px-1'>{item?.income || '0'}</span>
          </div>
          <div
            className='progress-item-member-dash text-right px-2 flex items-center justify-end grow'
            style={{
              minWidth: 'fit-content',
              height: '25px',
              borderRadius: '0 5px 5px 0',
              '--odd-background': 'transparent',
              '--even-background': color,
            }}
          >
            <span className='bg-white px-1' style={{ color: color }}>
              {item?.outcome || '0'}
            </span>
          </div>
          <div className='absolute -right-10 top-0.5'>
            {Number(item?.income) + Number(item?.outcome) || 0}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgressItem;
