import React from 'react';
import RenderTitle from './RenderTitle';
import ProgressItem from './ProgressItem';

function ProgressInfoItem({ item, maximum, color, no_hours }) {
  const width = maximum
    ? (Number(item?.income) + Number(item?.outcome)) / maximum
    : 0;
  return (
    <div
      className='w-full py-2'
      style={{
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '200px 1fr',
      }}
    >
      <RenderTitle item={item} no_hours />
      <ProgressItem item={item} maximum={maximum} width={width} color={color} />
    </div>
  );
}

export default ProgressInfoItem;
