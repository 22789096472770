import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CircularProgress,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import useTopPanel from 'hooks/useTopPanel';
import StaffAddEditModal from './CrmAddEditModal';
import amoCrm from 'assets/images/amo-crm-logo.bmp';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

const Crm = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);
  const [openStaffModal, setOpenStaffModal] = useState(false);
  const [itemId, setItemId] = useState('');
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('settings.crm.title')}</div>
    );
  }, [i18n.language, setComponent, t]);

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: 'amoCrm',
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/amocrm/get/${itemId}`);
      return response.data;
    },
    onSuccess: (data) => {
      if (data?.success) {
        setData(data?.data);
        setItemId(data?.id);
      }
    },
    // onError: (error) => {
    // 	// setHasError(true)
    // },
    retry: false,
  });

  return (
    <div className='component-list-wrapper'>
      {isLoading || isFetching ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        <Grid
          className='mt-6 setting-wrapper'
          spacing={{ xs: 2, sm: 2, lg: 2 }}
          columns={{ xs: 12, sm: 12, lg: 12 }}
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component='img'
                height='140'
                image={amoCrm}
                alt='green iguana'
              />
            </CardActionArea>
            <CardActions className='place-content-center'>
              <Button
                variant='action'
                color='success'
                onClick={() => setOpenStaffModal(true)}
              >
                <i
                  className={`bi ${data?.id ? 'bi-gear' : 'bi-plus-circle'}`}
                />
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )}
      {openStaffModal && (
        <StaffAddEditModal
          open={openStaffModal}
          setOpen={setOpenStaffModal}
          itemId={data?.id}
          setItemId={setItemId}
          data={data}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default React.memo(Crm);
