const CRM_DATA_CHANGE_MESSAGE_TYPE = {
	name: {
		value: "value",
		label: "Lid nomi"
	},
	crm_block_item_id: {
		value: "relation_value",
		label: "Voronka"
	},
	user_id: {
		value: "relation_value",
		label: "Mas'ul xodim"
	},
	budget: {
		value: "relation_value",
		label: "Budjet"
	},
	operator_id: {
		value: "relation_value",
		label: "Operator"
	},
	object_id: {
		value: "relation_value",
		label: "Proyekt"
	},
	source_id: {
		value: "relation_value",
		label: "Manba"
	},
	reject_type_id: {
		value: "relation_value",
		label: "Rad etish sababi"
	}
}

export default CRM_DATA_CHANGE_MESSAGE_TYPE
