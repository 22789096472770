import { Checkbox, FormControl, FormControlLabel } from "@mui/material"
import FilterAccordion from "../FilterAccordion"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const ObjectsFilter = ({ objects, disabled = false }) => {
	const [selectedObjectsId, setSelectedObjectsId] = useState([])
	const [searchParams, setSearchParams] = useSearchParams()

	const handleObjectId = (objectId) => {
		let entries = Object.fromEntries(searchParams)
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("object")) {
				searchParams.delete(item)
			}
		})
		if (isSelected(objectId)) {
			selectedObjectsId.splice(selectedObjectsId.indexOf(objectId), 1)
		} else {
			selectedObjectsId.push(objectId)
		}
		selectedObjectsId.forEach((item, index) => {
			searchParams.set(`object[${index}]`, item)
		})
		setSearchParams(searchParams)
		setSelectedObjectsId(selectedObjectsId)
	}

	const isSelected = (objectId) => {
		return selectedObjectsId.includes(objectId)
	}

	const setDefaultObjects = () => {
		let entries = Object.fromEntries(searchParams)
		let newObjectsId = []
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("object")) {
				newObjectsId.push(parseInt(searchParams.get(item)))
			}
		})
		setSelectedObjectsId(newObjectsId)
	}

	useEffect(() => {
		setDefaultObjects()
	}, [searchParams])

	return (
		<FilterAccordion title="Turar-joy majmuasi" code="objects">
			{objects &&
				objects.length > 0 &&
				objects.map((object, index) => (
					<FormControl
						fullWidth
						color="formColor"
						type="filterCheckbox"
						key={`object-item-${index}`}
					>
						<FormControlLabel
							control={
								<Checkbox
									id="home-type-free"
									name={object?.name}
									checked={selectedObjectsId.includes(object?.id)}
									onChange={(event) => {
										handleObjectId(object?.id)
									}}
									size="small"
									disabled={disabled}
								/>
							}
							label={object?.name}
						/>
					</FormControl>
				))}
		</FilterAccordion>
	)
}
export default ObjectsFilter
