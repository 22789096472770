import moment from "moment"

const CRMTaskMessage = ({ data = {} }) => {
	return (
		<div className="task-wrapper flex items-center" id={`task-${data?.id}`}>
			<div className="task-icon text-base leading-4 border-2 border-gray-400 text-gray-400 rounded-full w-[42px] h-[42px] min-w-[42px] min-h-[42px] flex items-center justify-center mr-3">
				<i className="bi bi-journal-bookmark"></i>
			</div>
			<div className="task-details w-full">
				<div className="text-[12px] text-gray-500 flex flex-col">
					<div>{moment(data?.created_at).format("DD.MM.YYYY HH:mm")}, Biriktirilgan xodim: {data?.name}</div>
					<div>
						Bajarilishi kerak bo'lgan sana:{" "}
						{moment(data?.date).format("DD.MM.YYYY HH:mm")}
					</div>
				</div>
				<div className="mt-1">{data?.title}</div>
			</div>
		</div>
	)
}
export default CRMTaskMessage
