import { Button, Menu, MenuItem } from "@mui/material"
import usePermission from "hooks/usePermission"
import { useEffect, useState } from "react"

const CommentTypeSelect = ({
	selectedType = {},
	setSelectedType = () => {}
}) => {
	const [open, setOpen] = useState(false)
	const { hasPermission } = usePermission()

	const [typeOptions, setTypeOptions] = useState([
		{
			label: "Topshiriq",
			code: "TASK",
			disabled: !hasPermission("CRM_ADD_TASK")
		},
		{
			label: "Chat",
			code: "CHAT",
			disabled: !hasPermission("CRM_ADD_COMMENT")
		}
	])
	let anchorEl = document.getElementById("lead-comment-type-btn")

	const handleChangeType = (item) => {
		setSelectedType(item)
		menuToggle()
	}
	const menuToggle = () => {
		setOpen((prev) => !prev)
	}

	useEffect(() => {
		setSelectedType(typeOptions.find((item) => !item.disabled))
	}, [])

	return (
		<div className="types-wrapper">
			<Button
				id="lead-comment-type-btn"
				className="lead-comment-type-btn"
				onClick={menuToggle}
				variant="select-menu"
				size="extraSmall"
			>
				{selectedType?.label}
				<i
					className={`bi bi-chevron-down btn-icon ${
						open ? "rotate-180" : "rotate-0"
					}`}
				/>
			</Button>
			<Menu
				open={open}
				anchorEl={anchorEl}
				disableScrollLock={true}
				onClose={menuToggle}
				className="lead-comment-types-menu"
				PaperProps={{
					sx: {
						marginTop: "0.5rem"
					}
				}}
			>
				{typeOptions
					.filter((item) => !item.disabled)
					.map((item, index) => (
						<MenuItem
							key={`lead-comment-type-${index + 1}`}
							onClick={() => handleChangeType(item)}
							className={
								selectedType?.code === item?.code
									? "lead-comment-type-item is-active"
									: "lead-comment-type-item"
							}
						>
							{item.label}
						</MenuItem>
					))}
			</Menu>
		</div>
	)
}
export default CommentTypeSelect
