const { default: CURRENCY_TYPE } = require("shared/currencyTypeList")
const { default: HOME_TYPE } = require("shared/homeTypeList")
const { default: REPAIR_TYPE } = require("shared/repairTypeList")
const { default: RESIDENT_TYPE } = require("shared/residentTypeList")

function setShaxmatkaHomesDisabled(
	blocks = [],
	filter = {
		selectedRooms: [],
		repairedPrice: [],
		square: [],
		stage: [],
		repairType: REPAIR_TYPE.NOT_REPAIRED.code,
		residentType: RESIDENT_TYPE.NON_RESIDENTIAL.code,
		onlyFree: false
	},
	currency
) {
	if (blocks.length > 0) {
		blocks.map((block, index) =>
			block?.homes.forEach((item) => {
				getRoomsDisabled(item, filter.selectedRooms) &&
				getRepairPriceDisabled(
					item,
					"repaired",
					filter.repairedPrice,
					currency
				) &&
				getDisabledByCode(item, "square", filter.square) &&
				getDisabledByCode(item, "stage", filter.stage) &&
				getRepairAndResidentTypeDisabled(
					item,
					"isrepaired",
					filter.repairType
				) &&
				getRepairAndResidentTypeDisabled(item, "islive", filter.residentType) &&
				getOnlyFreesDisabled(item, filter.onlyFree)
					? document
							.querySelector(`.block-${index}-home#home-${item?.id}`)
							.classList.remove("is-disabled")
					: document
							.querySelector(`.block-${index}-home#home-${item?.id}`)
							.classList.add("is-disabled")
			})
		)
	}
}

const getRoomsDisabled = (item, selectedRooms) => {
	if (item.rooms) {
		return selectedRooms.includes(item.rooms)
	}
	return true
}

const getRepairPriceDisabled = (item, code, values, currency) => {
	if (item[code]) {
		return values.length > 1
			? item?.isvalute === CURRENCY_TYPE.VALUTE.code
				? parseFloat(item[code]) * parseFloat(currency) >= values[0] &&
				  parseFloat(item[code]) * parseFloat(currency) <= values[1]
				: parseFloat(item[code]) >= values[0] &&
				  parseFloat(item[code]) <= values[1]
			: values.length > 0
			? item?.isvalute === CURRENCY_TYPE.VALUTE.code
				? parseFloat(item[code]) * parseFloat(currency) == values[0]
				: parseFloat(item[code]) == values[0]
			: true
	}
	return true
}

const getDisabledByCode = (item, code, values) => {
	if (item[code]) {
		return values.length > 1
			? parseFloat(item[code]) >= values[0] &&
					parseFloat(item[code]) <= values[1]
			: values.length > 0
			? parseFloat(item[code]) == values[0]
			: true
	}
	return true
}

const getRepairAndResidentTypeDisabled = (item, code, value) => {
	if (item[code] && value) {
		return item[code] === value
	}
	return true
}

const getOnlyFreesDisabled = (item, onlyFree) => {
	if (item.status && onlyFree) {
		return item.status === HOME_TYPE.ACTIVE.code
	}
	return true
}

module.exports = setShaxmatkaHomesDisabled
