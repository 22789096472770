import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import FilterAccordion from "../FilterAccordion"
import { useTranslation } from "react-i18next"
import { repairTypeSelectOptions } from "shared/selectOptionsList"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const HomeRepairTypeFilter = ({ disabled = false }) => {
	const { t } = useTranslation()
	const [repairTypeValue, setRepairTypeValue] = useState("ALL")
	const [searchParams, setSearchParams] = useSearchParams()

	const handleRepairTypeChange = (value) => {
		let entries = Object.fromEntries(searchParams)
		Object.keys(entries).forEach((item) => {
			if (item && item.includes("repaired")) {
				searchParams.delete(item)
			}
		})
		if (value !== "ALL") {
			searchParams.set("repaired", value)
		}
		setSearchParams(searchParams)
		setRepairTypeValue(value)
	}

	const setDefaultHomeTypes = () => {
		let entries = Object.fromEntries(searchParams)
		let newRepairedType = "ALL"
		Object.keys(entries).forEach((item) => {
			if (item && item === "repaired") {
				newRepairedType = searchParams.get(item)
			}
		})
		setRepairTypeValue(newRepairedType)
	}

	useEffect(() => {
		setDefaultHomeTypes()
	}, [searchParams])

	return (
		<FilterAccordion title="Ta'mir holati" code="home-repair-type">
			<FormControl fullWidth color="formColor" type="filterRadioGroup">
				<RadioGroup
					aria-labelledby="repair-type-radio-buttons-group"
					name="repair-type-radio-buttons-group-name"
					value={repairTypeValue}
					onChange={(event) => handleRepairTypeChange(event.target.value)}
				>
					<FormControlLabel
						value="ALL"
						control={<Radio />}
						label={t("common.filter.all")}
						type="radio"
						disabled={disabled}
					/>
					{repairTypeSelectOptions.map((option) => (
						<FormControlLabel
							key={option.code}
							value={option.code}
							control={<Radio />}
							label={t(option.label)}
							type="radio"
							disabled={disabled}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</FilterAccordion>
	)
}
export default HomeRepairTypeFilter
