import useCurrency from "hooks/useCurrency"
import useNotification from "hooks/useNotification"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"

const DailyCurrency = () => {
	const { t } = useTranslation()
	const { currencyData } = useCurrency()
	const sendNotification = useNotification()

	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(currencyData?.sum)
		sendNotification({
			msg: "Nusxa olindi!",
			variant: "info"
		})
	}

	return (
		<div
			className="daily-currency-wrapper"
			onClick={() => handleCopyToClipboard()}
		>
			<div className="daily-currency-title">
				{t("common.fields.currency")}{" "}
				{moment(currencyData?.date).format("DD/MM/YYYY")}
			</div>
			<NumericFormat
				value={currencyData?.sum}
				displayType={"text"}
				allowNegative={false}
				thousandSeparator={" "}
				decimalScale={2}
				className="bg-transparent"
				suffix={" UZS"}
			/>
		</div>
	)
}
export default DailyCurrency
