import { useState } from "react"
import { useQuery } from "react-query"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { MenuContext } from "context"
import { CircularProgress } from "@mui/material"

const MenuProvider = ({ children }) => {
	const [menus, setMenus] = useState([])
	const axiosPrivate = useAxiosPrivate()

	const { isLoading, isFetching } = useQuery({
		queryKey: "menuPermissions",
		queryFn: async function () {
			const response = await axiosPrivate.get("/admin/permission/parent")
			return response?.data?.data || []
		},
		onSuccess: (data) => {
			if (data && data.length > 0) {
				setMenus(data.map((item) => item.code))
			}
		},
		enabled: true,
		retry: false
	})

	const hasMenu = (value) => {
		return menus && menus.length > 0 ? menus.includes(value) : false
	}

	return isLoading || isFetching ? (
		<div className="h-screen flex flex-col items-center justify-center text-lg">
			<CircularProgress size={40} color="success" />
		</div>
	) : (
		<MenuContext.Provider value={{ menus, hasMenu }}>
			{children}
		</MenuContext.Provider>
	)
}

export default MenuProvider
