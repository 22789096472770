import Docxtemplater from 'docxtemplater';
import moment from 'moment';
import PizZip from 'pizzip';
import { numericFormatter } from 'react-number-format';
import { saveAs } from 'file-saver';
import template from '../../../../template/Payment.docx';

const localeMoment = moment;
localeMoment.locale('ru');

export const generateDocument = async (responseData, fileName) => {
  let templateData = {
    company:
      responseData?.payment?.contract?.homes?.blocks?.objects?.companies?.name,
    id: responseData?.payment?.id,
    date:
      responseData?.payment?.date &&
      localeMoment(responseData?.payment?.date).format('DD'),
    month:
      responseData?.payment?.date &&
      localeMoment(responseData?.payment?.date).format('MMMM'),
    year:
      responseData?.payment?.date &&
      localeMoment(responseData?.payment?.date).format('YYYY'),
    custom: `${responseData?.payment?.contract2?.custom?.surname} ${responseData?.payment?.contract2?.custom?.name} ${responseData?.payment?.contract2?.custom?.middlename}`,
    contractName: responseData?.payment?.contract?.name,
    objectName: responseData?.payment?.contract?.homes?.blocks?.objects?.name,
    blockName: responseData?.payment?.contract?.homes?.blocks?.name,
    homeNumber: responseData?.payment?.contract?.homes?.number,
    paymentPrice: responseData?.payment?.sum
      ? numericFormatter(responseData?.payment?.sum, {
          decimalScale: 3,
          thousandSeparator: ' ',
          allowNegative: false,
        })
      : '0',
    paymentPriceText: responseData?.numbertext,
    paymentType: responseData?.payment?.types?.name,
  };
  try {
    let response = await fetch(template);
    let data = await response.arrayBuffer();

    let zip = PizZip(data);

    let templateDoc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });

    templateDoc.render(templateData);

    let generatedDoc = templateDoc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      compression: 'DEFLATE',
    });

    saveAs(generatedDoc, fileName);
  } catch (error) {
    console.error('Error: ' + error);
  }
};
