import { Button } from "@mui/material"
import BaseTooltip from "components/ui/tooltips/BaseTooltip"
import ShaxmatkaStageTooltip from "../tooltips/ShaxmatkaStageTooltip"
import { useTranslation } from "react-i18next"

const StagesView = ({
	data = {},
	selectedBlockId = "",
	selectedStage = "",
	backFn = () => {},
	onClickPolygonFn = () => {}
}) => {
	const { t } = useTranslation()

	const getPolygonPoints = (coordinates) => {
		return JSON.parse(coordinates)
			.flat(2)
			.map((item) => (item * 72).toFixed(3))
			.join()
	}

	return (
		<div className="shaxmatka-visual-view-wrapper">
			<div className="visual-view-header">
				<div className="visual-view-back-btn-wrapper">
					<BaseTooltip title={t("common.button.back")} arrow placement="bottom">
						<Button variant="action" color="primary" onClick={() => backFn()}>
							<i className="bi bi-arrow-left flex items-center justify-center" />
						</Button>
					</BaseTooltip>
				</div>
				{data.blocks && data.blocks.length > 0 && (
					<div className="visual-view-title">{data.blocks[0]?.block}</div>
				)}
			</div>

			<div className="visual-view-body">
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}/${data?.image}`}
					alt=""
					className="helper-image"
				/>
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}/${data?.image}`}
					alt=""
					className="main-image"
					id="main-image"
				/>
				<svg
					viewBox={`0 0 ${data?.width} ${data?.height}`}
					xmlns="http://www.w3.org/2000/svg"
					className="svg-item stage-svg-polygons"
				>
					{data.blocks &&
						data.blocks.length > 0 &&
						data.blocks.map((block, index) => (
							<ShaxmatkaStageTooltip
								key={`stage-polygon-${index}`}
								block={block}
							>
								<polygon
									key={`stage-polygon-${index}`}
									points={getPolygonPoints(block?.cordinates)}
									fillOpacity="0.3"
									style={{
										transform: `scaleY(-1) translateY(-${+data?.height}px)`
									}}
									className="stage-polygon"
									id={`block-${selectedBlockId}-stage-${selectedStage}-polygon`}
									onClick={() => onClickPolygonFn(block?.stage)}
								/>
							</ShaxmatkaStageTooltip>
						))}
				</svg>
			</div>
		</div>
	)
}
export default StagesView
