import { Button, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import HOME_TYPE from "shared/homeTypeList"
import { homeTypeVariants } from "shared/tableColVariantsList"

const ShaxmatkaStatuses = ({ isLoading = false, blocks }) => {
	const { t } = useTranslation()
	const [roomsNumber, setRoomsNumber] = useState(0)
	const [selectedHomeTypes, setSelectedHomeTypes] = useState(
		homeTypeVariants.map((item) => item.code)
	)

	useEffect(() => {
		setRoomsNumber(
			blocks.reduce((acc, block) => {
				let homesN =
					block?.homes &&
					block?.homes.length > 0 &&
					block.homes.reduce(
						(bAcc, bHome) =>
							bAcc + (selectedHomeTypes.includes(bHome?.status) ? 1 : 0),
						0
					)
				return acc + homesN
			}, 0)
		)
	}, [blocks, selectedHomeTypes])

	const handleToggleStatus = (code) => {
		if (selectedHomeTypes.includes(code)) {
			selectedHomeTypes.splice(selectedHomeTypes.indexOf(code), 1)
		} else {
			selectedHomeTypes.push(code)
		}
		setSelectedHomeTypes([...selectedHomeTypes])
	}

	return (
		<div className="sheet-status-items">
			<div className="sheet-status-item mr-5 cursor-default">
				<div className="status-item-text flex items-center">
					{t("shaxmatka.foundHomesNumber")}:
					<span className="found-homes-number">
						{isLoading ? <CircularProgress size={12.5} /> : roomsNumber}
					</span>
				</div>
			</div>
			<Button
				className={`sheet-status-item${
					selectedHomeTypes.includes(HOME_TYPE.ACTIVE.code)
						? ""
						: " is-disabled"
				}`}
				color="primary"
				variant="outlined"
				onClick={() => handleToggleStatus(HOME_TYPE.ACTIVE.code)}
			>
				<div className="status-item-indicator bg-base-color-disabled"></div>
				<div className="status-item-text">{t("shaxmatka.homeType.free")}</div>
			</Button>
			<Button
				className={`sheet-status-item${
					selectedHomeTypes.includes(HOME_TYPE.TIME.code) ? "" : " is-disabled"
				}`}
				color="warning"
				variant="outlined"
				onClick={() => handleToggleStatus(HOME_TYPE.TIME.code)}
			>
				<div className="status-item-indicator bg-orange-400"></div>
				<div className="status-item-text">
					{t("shaxmatka.homeType.ordered")}
				</div>
			</Button>
			<Button
				className={`sheet-status-item${
					selectedHomeTypes.includes(HOME_TYPE.ORDERED.code)
						? ""
						: " is-disabled"
				}`}
				color="error"
				variant="outlined"
				onClick={() => handleToggleStatus(HOME_TYPE.ORDERED.code)}
			>
				<div className="status-item-indicator bg-red-500"></div>
				<div className="status-item-text">{t("shaxmatka.homeType.sold")}</div>
			</Button>
			<Button
				className={`sheet-status-item${
					selectedHomeTypes.includes(HOME_TYPE.DISABLED.code)
						? ""
						: " is-disabled"
				}`}
				color="inherit"
				variant="outlined"
				onClick={() => handleToggleStatus(HOME_TYPE.DISABLED.code)}
			>
				<div className="status-item-indicator bg-gray-400"></div>
				<div className="status-item-text">
					{t("shaxmatka.homeType.disabled")}
				</div>
			</Button>
		</div>
	)
}
export default ShaxmatkaStatuses
