import { numericFormatter } from "react-number-format"

const doughnutTooltip = (
	sumLabel = "sum",
	currencyLabel = "valute",
	totalLabel = "total"
) =>
	function (context) {
		let tooltipEl = document.getElementById("chartjs-tooltip")

		if (!tooltipEl) {
			tooltipEl = document.createElement("div")
			tooltipEl.id = "chartjs-tooltip"
			tooltipEl.innerHTML = "<div></div>"
			document.body.appendChild(tooltipEl)
		}
		const tooltipModel = context.tooltip
		if (tooltipModel.opacity === 0) {
			tooltipEl.style.opacity = 0
			return
		}
		// tooltipEl.classList.remove(
		// 	"above",
		// 	"below",
		// 	"no-transform"
		// )
		// if (tooltipModel.yAlign) {
		// 	tooltipEl.classList.add(tooltipModel.yAlign)
		// } else {
		// 	tooltipEl.classList.add("no-transform")
		// }

		if (tooltipModel.body) {
			let innerHtml = "<div class='custom-doughnut-chart-tooltip-wrapper'>"
			if (tooltipModel.title && tooltipModel.title.length > 0) {
				innerHtml += `<div class="tooltip-title">${tooltipModel.title[0]}</div>`
			}
			if (
				tooltipModel?.labelColors &&
				tooltipModel?.labelColors.length > 0 &&
				tooltipModel?.dataPoints &&
				tooltipModel?.dataPoints.length > 0
			)
				innerHtml += `<div class="tooltip-body">
															<div class="tooltip-indicator" style="
																background-color: ${tooltipModel?.labelColors[0].backgroundColor};
															"></div>
															<div class="tooltip-prices">
															<div class="tooltip-price-item">
																UZS: <span class="price price-sum">${numericFormatter(
																	tooltipModel?.dataPoints[0]?.raw[sumLabel]
																		? tooltipModel?.dataPoints[0]?.raw[
																				sumLabel
																		  ].toString()
																		: "0",
																	{
																		decimalScale: 2,
																		thousandSeparator: " ",
																		allowNegative: false
																	}
																)} UZS</span>
															</div>
															<div class="tooltip-price-item">
																USD: <span class="price price-currency">${numericFormatter(
																	tooltipModel?.dataPoints[0]?.raw[
																		currencyLabel
																	]
																		? tooltipModel?.dataPoints[0]?.raw[
																				currencyLabel
																		  ].toString()
																		: "0",
																	{
																		decimalScale: 2,
																		thousandSeparator: " ",
																		allowNegative: false
																	}
																)} $</span>
															</div>
															<div class="tooltip-price-item">
																Jami: <span class="price price-total">${numericFormatter(
																	tooltipModel?.dataPoints[0]?.raw[totalLabel]
																		? tooltipModel?.dataPoints[0]?.raw[
																				totalLabel
																		  ].toString()
																		: "0",
																	{
																		decimalScale: 2,
																		thousandSeparator: " ",
																		allowNegative: false
																	}
																)} UZS</span>
															</div>
															</div>
														</div>`
			innerHtml += "</div>"
			tooltipEl.innerHTML = innerHtml
		}

		tooltipEl.style.opacity = 1
		const { offsetLeft: positionX, offsetTop: positionY } = context.chart.canvas
		tooltipEl.style.left = positionX + tooltipModel.caretX + "px"
		tooltipEl.style.top = positionY + tooltipModel.caretY + "px"
		// const position = context.chart.canvas.getBoundingClientRect()
		// tooltipEl.style.left =
		// 	position.left + window.pageXOffset + tooltipModel.caretX + "px"
		// tooltipEl.style.top =
		// 	position.top + window.pageYOffset + tooltipModel.caretY + "px"
	}

const barTooltip = (
	sumLabel = "sum",
	currencyLabel = "valute",
	totalLabel = "total",
	withTitle = false
) =>
	function (context) {
		let tooltipEl = document.getElementById("chartjs-tooltip")

		if (!tooltipEl) {
			tooltipEl = document.createElement("div")
			tooltipEl.id = "chartjs-tooltip"
			tooltipEl.innerHTML = "<div></div>"
			document.body.appendChild(tooltipEl)
		}
		const tooltipModel = context.tooltip
		if (tooltipModel.opacity === 0) {
			tooltipEl.style.opacity = 0
			return
		}

		if (tooltipModel.body) {
			let innerHtml = "<div class='custom-doughnut-chart-tooltip-wrapper'>"
			if (tooltipModel.title && tooltipModel.title.length > 0 && withTitle) {
				innerHtml += `<div class="tooltip-title">${tooltipModel.title[0]}</div>`
			}
			if (
				tooltipModel?.labelColors &&
				tooltipModel?.labelColors.length > 0 &&
				tooltipModel?.dataPoints &&
				tooltipModel?.dataPoints.length > 0
			)
				innerHtml += `<div class="tooltip-body">
																<div class="tooltip-prices">
																<div class="tooltip-price-item">
																	UZS: <span class="price price-sum">${numericFormatter(
																		tooltipModel?.dataPoints[0]?.raw[sumLabel]
																			? tooltipModel?.dataPoints[0]?.raw[
																					sumLabel
																			  ].toString()
																			: "0",
																		{
																			decimalScale: 2,
																			thousandSeparator: " ",
																			allowNegative: false
																		}
																	)} UZS</span>
																</div>
																<div class="tooltip-price-item">
																	USD: <span class="price price-currency">${numericFormatter(
																		tooltipModel?.dataPoints[0]?.raw[
																			currencyLabel
																		]
																			? tooltipModel?.dataPoints[0]?.raw[
																					currencyLabel
																			  ].toString()
																			: "0",
																		{
																			decimalScale: 2,
																			thousandSeparator: " ",
																			allowNegative: false
																		}
																	)} $</span>
																</div>
																<div class="tooltip-price-item">
																	Jami: <span class="price price-total">${numericFormatter(
																		tooltipModel?.dataPoints[0]?.raw[totalLabel]
																			? tooltipModel?.dataPoints[0]?.raw[
																					totalLabel
																			  ].toString()
																			: "0",
																		{
																			decimalScale: 2,
																			thousandSeparator: " ",
																			allowNegative: false
																		}
																	)} UZS</span>
																</div>
																</div>
															</div>`
			innerHtml += "</div>"
			tooltipEl.innerHTML = innerHtml
		}

		// const position = context.chart.canvas.getBoundingClientRect()
		// tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px"
		// tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px"

		tooltipEl.style.opacity = 1
		const { offsetLeft: positionX, offsetTop: positionY } = context.chart.canvas
		tooltipEl.style.left = positionX + tooltipModel.caretX + "px"
		tooltipEl.style.top = positionY + tooltipModel.caretY + "px"
	}

export { doughnutTooltip, barTooltip }
