import { TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { NumericFormat } from "react-number-format"

const EditableNumberField = ({
	initialValue = "",
	changeFn = () => {},
	className = "",
	decimalScale = 0,
	children
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [prevValue, setPrevValue] = useState(initialValue)
	const [value, setValue] = useState(initialValue)

	const handleClick = () => {
		setIsEditing(true)
		setPrevValue(initialValue)
		setValue(initialValue)
	}

	const handleChange = (event) => {
		let formattedValue =
			event.target.value && parseFloat(event.target.value.split(" ").join(""))
		if (formattedValue) {
			setValue(formattedValue)
		}
	}

	const handleBlur = (event) => {
		setIsEditing(false)
		if (prevValue !== value) {
			changeFn(value)
		}
		let formattedValue =
			event.target.value && parseFloat(event.target.value.split(" ").join(""))
		setPrevValue(formattedValue)
	}

	useEffect(() => {
		setPrevValue(initialValue)
		setValue(initialValue)
	}, [initialValue])

	return (
		<span onClick={handleClick} className={className}>
			{isEditing ? (
				<NumericFormat
					label=""
					value={value}
					color="formColor"
					variant="outlined"
					onChange={handleChange}
					onBlur={handleBlur}
					autoFocus={true}
					customInput={TextField}
					allowNegative={false}
					allowLeadingZeros={false}
					decimalScale={decimalScale}
					thousandSeparator={" "}
					size="small"
				/>
			) : (
				children
			)}
		</span>
	)
}

export default EditableNumberField
