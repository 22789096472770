import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import TopPanel from 'components/top-panel/TopPanel';
import SidebarPanel from 'components/sidebar/SidebarPanel';
import useToggle from 'hooks/useToggle';

const BaseLayout = () => {
  const [sideBarToggle, setSideBarToggle] = useToggle('sidebarOpen');

  useEffect(() => {
    window.addEventListener('keypress', (event) => {
      if (event && event.keyCode === 91) {
        setSideBarToggle(false);
      }
      if (event && event.keyCode === 93) {
        setSideBarToggle(true);
      }
    });
  }, []);

  return (
    <>
      <div className='flex flex-row relative'>
        <div
          className={`aside-container${
            sideBarToggle ? ' sidebar-full' : ' sidebar-small'
          }`}
        >
          <SidebarPanel
            sideBarToggle={sideBarToggle}
            setSideBarToggle={setSideBarToggle}
          />
        </div>
        <div
          className={`base-container${
            sideBarToggle ? ' base-container-small' : ' base-container-full'
          }`}
        >
          <div className='header-container'>
            <TopPanel />
          </div>
          <main className='main-container with-max-width'>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default BaseLayout;
