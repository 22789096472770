import { Button, Menu, MenuItem } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const ShaxmatkaTypeMenu = ({ tabValue = 0, changeFn = () => {} }) => {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)

	let anchorEl = document.getElementById("shaxmatka-type-btn")

	const menuToggle = () => {
		setOpen((prev) => !prev)
	}

	return (
		<div className="sheet-shaxmatka-type-wrapper">
			<Button
				id="shaxmatka-type-btn"
				className="shaxmatka-type-btn"
				onClick={menuToggle}
				variant="select-menu"
				size="small"
			>
				{tabValue === 0
					? t("shaxmatka.type.shaxmatka1")
					: tabValue === 1
					? t("shaxmatka.type.shaxmatka2")
					: tabValue === 2
					? t("shaxmatka.type.table")
					: "Visual ko'rinishi"}
				<i
					className={`bi bi-chevron-down btn-icon ${
						open ? "rotate-180" : "rotate-0"
					}`}
				/>
			</Button>
			<Menu
				open={open}
				anchorEl={anchorEl}
				disableScrollLock={true}
				onClose={menuToggle}
				className="crm-blocks-menu"
			>
				<MenuItem
					onClick={() => {
						changeFn(0)
						menuToggle()
					}}
					className={`small-size${tabValue === 0 ? " is-active" : ""}`}
				>
					<span>
						<i className="bi bi-grid" /> {t("shaxmatka.type.shaxmatka1")}
					</span>
				</MenuItem>
				<MenuItem
					onClick={() => {
						changeFn(1)
						menuToggle()
					}}
					className={`small-size${tabValue === 1 ? " is-active" : ""}`}
				>
					<span>
						<i className="bi bi-grid" /> {t("shaxmatka.type.shaxmatka2")}
					</span>
				</MenuItem>
				<MenuItem
					onClick={() => {
						changeFn(2)
						menuToggle()
					}}
					className={`small-size${tabValue === 2 ? " is-active" : ""}`}
				>
					<span>
						<i className="bi bi-list-task" /> {t("shaxmatka.type.table")}
					</span>
				</MenuItem>
				<MenuItem
					onClick={() => {
						changeFn(3)
						menuToggle()
					}}
					className={`small-size${tabValue === 3 ? " is-active" : ""}`}
				>
					<span>
						<i className="bi bi-buildings" /> Visual ko'rinishi
					</span>
				</MenuItem>
			</Menu>
		</div>
	)
}
export default ShaxmatkaTypeMenu
