import {
	Button,
	LinearProgress,
	Skeleton,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import useTopPanel from "hooks/useTopPanel"
import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueries } from "react-query"
import RoleAddModal from "./RoleAddModal"

const Permission = () => {
	const { t, i18n } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const { setComponent } = useTopPanel()
	const [rolesList, setRolesList] = useState([])
	const [openAddRoleModal, setOpenAddRoleModal] = useState(false)
	const sendNotification = useNotification()

	const [permissionsQuery, rolesQuery] = useQueries([
		{
			queryKey: "permissionsQuery",
			queryFn: async function () {
				const response = await axiosPrivate.get("/admin/permission/index")
				return response.data.data
			},
			onSuccess: (data) => {},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
		{
			queryKey: "rolesQuery",
			queryFn: async function () {
				const response = await axiosPrivate.get("/admin/permission/roles")
				return response.data.data
			},
			enabled: !hasError,
			onSuccess: (data) => {
				setRolesList(JSON.parse(JSON.stringify(data)))
			},
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
	])

	const handleMultipleResponse = (multipleResponse) => {
		let result = multipleResponse.reduce((acc, curr) => {
			return acc && curr && curr.data && curr.data.status
		}, true)
		if (result) {
			sendNotification({
				msg: t("settings.permission.alerts.success"),
				variant: "success"
			})
			rolesQuery.refetch()
		}
	}

	const handleChangePermission = async (values, roleId, codes) => {
		try {
			let multipleResponse = await Promise.all(
				codes.map((code, index) =>
					axiosPrivate.post(
						"/admin/permission/update",
						JSON.stringify({
							status: values[index] ? "1" : "0",
							code: code,
							role_id: roleId
						}),
						{ headers: { "Content-Type": "application/json" } }
					)
				)
			)
			handleMultipleResponse(multipleResponse)
		} catch (error) {
			sendNotification({
				msg: error?.response?.data?.message || error?.message,
				variant: "error"
			})
		}
	}

	useEffect(() => {
		setComponent(
			<div className="component-title">
				{t("settings.permission.title")}
			</div>
		)
	}, [i18n.language])

	return (
		<div className="permission-wrapper">
			<div className="float-right mb-3">
				<Button
					color="success"
					variant="contained"
					onClick={() => setOpenAddRoleModal(true)}
				>
					{t("settings.permission.addRole")}
				</Button>
			</div>
			<TableContainer className="h-full">
				<Table
					stickyHeader
					sx={{ minWidth: 750, height: "max-content" }}
					aria-labelledby="tableTitle"
				>
					{(permissionsQuery.isLoading && permissionsQuery.isFetching) ||
					(rolesQuery.isLoading && rolesQuery.isFetching) ? (
						<Fragment>
							<TableHead>
								<TableRow>
									<TableCell>
										<Skeleton animation="wave" variant="rounded" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" variant="rounded" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" variant="rounded" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" variant="rounded" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" variant="rounded" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" variant="rounded" />
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className="overflow-hidden">
								<TableRow>
									<TableCell colSpan={6}>
										<LinearProgress />
									</TableCell>
								</TableRow>
							</TableBody>
						</Fragment>
					) : permissionsQuery?.isError ? (
						<Fragment>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody className="overflow-hidden">
								<TableRow>
									<TableCell>
										<div className="flex flex-col items-center">
											{permissionsQuery?.error?.response?.data?.message && (
												<span className="text-red-600 font-medium">
													{permissionsQuery?.error?.response?.data?.message}
												</span>
											)}
											{permissionsQuery?.error?.response?.data?.details &&
												permissionsQuery?.error?.response?.data?.details[0]
													?.message && (
													<div>
														<span className="text-red-600 font-medium">
															{t("common.errors.message")}
														</span>
														<span>
															{
																permissionsQuery?.error?.response?.data
																	?.details[0]?.message
															}
														</span>
													</div>
												)}
										</div>
									</TableCell>
								</TableRow>
							</TableBody>
						</Fragment>
					) : permissionsQuery?.data &&
					  permissionsQuery?.data.length > 0 &&
					  rolesList &&
					  rolesList.length > 0 ? (
						<Fragment>
							<TableHead>
								<TableRow>
									<TableCell>
										{t("common.table.permissionName")} |{" "}
										{t("common.table.roleName")}
									</TableCell>
									{rolesList.map((role, index) => (
										<TableCell key={`role-cell-${index}`}>
											{role?.name}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody className="overflow-hidden">
								{permissionsQuery?.data.map((permission, permissionInd) => (
									<Fragment key={`row-permission-${permissionInd}`}>
										<TableRow
											hover
											tabIndex={-1}
											className="is-parent-permission"
										>
											<TableCell>
												<span className="font-bold">{permission?.name}</span>
											</TableCell>
											{rolesList.map((role, roleInd) => (
												<TableCell key={`row-permission-role-cell-${roleInd}`}>
													<Switch
														color="secondary"
														checked={Boolean(
															role.permissions.find(
																(item) => item.code === permission?.code
															)
														)}
														onChange={(event) =>
															handleChangePermission(
																[event?.target?.checked],
																role?.id,
																[permission?.code]
															)
														}
													/>
												</TableCell>
											))}
										</TableRow>
										{permission.childs &&
											permission.childs.length > 0 &&
											permission.childs.map(
												(childPermission, childPermissionInd) => (
													<TableRow
														hover
														tabIndex={-1}
														key={`row-permission-${permissionInd}-child-${childPermissionInd}`}
													>
														<TableCell>{childPermission?.name}</TableCell>
														{rolesList.map((role, roleInd) => (
															<TableCell
																key={`row-permission-child-role-cell-${roleInd}`}
															>
																<Switch
																	color="secondary"
																	checked={Boolean(
																		role.permissions.find(
																			(item) =>
																				item.code === childPermission?.code
																		)
																	)}
																	onChange={(event) =>
																		handleChangePermission(
																			["1", event?.target?.checked],
																			role?.id,
																			[permission?.code, childPermission?.code]
																		)
																	}
																/>
															</TableCell>
														))}
													</TableRow>
												)
											)}
									</Fragment>
								))}
							</TableBody>
						</Fragment>
					) : (
						<Fragment>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell colSpan={5}>
										<span className="text-gray-400 flex items-center justify-center">
											<i className="bi bi-exclamation-octagon text-xl leading-4 mr-1" />{" "}
											{t("common.global.noDataFound")}
										</span>
									</TableCell>
								</TableRow>
							</TableBody>
						</Fragment>
					)}
				</Table>
			</TableContainer>

			{openAddRoleModal && (
				<RoleAddModal
					open={openAddRoleModal}
					setOpen={setOpenAddRoleModal}
					refetchFn={rolesQuery.refetch}
				/>
			)}
		</div>
	)
}

export default Permission
