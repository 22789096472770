import ShaxmatkaBlockTooltip from "../tooltips/ShaxmatkaBlockTooltip"

const BlocksView = ({
	data = {},
	selectedBlockId = "",
	setSelectedBlockId = () => {}
}) => {
	const getPolygonPoints = (coordinates) => {
		return JSON.parse(coordinates)
			.flat(2)
			.map((item) => (item * 72).toFixed(3))
			.join()
	}

	return (
		<div className="shaxmatka-visual-view-wrapper">
			<div className="visual-view-body">
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}/${data?.image}`}
					alt=""
					className="helper-image"
				/>
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}/${data?.image}`}
					alt=""
					className="main-image"
					id="main-image"
				/>
				<svg
					viewBox={`0 0 ${data?.width} ${data?.height}`}
					xmlns="http://www.w3.org/2000/svg"
					className="svg-item block-svg-polygons"
				>
					{data.blocks &&
						data.blocks.length > 0 &&
						data.blocks.map((block, index) => (
							<ShaxmatkaBlockTooltip
								key={`block-polygon-${index}`}
								block={block}
							>
								<polygon
									key={`block-polygon-${index}`}
									points={getPolygonPoints(block?.cordinates)}
									fillOpacity="0.3"
									style={{
										transform: `scaleY(-1) translateY(-${+data?.height + 10}px)`
									}}
									className="block-polygon"
									onClick={() => setSelectedBlockId(block?.id)}
									id={`block-${selectedBlockId}-polygon`}
								/>
							</ShaxmatkaBlockTooltip>
						))}
				</svg>
			</div>
		</div>
	)
}
export default BlocksView
