import React, { memo } from 'react';
import { Grid } from '@mui/material';

import BlocksFilter from './items/BlocksFilter';
import DateFilter from './items/DateFilter';
import ObjectFilter from './items/ObjectFilter';

const ArrearsFilterComponent = () => {
  return (
    <div className='mb-1 pb-2'>
      <Grid
        container
        spacing={{ xs: 2, sm: 2, lg: 2 }}
        rowSpacing={1}
        columns={{ xs: 12, sm: 12, lg: 12 }}
      >
        <Grid item={true} lg={3} sm={6} xs={12}>
          <ObjectFilter />
        </Grid>
        <Grid item={true} lg={3} sm={6} xs={12}>
          <BlocksFilter />
        </Grid>
        <Grid item={true} lg={3} sm={6} xs={12}>
          <DateFilter />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(ArrearsFilterComponent);
