import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { CircularProgress, IconButton } from "@mui/material"
import { Fragment, useRef, useState } from "react"

const ImagesSwiper = ({ imageURLs = [] }) => {
	const [sliderActiveIndex, setSliderActiveIndex] = useState(0)
	const swiperRef = useRef(null)

	return (
		<div className="images-swiper-wrapper">
			<Swiper
				lazy="true"
				navigation={false}
				modules={[Navigation]}
				onBeforeInit={(swiper) => {
					swiperRef.current = swiper
				}}
				onSlideChange={(a) => {
					setSliderActiveIndex(a?.activeIndex)
				}}
			>
				{imageURLs &&
					imageURLs.length > 0 &&
					imageURLs.map((item, index) => (
						<SwiperSlide key={`swiper-item-${index}`}>
							<div className="swiper-zoom-container">
								<img
									src={`${process.env.REACT_APP_BACKEND_URL}/${item}`}
									alt=""
									loading="lazy"
								/>
							</div>
							<div className="swiper-lazy-preloader !animate-none !border-none">
								<CircularProgress size={40} sx={{ color: "white" }} />
							</div>
						</SwiperSlide>
					))}
			</Swiper>
			{imageURLs && imageURLs.length > 1 && (
				<Fragment>
					<div
						className={`swiper-navigation-button-wrapper prev${
							sliderActiveIndex === 0 ? " is-disabled" : ""
						}`}
					>
						<IconButton
							variant="swiperNavigationButton"
							color="primary"
							onClick={() => {
								swiperRef.current?.slidePrev()
							}}
							disabled={sliderActiveIndex === 0}
							size="small"
						>
							<i className="bi bi-chevron-left" />
						</IconButton>
					</div>
					<div
						className={`swiper-navigation-button-wrapper next${
							sliderActiveIndex === imageURLs.length - 1 ? " is-disabled" : ""
						}`}
					>
						<IconButton
							variant="swiperNavigationButton"
							color="primary"
							onClick={() => {
								swiperRef.current?.slideNext()
							}}
							disabled={sliderActiveIndex === imageURLs.length - 1}
							size="small"
						>
							<i className="bi bi-chevron-right" />
						</IconButton>
					</div>
					<div className="swiper-pagination-wrapper">
						<span className="swiper-pagination-details">
							{sliderActiveIndex + 1}/{imageURLs.length}
						</span>
					</div>
				</Fragment>
			)}
		</div>
	)
}
export default ImagesSwiper
