import { CircularProgress } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import ContractsAccordion from "./ContractsAccordion"
import ObjectSelectBox from "components/boss/ObjectSelectBox"

const ContractsTerm = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [from0To24Contacts, setFrom0To24Contacts] = useState([])
	const [from24To60Contacts, setFrom24To60Contacts] = useState([])
	const [moreThan60Contacts, setMoreThan60Contacts] = useState([])
	const [selectedObjectId, setSelectedObjectId] = useState("ALL")

	const { isLoading, isFetching, isError } = useQuery({
		queryKey: ["contractsTerm", selectedObjectId],
		queryFn: async function () {
			const response = await axiosPrivate.get(
				`/analytic/contracts/index${
					selectedObjectId !== "ALL" ? `?objects[0]=${selectedObjectId}` : ""
				}`
			)
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && data?.small && data?.small.length > 0) {
				setFrom0To24Contacts([...data?.small.sort((a, b) => a?.key - b?.key)])
				setFrom24To60Contacts([
					...data?.avarage.sort((a, b) => a?.key - b?.key)
				])
				setMoreThan60Contacts([...data?.big.sort((a, b) => a?.key - b?.key)])
			}
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	return (
		<div className="boss-contract-item contract-terms">
			<div className="boss-contract-item-header">
				<div className="boss-contract-item-title contract-terms-title">
					Shartnoma muddati
				</div>
			</div>

			<div className="boss-contract-item-actions-wrapper">
				<ObjectSelectBox
					value={selectedObjectId}
					setValue={setSelectedObjectId}
					size="small"
				/>
			</div>

			<div className="contract-terms-body">
				{isError ? (
					<div className="no-data-found-wrapper h-[100px]">
						<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
						{t("common.global.noDataFound")}
					</div>
				) : isLoading || isFetching ? (
					<div className="circular-progress-box w-full h-[100px]">
						<CircularProgress size={25} />
					</div>
				) : (
					<Fragment>
						<ContractsAccordion
							data={from0To24Contacts}
							title="0-24"
							itemClassName="from-0-to-24"
						/>
						<ContractsAccordion
							data={from24To60Contacts}
							title="24-60"
							itemClassName="from-24-to-60"
						/>
						<ContractsAccordion
							data={moreThan60Contacts}
							title=">60"
							itemClassName="more-than-60"
						/>
					</Fragment>
				)}
			</div>
		</div>
	)
}
export default ContractsTerm
