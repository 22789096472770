import { TextField } from "@mui/material"
import { motion } from "framer-motion"
import { NumericFormat } from "react-number-format"
import { fadeUp } from "utils/motion"

const SimpleNumberField = ({
	delay = 0,
	duration = 0.5,
	value,
	setValue,
	label,
	name,
	decimalScale = 3,
	isParseFloat = true,
	prefix = "",
	suffix = "",
	readOnly = false,
	disabled = false,
	error = false,
	helperText = "",
	className = "",
	size = "medium"
}) => {
	return (
		<NumericFormat
			id={`${name}-number-field`}
			name={name}
			label={label}
			value={value}
			delay={delay}
			onChange={(event) => {
				let formattedValue =
					event.target.value && isParseFloat
						? parseFloat(event.target.value.split(" ").join(""))
						: parseInt(event.target.value.split(" ").join(""))
				setValue(formattedValue)
			}}
			error={error}
			helperText={helperText}
			component={motion.div}
			variants={fadeUp(30, "tween", delay, duration)}
			initial="hidden"
			animate="show"
			viewport={{ once: true, amount: 0.25 }}
			color="formColor"
			variant="outlined"
			fullWidth
			customInput={TextField}
			allowNegative={false}
			InputProps={{ readOnly: readOnly, disabled: disabled }}
			thousandSeparator={" "}
			decimalScale={decimalScale}
			prefix={prefix}
			suffix={suffix}
			className={className}
			size={size}
		/>
	)
}

export default SimpleNumberField
