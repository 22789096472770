import { Button, Drawer } from '@mui/material';
import DateTypeFilter from './items/dateTypeFilter';
import { useLocation, useNavigate } from 'react-router-dom';

const BossCrmCallsFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
      <div className='dashboard-filter-drawer-wrapper'>
        <div className='mb-3 text-end'>
          <Button
            color='error'
            variant='outlined'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            <i className='bi bi-arrow-repeat text-lg mr-1' />
            Tozalash
          </Button>
        </div>
        <DateTypeFilter disabled={isLoading} />
      </div>
    </Drawer>
  );
};
export default BossCrmCallsFilterDrawer;
