import { createContext } from "react"

const AuthContext = createContext({})

const NotificationContext = createContext({})

const CRMLeadsContext = createContext({})

const CurrencyContext = createContext({})

const MenuContext = createContext({})

const PermissionContext = createContext({})

const TopPanelContext = createContext({})

export {
	AuthContext,
	NotificationContext,
	CRMLeadsContext,
	CurrencyContext,
	TopPanelContext,
	MenuContext,
	PermissionContext
}
