import { Grid } from "@mui/material"
import useTopPanel from "hooks/useTopPanel"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import TotalStatistics from "./items/TotalStatistics"
import IncomeByResidentialComplex from "./items/IncomeByResidentialComplex"
import SaleOfHomes from "./items/SaleOfHomes"
import StartingPrices from "./items/StartingPrices"
import AveragePriceForSquare from "./items/AveragePriceForSquare"

const ResidentialComplex = () => {
	const { t, i18n } = useTranslation()
	const { setComponent } = useTopPanel()

	useEffect(() => {
		setComponent(
			<div className="component-title">
				{t("boss.residentialComplex.title")}
			</div>
		)
	}, [i18n.language])

	return (
		<div className="component-wrapper boss-residential-complex-wrapper">
			<Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
				<Grid item={true} lg={5} md={6} sm={6} xs={12}>
					<TotalStatistics />
				</Grid>
				<Grid item={true} lg={7} md={6} sm={6} xs={12}>
					<IncomeByResidentialComplex />
				</Grid>
				<Grid item={true} lg={5} md={6} sm={6} xs={12}>
					<SaleOfHomes />
				</Grid>
				<Grid item={true} lg={7} md={6} sm={6} xs={12}>
					<StartingPrices />
				</Grid>
				<Grid item={true} sm={12} xs={12}>
					<AveragePriceForSquare />
				</Grid>
			</Grid>
		</div>
	)
}
export default ResidentialComplex
