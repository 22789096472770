import { useEffect, useRef, useState } from 'react';

const MiniMap = ({ mainContainerId = '', itemsNumber = 0 }) => {
  const minimapRef = useRef(null);
  const viewerRef = useRef(null);
  const minimapItemsContainerRef = useRef(null);
  const [mainContainer, setMainContainer] = useState(null);

  const handleMove = (event) => {
    const x = event.layerX - viewerRef.current.clientWidth / 2;
    const realScale =
      minimapRef.current.clientWidth / mainContainer.scrollWidth;
    if (
      x > -3 &&
      x < minimapRef.current.clientWidth - viewerRef.current.clientWidth - 13
    ) {
      viewerRef.current.style.transform = `translateX(${x}px)`;
      mainContainer.scrollTo(x / realScale, 0);
    }
  };

  const handleMouseDown = (event) => {
    const el = event.target;
    document.body.style.userSelect = 'none';
    el.addEventListener('mousemove', handleMove);
  };

  const handleMouseUp = (event) => {
    const el = event.target;
    document.body.style.userSelect = 'auto';
    el.removeEventListener('mousemove', handleMove);
  };

  const trackScroll = (event) => {
    let realScale = minimapRef.current.clientWidth / mainContainer.scrollWidth;
    viewerRef.current.style.transform = `translateX(${
      event.target.scrollLeft * realScale
    }px)`;
  };

  const getDimension = () => {
    let minimap = document.getElementById('minimap-wrapper');
    let viewer = document.getElementById('viewer');

    let realScale = minimap.clientWidth / mainContainer.scrollWidth;
    viewer.style.width = `${mainContainer.offsetWidth * realScale - 8}px`;
  };

  useEffect(() => {
    let mainContainer = document.getElementById(mainContainerId);
    setMainContainer(mainContainer);
  }, []);

  useEffect(() => {
    if (mainContainer) {
      getDimension();
      mainContainer.addEventListener('scroll', trackScroll);
      window.addEventListener('resize', getDimension);
    }

    return () => {
      window.removeEventListener('resize', getDimension);
    };
  }, [mainContainer]);

  return (
    <div className='minimap-wrapper' id='minimap-wrapper' ref={minimapRef}>
      <div
        className='viewer'
        id='viewer'
        ref={viewerRef}
        onMouseDownCapture={handleMouseDown}
        onMouseUp={handleMouseUp}
        // onMouseMove={handleMove}
        onMouseOut={handleMouseUp}
        // onMouseLeave={(event) => handleMouseUp(event)}
      />
      <div
        className='items-wrapper'
        id='minimap-items-wrapper'
        ref={minimapItemsContainerRef}
      >
        {[...Array(itemsNumber).keys()].map((item) => (
          <div className='item' key={`minimap-item-${item}`}></div>
        ))}
      </div>
    </div>
  );
};
export default MiniMap;
