import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import ContractChildAccordion from "./ContractChildAccordion"

const ContractsAccordion = ({ title = "", itemClassName = "", data = [] }) => {
	return (
		<Accordion
			sx={{ boxShadow: "none" }}
			className={`contracts-term-accordion contracts-${itemClassName}`}
		>
			<AccordionSummary
				aria-controls={`contracts-term-item-${itemClassName}-content`}
				id={`contracts-term-item-${itemClassName}-header`}
				expandIcon={<i className="bi bi-chevron-right expand-icon" />}
				className={`contracts-term-accordion-summary ${itemClassName}`}
			>
				<div className="contracts-term-data">
					<i className="bi bi-calendar4-week text-lg" />
					<div className="contract-range">{title} oy</div>
					<div className="divider"></div>
					<i className="bi bi-clipboard-check text-lg" />
					<div className="contracts-number">{data.length} ta shartnoma</div>
				</div>
			</AccordionSummary>
			<AccordionDetails className="contracts-term-accordion-details">
				<div className="contracts-wrapper">
					{data && data.length > 0
						? data.map((item, index) => (
								<ContractChildAccordion
									item={item}
									parentClassName={itemClassName}
									key={`contracts-wrapper-accordion-${itemClassName}-item-${index}`}
								/>
						  ))
						: ""}
				</div>
			</AccordionDetails>
		</Accordion>
	)
}
export default ContractsAccordion
