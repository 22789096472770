import React, { memo, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import useTopPanel from 'hooks/useTopPanel';
import SearchInput from 'components/SearchInput';
import CrmStaffConnectionTable from 'components/ui/tables/CrmStaffConnectionTable';
import CrmStaffConnectionFilter from 'components/ui/filters/CrmStaffConnectionFilter';
import BackButton from 'components/ui/BackButton';
import StaffFilter from 'components/ui/filters/items/StaffFilter';
import useFormSubmit from 'hooks/useFormSubmit';

const StaffConnection = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchParam] = useSearchParams();
  const { submit } = useFormSubmit();

  useEffect(() => {
    setComponent(
      <div className='component-title'>
        <BackButton defaultPath='/admin/crm' />
        {t('crm.block.connection.title')}
      </div>
    );
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const onSubmit = () => {
    const leads = selectedRows.map((row) => ({
      id: row,
    }));
    const values = {
      leads,
      operator_id: searchParam.get('staff'),
    };
    submit(
      { type: 'post', contentType: 'simple' },
      values,
      '/crm/crm-connect/connect',
      'connection-staff',
      null,
      true,
      () => setRefetch(true)
    );
  };

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full'>
            <Button
              variant='filterOutlined'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setExpanded((prev) => !prev)}
            >
              {t('common.button.filter')}
            </Button>
            <SearchInput inputKey='name' />
            <div className='ml-auto flex items-center'>
              <div className='mr-3 lg:w-[200px] w-[150px]'>
                <StaffFilter />
              </div>
              <Button
                variant='contained'
                color='success'
                onClick={onSubmit}
                disabled={!(searchParam.get('staff') && selectedRows.length)}
                className='h-10'
              >
                {t('common.button.save')}
              </Button>
            </div>
          </div>
          <div className='header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4'>
            <Button
              variant='action'
              color='info'
              onClick={() => {
                setRefetch(true);
              }}
              className='!mr-2'
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='action'
              color='default'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
        {expanded && (
          <div className='my-shadow-2 rounded-lg px-4 w-full mt-2'>
            <CrmStaffConnectionFilter />
          </div>
        )}
      </div>

      <div className='component-table-wrapper'>
        <CrmStaffConnectionTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='crm/crm-connect/newleads'
          emitTableColumns={{ open, setOpen }}
          tableName='arrears'
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          actionContractView={true}
        />
      </div>
    </div>
  );
};

export default memo(StaffConnection);
