const IMAGE_TYPE = {
	"2D": {
		code: "1",
		label: "2D"
	},
	"3D": {
		code: "2",
		label: "3D"
	}
}

export default IMAGE_TYPE
