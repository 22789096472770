import useTopPanel from "hooks/useTopPanel"
import { useEffect, useState } from "react"
import CRMBlockColumns from "./crm-parts/block-columns/CRMBlockColumns"
import CRMTopPanel from "./crm-parts/CRMTopPanel"
import usePermission from "hooks/usePermission"

const CRM = () => {
	const { setComponent } = useTopPanel()
	const [selectedBlock, setSelectedBlock] = useState({})
	const { hasPermission } = usePermission()

	useEffect(() => {
		setComponent(
			<CRMTopPanel
				selectedBlock={selectedBlock}
				setSelectedBlock={setSelectedBlock}
				addBlock={hasPermission("CRM_ADD_BLOCK")}
				addLead={hasPermission("")}
			/>
		)

		return () => setComponent("")
	}, [selectedBlock])

	return (
		<div className="component-list-wrapper bg-base-color-light-hover rounded-lg">
			<div className="crm-wrapper">
				<CRMBlockColumns blockId={selectedBlock?.id} />
			</div>
		</div>
	)
}
export default CRM
