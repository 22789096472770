import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import { Trans, useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import {
  homeTypeVariants,
  repairTypeVariants,
  residentTypeVariants,
} from 'shared/tableColVariantsList';

const { forwardRef } = require('react');

const ShaxmatkaHomeDetailToPrint = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const setHomeType = (item, typeArr) => {
    let result = typeArr.filter((variant) => variant.code === item);
    if (result.length > 0) {
      return t(result[0].label);
    }
    return '';
  };

  return (
    <div ref={ref} className='home-detail-to-print-wrapper'>
      <div className='row-data'>
        <div className='row-data-item is-start'>
          <div className='helper-text'>
            {t('shaxmatka.homeDetail.residentialComplex')}
          </div>
          <div>{props?.objectData?.name}</div>
        </div>
        <div>
          <img
            className='w-[40px]'
            src={require('assets/images/logo/median-logo.jpg')}
            alt='logo'
          />
        </div>
        <div className='row-data-item is-end'>
          <div className='helper-text'>
            {t('shaxmatka.homeDetail.callCenter')}
          </div>
          <div>+998 78 888-07-77</div>
        </div>
      </div>
      <div className='divider'></div>
      <div className='row-data'>
        <div className='row-data-item is-start'>
          <div className='helper-text'>{t('shaxmatka.homeDetail.areaAll')}</div>
          <div>
            <NumericFormat
              value={props?.homeData?.square}
              allowNegative={false}
              displayType={'text'}
              decimalScale={2}
              className='w-full text-right'
            />{' '}
            <Trans i18nKey='common.global.meter'>
              m<sup>2</sup>
            </Trans>
          </div>
        </div>
        <div className='row-data-item is-center'>
          <div className='helper-text'>{t('shaxmatka.homeDetail.rooms')}</div>
          <div className='row-data-item is-center'>
            <ButtonBase className='home-rooms'>
              {props?.homeData?.rooms}
            </ButtonBase>
          </div>
        </div>
        <div className='row-data-item is-end'>
          <div className='helper-text text-center'>
            {t('shaxmatka.homeDetail.price')}
          </div>
          <div className='flex flex-col'>
            <NumericFormat
              value={props?.homeData?.fullPriceSum}
              allowNegative={false}
              displayType={'text'}
              thousandSeparator={' '}
              decimalScale={1}
              className='w-full text-center'
              suffix=' UZS'
            />
            <NumericFormat
              value={props?.homeData?.fullPriceValute}
              allowNegative={false}
              displayType={'text'}
              thousandSeparator={' '}
              decimalScale={1}
              className='w-full text-center'
              suffix=' $'
            />
          </div>
        </div>
      </div>
      <div className='divider'></div>
      <div className='row-data'>
        <div className='row-data-item'>
          <div className='helper-text'>
            {t('shaxmatka.homeDetail.buildingName')}
          </div>
          <div>{props?.blockName}</div>
        </div>
        <div className='row-data-item'>
          <div className='helper-text'>
            {t('shaxmatka.homeDetail.residentType')}
          </div>
          <div>
            {props?.homeData?.islive
              ? setHomeType(props?.homeData.islive, residentTypeVariants)
              : '—'}
          </div>
        </div>
        <div className='row-data-item'>
          <div className='helper-text'>{t('shaxmatka.homeDetail.stage')}</div>
          <div>{props?.homeData?.stage || '—'}</div>
        </div>
        <div className='row-data-item'>
          <div className='helper-text'>
            {t('shaxmatka.homeDetail.houseNumber')}
          </div>
          <div>{props?.homeData?.number || '—'}</div>
        </div>
        <div className='row-data-item'>
          <div className='helper-text'>
            {t('shaxmatka.homeDetail.repairType')}
          </div>
          <div>
            {props?.homeData?.isrepaired
              ? setHomeType(props?.homeData.isrepaired, repairTypeVariants)
              : '—'}
          </div>
        </div>
        <div className='row-data-item'>
          <div className='helper-text'>
            {t('shaxmatka.homeDetail.position')}
          </div>
          <div>
            {props?.homeData?.status
              ? setHomeType(props?.homeData?.status, homeTypeVariants)
              : ''}
          </div>
        </div>
      </div>
      {(props?.homeData?.plan?.link || props?.homeData?.plan?.link2d) && (
        <div className='h-[calc(100vh-400px)] flex flex-col items-center mt-4'>
          {props?.homeData?.plan?.link && (
            <div className='row-data-item h-1/2 py-5'>
              <div className='helper-text text-center mb-2'>
                {t('shaxmatka.homeDetail.plan3D')}
              </div>
              <img
                className='h-full'
                src={`${process.env.REACT_APP_BACKEND_URL}/${props?.homeData?.plan?.link}`}
                alt={props?.homeData?.plan?.name}
              />
            </div>
          )}
          {props?.homeData?.plan?.link2d && (
            <div className='row-data-item h-1/2 py-5'>
              <div className='helper-text text-center mb-2'>
                {t('shaxmatka.homeDetail.plan2D')}
              </div>
              <img
                className='h-full'
                src={`${process.env.REACT_APP_BACKEND_URL}/${props?.homeData?.plan?.link2d}`}
                alt={props?.homeData?.plan?.name}
              />
            </div>
          )}
        </div>
      )}

      <div className='mt-6'>
        <TableContainer className='flex-auto h-full'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    Umumiy narx
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.sellPrice')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.startPrice')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.discount')}
                  </span>
                </TableCell>
                {/* <TableCell>
									<span className="!text-[10px] text-gray-500">
										m<sup>2</sup> narxi
									</span>
								</TableCell> */}
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.month')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.monthlyPay')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    Kadastr To'lov
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='overflow-hidden'>
              {props?.homeData?.calculations &&
                props?.homeData?.calculations.length > 0 &&
                props?.homeData?.calculations.map((item, index) => (
                  <TableRow key={`calculation-table-row-${index}`}>
                    <TableCell>
                      <span className='!text-[11px]'>
                        {item?.fullPrice ? (
                          <CurrencyFormat
                            value={item?.fullPrice}
                            suffix={item?.isvalute === '1' ? ' $' : ' UZS'}
                            decimalScale={1}
                          />
                        ) : (
                          0
                        )}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>
                        <NumericFormat
                          value={item?.sellPrice}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          className='w-full text-center'
                          suffix={item?.isvalute === '1' ? ' $' : ' UZS'}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>
                        {item?.startPrice ? (
                          <CurrencyFormat
                            value={item?.startPrice}
                            suffix={item?.isvalute === '1' ? ' $' : ' UZS'}
                            decimalScale={1}
                          />
                        ) : (
                          0
                        )}{' '}
                        (
                        <NumericFormat
                          value={item?.startPricePercentage}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={2}
                          className='w-full text-center'
                          suffix=' %'
                        />
                        )
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>
                        {item?.discount ? (
                          <CurrencyFormat
                            value={item?.discount}
                            suffix={item?.isvalute === '1' ? ' $' : ' UZS'}
                            decimalScale={1}
                          />
                        ) : (
                          0
                        )}{' '}
                        (
                        <NumericFormat
                          value={item?.discountPercentage}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={2}
                          className='w-full text-center'
                          suffix=' %'
                        />
                        )
                      </span>
                    </TableCell>
                    {/* <TableCell>
											<span className="!text-[11px]">
												<CurrencyFormat
													value={item?.priceForSquare}
													suffix={item?.isvalute === "1" ? " $" : " UZS"}
													decimalScale={1}
												/>
											</span>
										</TableCell> */}
                    <TableCell>
                      <span className='!text-[11px]'>{item?.month}</span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>
                        <NumericFormat
                          value={item?.priceForMonth}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          className='w-full text-center'
                          suffix={item?.isvalute === '1' ? ' $' : ' UZS'}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>
                        <NumericFormat
                          value={item?.kadastr}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          className='w-full text-center'
                          suffix={item?.isvalute === '1' ? ' $' : ' UZS'}
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='divider mt-auto mb-3'></div>
    </div>
  );
});

export default ShaxmatkaHomeDetailToPrint;
