import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import FilterAccordion from '../FilterAccordion';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

const radios = [
  {
    code: 'tilltoday',
    label: 'Shu kungacha',
  },
  {
    code: 'today',
    label: 'Bugun',
  },
  {
    code: 'yesterday',
    label: 'Kecha',
  },
  {
    code: 'week',
    label: 'Joriy hafta',
  },
  {
    code: 'month',
    label: 'Joriy oy',
  },
  {
    code: 'custom',
    label: 'Maxsus',
  },
];

const DateTypeFilter = ({ disabled = false }) => {
  const { t } = useTranslation();
  const [repairTypeValue, setRepairTypeValue] = useState('ALL');
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRepairTypeChange = (value) => {
    if (value === 'tilltoday') {
      searchParams.delete('from');
      searchParams.delete('till');
    }
    if (value === 'today') {
      searchParams.set('from', moment().format('YYYY-MM-DD'));
      searchParams.set('till', moment().format('YYYY-MM-DD'));
    }
    if (value === 'yesterday') {
      searchParams.set(
        'from',
        moment().subtract(1, 'days').format('YYYY-MM-DD')
      );
      searchParams.set(
        'till',
        moment().subtract(1, 'days').format('YYYY-MM-DD')
      );
    }
    if (value === 'week') {
      searchParams.set(
        'from',
        moment().clone().startOf('isoWeek').format('YYYY-MM-DD')
      );
      searchParams.set('till', moment().format('YYYY-MM-DD'));
    }
    if (value === 'month') {
      searchParams.set(
        'from',
        moment().clone().startOf('month').format('YYYY-MM-DD')
      );
      searchParams.set('till', moment().format('YYYY-MM-DD'));
    }
    setSearchParams(searchParams);
    setRepairTypeValue(value);
  };

  return (
    <FilterAccordion title='Filter' code='home-repair-type'>
      <FormControl fullWidth color='formColor' type='filterRadioGroup'>
        <RadioGroup
          aria-labelledby='repair-type-radio-buttons-group'
          name='repair-type-radio-buttons-group-name'
          value={repairTypeValue}
          onChange={(event) => handleRepairTypeChange(event.target.value)}
        >
          {radios.map((option) => (
            <div className='mb-1 w-full' key={option.code}>
              <FormControlLabel
                className='w-full'
                value={option.code}
                control={<Radio />}
                label={t(option.label)}
                type='radio'
                disabled={disabled}
              />
            </div>
          ))}
          {repairTypeValue === 'custom' ? <DateRangeFilter /> : null}
        </RadioGroup>
      </FormControl>
    </FilterAccordion>
  );
};
export default DateTypeFilter;
